import React, { createContext, useState, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';


// Create a UserContext
const UserContext = createContext();

// Create a custom hook for easy access to the UserContext
export const useUser = () => useContext(UserContext);

// UserProvider component to wrap around the app
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Initially, no user is logged in

  // Function to store user details after login
  const loginUser = (userData) => {
    setUser(userData);
    console.log("I am from user Context : ",userData);
    const finalData = {
      ...userData,
      email : userData.businessEmail
    }
    // Optionally store user in localStorage/sessionStorage to persist login across refreshes
    localStorage.setItem('user', JSON.stringify(finalData));
  };

  // Function to clear user details upon logout
  const logoutUser = () => {
    setUser(null);
    localStorage.removeItem('user');
  };


  return (
    <UserContext.Provider value={{ user, loginUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};
