import "./index.css"
import IconClientSidebar from "../../../IconClientSidebar";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import { useRef } from "react";
import { IoMicCircle } from "react-icons/io5";
import { FaMicrophoneLines } from "react-icons/fa6";
import {v4 as uuidv4} from 'uuid';
import { HiSpeakerWave } from "react-icons/hi2";
import { IoMdClose } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { AiFillRobot } from "react-icons/ai";
import { MdOutlineAddIcCall } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { RiRobot3Fill } from "react-icons/ri";

const assistantArray = ['Vijay Kumar Singh','John']
const dids = ['07971908298']
const statusOptions = ["Not started",
    "In queue",
    "Ongoing",
    "Completed", 
    "Not Connected" ,
    "Invalid" ,
    "Not reachable", 
    "Incoming Bard"  ,
    'Error']

const ReportDetail = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name:'',
        number:'',
        group:'',
        status:'Not Started',
        isSelected : false
    });
    const [micOn, setMicOn] = useState(false);
    const params = useParams();
    const {campaignName} = params;
    const [usersList, setUsersList] = useState([]);
    const [selectedAssistant, setSelectedAssistant] = useState('');
    const [did, setDID] = useState('7971908298');
    const fileInputRef = useRef(null);
    const [activeAssistant, setActiveAssistant] = useState({
      name : 'Geeta',
      type : 'Customer Care Executive'
    });
    const [user, setUser] = useState({
      name : 'Vijay',
      mobileno : '8147540362'
    });

  // Required columns
  const requiredColumns = ['Name', 'Number', 'Details'];

  // Function to handle file upload
  console.log(usersList)


    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
          ...formData,
          [id]: value
        });
      };    


    

    


    return (
         <div className="business-container">
            <div className="business-container-header">
                <div style={{display:'flex',alignItems:'center'}}>
            <button style={{display:'flex',alignItems:'center'}} onClick={() => navigate("/campaigns/report")}>
                <MdArrowBackIos/>
                </button>
                <h1 style={{margin:0}} className="bc-main-heading">Report</h1>
                </div>
                <div>
</div>
        </div>

              <div className="table-outer-container bg-[#444444] mt-4 p-4">
  <table className="min-w-full bg-[#15161A] text-white rounded-lg shadow-lg">
    <thead>
      <tr style={{color:'white',fontSize:'16px'}}>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">S.No</th>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">Name</th>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">Number</th>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">Group</th>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">Status</th>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">Outcome</th>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">Remarks</th>
      </tr>
    </thead>
    <tbody>
    <tr className="hover:bg-gray-700">
          <td className="py-3 px-4 border-b border-gray-700">1</td>
          <td className="py-3 px-4 border-b border-gray-700">Ram</td>
          <td className="py-3 px-4 border-b border-gray-700">8394830284</td>
          <td className="py-3 px-4 border-b border-gray-700">Group 1</td>
          <td className="py-3 px-4 border-b border-gray-700">
            InQueue
          </td>
          <td className="py-3 px-4 border-b border-gray-700">Demo Requested</td>
          <td className="py-3 px-4 border-b border-gray-700">
          Action Required
          </td>
        </tr>
      {usersList.map((ele, index) => (
        <tr className="hover:bg-gray-700" key={index}>
          <td className="py-3 px-4 border-b border-gray-700">{index+1}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.name}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.number}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.group}</td>
          <td className="py-3 px-4 border-b border-gray-700">
            {ele.status}
          </td>
          <td className="py-3 px-4 border-b border-gray-700">
            {ele.status}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
            </div>

    )
}

export default ReportDetail;