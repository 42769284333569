import "./index.css"
import IconClientSidebar from "../../IconClientSidebar";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FaCalendarXmark } from "react-icons/fa6";
import { MdHeadphones } from "react-icons/md";
import { FaGear } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { RiRobot3Fill } from "react-icons/ri";
import { IoMdClose } from "react-icons/io";
import {v4 as uuidv4} from 'uuid';
import { FaUser } from "react-icons/fa";
import {DateRangePicker} from "@nextui-org/date-picker";




const meetings = [
  {
    title: "Sales Call Meeting",
    date: "Date : 15th Sept , 3:00-4:00Pm",
    participant: "Vijay Kumar",
    participantEmail: "Vijay.wiz@gmail.com",
    participantMobileNo: 8147540362,
    status: 'Canceled',
    meetUrl: 'https://meet.google.com/abcd-efg-hij',
    id : uuidv4()
  },
  {
    title: "Product Demo Meeting",
    date: "Date : 16th Sept , 4:00-5:00Pm",
    participant: "Naga Chandu",
    participantEmail: "nagachandu@gmail.com",
    participantMobileNo: 8899332233,
    status: 'Completed',
    meetUrl: 'https://meet.google.com/xyz-abc-def',
    id : uuidv4()
  },
  {
    title: "Product Training Meeting",
    date: "Date : 17th Sept , 3:00-4:00Pm",
    participant: "Jitendra Ojha",
    participantEmail: "Jitendraojha@gmail.com",
    participantMobileNo: 8897886587,
    status: 'Rescheduled',
    meetUrl: 'https://meet.google.com/uvw-klm-opq',
    id : uuidv4()
  },
];

const Meetings = () => {
    const [meetingsList, setMeetingsList] = useState(meetings);
    const [activeMeetingTab, setActiveMeetingTab] = useState('ALL');
    const [activeStatus, setActiveStatus] = useState('Scheduled');
    const [filteredList, setFilteredList] = useState(meetingsList);
    const handleStatusChange = (uniqueId,value) => {
      const newList = meetingsList.map((ele) => {
        if(ele.id===uniqueId)
        {
          return {...ele,status:value}
        }
        return ele;
      })
      setMeetingsList(newList);
      if(activeMeetingTab==="ALL")
        setFilteredList(newList)
      else if(activeMeetingTab==="Upcoming")
        setFilteredList(newList.filter((ele) => ele.status==="Scheduled"))
      else
      setFilteredList(newList.filter((ele) => ele.status===activeMeetingTab))
    }

    const handleActiveTabChange = (value) => {
      setActiveMeetingTab(value)
      if(value==="ALL")
        setFilteredList(meetingsList)
      else if(value==="Upcoming")
        setFilteredList(meetingsList.filter((ele) => ele.status==="Scheduled"))
      else
      setFilteredList(meetingsList.filter((ele) => ele.status===value))
    }

    const getStatusColor = (status) => {
      switch (status) {
        case "Completed" :
          return 'green';
        case "Rescheduled" :
          return 'orange';
        case "Canceled" :
          return 'red';
        default :
          return 'white';
      }
    };

    

    


    return (
         <div className="w-80 business-container">
            <div className="business-container-header">
                <h1 className="bc-main-heading">Meetings</h1>
                <DateRangePicker
      label="Stay duration" 
      className="max-w-xs" 
      style={{width:'200px'}}
    />
            </div>
            <div className="template-container">
            <div className="mb-5 meeting-tabs-container">
            <div className="meeting-tabs">
            <button onClick={() => handleActiveTabChange("ALL")} type="button" className={`meeting-tab-button ${activeMeetingTab === "ALL" ? 'active' : ''}`}>ALL</button>
            <button onClick={() => handleActiveTabChange("Upcoming")} type="button" className={`meeting-tab-button ${activeMeetingTab === "Upcoming" ? 'active' : ''}`}>Upcoming</button>
            <button onClick={() => handleActiveTabChange("Pending")} type="button" className={`meeting-tab-button ${activeMeetingTab === "Pending" ? 'active' : ''}`}>Pending</button>
            <button onClick={() => handleActiveTabChange("Rescheduled")} type="button" className={`meeting-tab-button ${activeMeetingTab === "Rescheduled" ? 'active' : ''}`}>Rescheduled</button>
            <button onClick={() => handleActiveTabChange("Canceled")} type="button" className={`meeting-tab-button ${activeMeetingTab === "Canceled" ? 'active' : ''}`}>Canceled</button>
            <button onClick={() => handleActiveTabChange("Not Interested")} type="button" className={`meeting-tab-button ${activeMeetingTab === "Not Interested" ? 'active' : ''}`}>Not Interested</button>
            <button onClick={() => handleActiveTabChange("Sale")} type="button" className={`meeting-tab-button ${activeMeetingTab === "Sale" ? 'active' : ''}`}>Sale</button>
            <button onClick={() => handleActiveTabChange("Interested")} type="button" className={`meeting-tab-button ${activeMeetingTab === "Interested" ? 'active' : ''}`}>Interested</button>
            <button onClick={() => handleActiveTabChange("Follow-up")} type="button" className={`meeting-tab-button ${activeMeetingTab === "Follow-up" ? 'active' : ''}`}>Follow-up</button>
            <button onClick={() => handleActiveTabChange("Others")} type="button" className={`meeting-tab-button ${activeMeetingTab === "Others" ? 'active' : ''}`}>Others</button>
          </div>

              {/* Tabs - Container*/}
              <div>
                {/* <div className="p-3 text-center">
                <p className="m-auto"><FaCalendarXmark className="m-auto" size={60}/></p>
                <h1>No Events Yet</h1>
                <p>Share Event Type links to Schedule Events</p>
                </div> */}
              </div>
              </div>

              <div className="flex w-100">
      {filteredList.map((meeting, index) => (
        <div key={index} style={{ width: '30%' }} className="template-card">
          <div className="template-heading-container">
            <h1 style={{ fontSize: '18px', color: 'yellow', width: '90%' }} className="template-heading">
              {meeting.title}
            </h1>

            <div className="mb-2 flex items-center w-10">
            <Popup
            trigger={<p ><MdHeadphones size={20}/></p>}
            modal
            nested
          >
            {close => (
              <div style={{width:'600px', overflow: 'auto' }} className="hidden-scrollbar">
              <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
                  <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                  <h3 style={{ fontSize: '25px', marginBottom: '15px', color:'yellow' }} className="font-bold">
                    Completed Call
                  </h3>
                  <button type="button" onClick={() => close()}><IoMdClose /></button>
                  </div>
                  <div className="mt-2 mb-3 flex justify-between items-start">
                    <div className="text-center flex-1">
                    <h1 style={{fontSize:'18px'}} className="assist-heading">John</h1>
                      <p className="m-auto"><RiRobot3Fill style={{color:'aqua'}} className="m-auto" size={60}/></p>
                      <h1 style={{fontSize:'20px'}} className="assist-heading mb-3 mt-2">AI Assistant</h1>

                      <h2 className="assist-heading">Customer Care Executive</h2>
                    </div>
                    <div className="text-center flex-1 relative">
                    <div className="flex justify-center items-center pulsing-ring">
                      <FiPhoneCall style={{color:'aqua'}} size={60} className="mx-auto text-white relative z-10" />
                    </div>
                    <p className="text-center">03:49 / 04:00</p>
                  </div>
                    <div className="text-center flex-1">
                    <h1 style={{fontSize:'18px'}} className="assist-heading">Vijay</h1>
                      <p className="m-auto"><FaUserCircle style={{color:'aqua'}} className="m-auto" size={60}/></p>
                      <h1 style={{fontSize:'20px'}} className="assist-heading mb-3 mt-2">User</h1>

                      <h2 className="assist-heading">8147540362</h2>
                    </div>
                  </div>
                  <div style={{height:'100px',backgroundColor:'black',borderRadius:'5px',textAlign:'center'}}>
                    <p className="p-3">Hello! How can I help you?</p>
                    </div>
                  {/* <textarea className="greeting-agent-input" placeholder=""></textarea> */}
                </div>
              </div>
            </div>
            
            )}
          </Popup>

              <Popup
trigger={<p className="ml-2"><FaGear size={20}/></p>}
modal
nested
>
{close => (
  <div style={{width:'600px', overflow: 'auto' }} className="hidden-scrollbar">
  <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
    <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <h3 style={{ fontSize: '25px', marginBottom: '15px', color:'yellow' }} className="font-bold">
        Update Status
      </h3>
      <button type="button" onClick={() => close()}><IoMdClose /></button>
      </div>
      <div>
        <div>
        <button onClick={() => {handleStatusChange(meeting.id,"Scheduled")
          close()
        }} className="w-48 addnew-button mr-2" type="button">Scheduled</button>
        <button onClick={() => {handleStatusChange(meeting.id,"Completed")
          close()
        }} className="w-48 addnew-button" type="button">Completed</button>
        </div>
        <br/>
        <div>
        <button onClick={() => {handleStatusChange(meeting.id,"Canceled")
          close()
        }} className="w-48 addnew-button mr-2" type="button">Canceled</button>
        <button onClick={() => {handleStatusChange(meeting.id,"Rescheduled")
          close()
        }} className="w-48 addnew-button" type="button">Rescheduled</button>
        </div>
        </div>
    </div>
  </div>
</div>

)}
</Popup>
            </div>
          </div>
          <p style={{fontSize:'18px', color: getStatusColor(meeting.status) }}>{meeting.status}</p>
          <p>{meeting.date}</p>
          <p>{meeting.participant}</p>
          <p>{meeting.participantEmail}</p>
          <p>{meeting.participantMobileNo}</p>
          <div className="mt-4 flex justify-between items-center">
            <a href={meeting.meetUrl} style={{ color: 'white', textDecoration: 'none' }}>
              <button className="join-button-new" type="button">Join</button>
            </a>
            <Popup
            trigger={<button style={{backgroundColor:'green',color:'white'}} className="join-button-new" type="button">Call</button>}
            modal
            nested
          >
            {close => (
              <div style={{width:'600px', overflow: 'auto' }} className="hidden-scrollbar">
              <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
                  <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                  <h3 style={{ fontSize: '25px', marginBottom: '15px', color:'yellow' }} className="font-bold">
                    Manual Call
                  </h3>
                  <button type="button" onClick={() => close()}><IoMdClose /></button>
                  </div>
                  <div className="mt-2 mb-3 flex justify-between items-start">
                    <div className="text-center flex-1">
                    <h1 style={{fontSize:'18px'}} className="assist-heading">John</h1>
                      <p className="m-auto"><FaUser style={{color:'aqua'}} className="m-auto" size={60}/></p>
                      <h1 style={{fontSize:'20px'}} className="assist-heading mb-3 mt-2">Executive</h1>
                      <h2 className="assist-heading">DID - 7578457215</h2>
                    </div>
                    <div className="text-center flex-1 relative">
                    <div className="flex justify-center items-center pulsing-ring">
                      <FiPhoneCall style={{color:'aqua'}} size={60} className="mx-auto text-white relative z-10" />
                    </div>
                    <p className="text-center">03:49</p>
                  </div>
                    <div className="text-center flex-1">
                    <h1 style={{fontSize:'18px'}} className="assist-heading">Vijay</h1>
                      <p className="m-auto"><FaUserCircle style={{color:'aqua'}} className="m-auto" size={60}/></p>
                      <h1 style={{fontSize:'20px'}} className="assist-heading mb-3 mt-2">User</h1>

                      <h2 className="assist-heading">8147540362</h2>
                    </div>
                  </div>
                  <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'8px',marginBottom:'12px'}}>
            <button
            onClick = {() => close()}
              type="button"
              className="col-span-2 bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Disconnect
            </button>
            <button
              type="submit"
              className="col-span-2 bg-green-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Connect
            </button>
            </div>
                  <div className="mt-3" style={{height:'100px',backgroundColor:'black',borderRadius:'5px',textAlign:'center'}}>
                    <p className="p-3">Hello! How can I help you?</p>
                    </div>
                  {/* <textarea className="greeting-agent-input" placeholder=""></textarea> */}
                </div>
              </div>
            </div>
            
            )}
          </Popup>
          </div>
        </div>
      ))}
    </div>

            </div>

       </div>
    )
}

export default Meetings;





