import "./index.css"
import IconClientSidebar from "../../../IconClientSidebar";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import { useRef } from "react";
import { IoMicCircle } from "react-icons/io5";
import { FaMicrophoneLines } from "react-icons/fa6";
import {v4 as uuidv4} from 'uuid';
import { HiSpeakerWave } from "react-icons/hi2";
import { IoMdClose } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { AiFillRobot } from "react-icons/ai";
import { MdOutlineAddIcCall } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { RiRobot3Fill } from "react-icons/ri";
import { FaInfoCircle } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { IoEyeOff } from "react-icons/io5";
import { useEffect } from "react";

const Telephony = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name:'',
        number:'',
        status:'disable',
    });
    const [didnumbers, setDidnumbers] = useState([]);
    const [telephonyList, setTelephonyList] = useState([]);
    const [message, setMessage] = useState('');
    const [messageStyle, setMessageStyle] = useState('');
    let user = localStorage.getItem("user");
    if(user)
      user = JSON.parse(user);
    console.log(user)

    useEffect(() => {
      const getItems = async () => {
        try {
          const options = {
            method : "POST",
            headers : {
              "Content-Type" : "application/json"
            },
            body:JSON.stringify({email:user.email,businessName:user.businessName})
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/getclienttelephonydata`,options);
          const response2 = await fetch(`${process.env.REACT_APP_API_URL}/getdids`,options);
          const data = await response.json();
          const data1 = await response2.json();
          if(data.telephonyArray)
          setTelephonyList(data.telephonyArray)
          if(data1)
          setDidnumbers(data1.telephonyArray);
        } catch (err) {
          console.error(`Error Occurred: ${err}`);
        }
      };
  
      getItems();
    }, []);

    console.log(didnumbers);

    const onChangeStatus = (elem,statuss) => {
        const newList = telephonyList.map((ele) => {
          if(ele.id===elem.id){
            return {...ele,status:statuss}
          }
          return ele;
        })
        setTelephonyList(newList)
      }

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
          ...formData,
          [id]: value
        });
      };
      
      const postData = async (obj) => {
        try{
          const options = {
            method : "POST",
            headers : {
              "Content-Type" : "application/json"
            },
            body:JSON.stringify({email:user.email,telephonyItem:{...obj,businessname:user.businessName}})
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/addclienttelephonyitem`,options);
          const data = await response.json();
          if (data.success) {
            // On success, set the success message
            setMessage('Item Inserted Successfully');
            setMessageStyle('green');
            
            // Automatically hide the message after 3 seconds
            setTimeout(() => {
              setMessage('');
            }, 3000);
            return true;
          } else {
            setMessageStyle('red');
            setMessage(data.message);
            setTimeout(() => {
              setMessage('');
            }, 3000);
          }
        }
        catch(Err){
          setMessageStyle('red');
          setMessage('An error occurred');
        setTimeout(() => {
          setMessage('');
        }, 3000);
          console.log(`Error Occurred : ${Err}`)
        }
      }
  
      const deleteData = async (obj) => {
        try {
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              email: user.email,
              telephonyItem: {
                id: obj
              }
            })
          };
      
          const response = await fetch(`${process.env.REACT_APP_API_URL}/deleteclienttelephonyitem`, options);
          
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const data = await response.json();
          console.log("I am from Delete Data");
      
          if (data.success) {
            // On success, set the success message
            setMessage(data.message);
            setMessageStyle('green');
            
            // Automatically hide the message after 3 seconds
            setTimeout(() => {
              setMessage('');
            }, 3000);
      
            // Refresh or update the telephony list
            setTelephonyList(telephonyList.filter((ele) => ele.id!==obj)); // Ensure this function is correctly implemented
          } else {
            setMessageStyle('red');
            setMessage(data.message);
            setTimeout(() => {
              setMessage('');
            }, 3000);
          }
        } catch (Err) {
          setMessageStyle('red');
          setMessage('An error occurred');
          setTimeout(() => {
            setMessage('');
          }, 3000);
          console.error(`Error Occurred: ${Err}`);
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const finalData = {
            name : formData.name,
            didnumber : formData.number,
            status : "disable",
            id : uuidv4(),
            date : (new Date()).toLocaleDateString('en-GB')+"\n"+(new Date()).toLocaleTimeString('en-GB')
        }
        const res = await postData(finalData);
        if(res){
        setTelephonyList([finalData,...telephonyList]);
        setFormData({
          name:'',
          number:'',
          status:''
      })
        }
    }

    


    return (
         <div className="business-container">
         {message && <div style={{backgroundColor:`${messageStyle==='green'?'green':'red'}`}} className="notification">{message}</div>}
            <div className="business-container-header mb-3">
                <h1 style={{margin:0}} className="bc-main-heading">Telephony</h1>
                <div>
                <Popup
  trigger={<button style={{width:'200px'}} className="addnew-button">Subscribe Number</button>}
  modal
  nested
>
  {close => (
    <div style={{ width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
      <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
        <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
          <h3 style={{fontSize:'20px'}} className="font-bold mb-6">Subscribe Number</h3>
          <form onSubmit={(e) => {
            handleSubmit(e);
            close();
          }} className="">
            
            {/* Name Field */}
            <div className="relative">
            <label
                htmlFor="name"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="input-model"
                placeholder="Name"
                required
              />

            </div>
            <br/>

            {/* Number Field */}
            <div className="relative">
              <label htmlFor="number">Number (Rs 1499 per month)</label>
              <select className="mt-3" id="number" onChange={handleChange}>
                <option value="">Select Number </option>
                {didnumbers.map((ele,i) => (
    <option key={i} value={ele}>{ele}</option>
))}

              </select>
            </div>
            <br/>

            {/* Details Field */}
            {/* <div className="relative col-span-2">
          <select
            id="status"
            value={formData.status}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300"
            required
          >
            <option value="" disabled>Select</option>
            <option value="Enable" >Enable</option>
            <option value="Disable" >Disable</option>
          </select>
          <label
            htmlFor="status"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Status
          </label>
        </div>
        <br/> */}

            {/* Submit Button */}
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <button
            onClick = {() => close()}
              type="button"
              className="col-span-2 bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="col-span-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Add
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )}
</Popup>
</div>
        </div>
            <div>

              <div className="table-outer-container bg-[#444444] mt-5 p-4">
  <table className="min-w-full bg-[#15161A] text-white rounded-lg shadow-lg">
    <thead>
      <tr style={{color:'white',fontSize:'16px'}}>
        <th className="w-1/6 py-3 px-4 text-left border-b border-gray-700">S.No</th>
        <th className="w-1/6 py-3 px-4 text-left border-b border-gray-700">Date</th>
        <th className="w-1/6 py-3 px-4 text-left border-b border-gray-700">Name</th>
        <th className="w-1/6 py-3 px-4 text-left border-b border-gray-700">Number</th>
        <th className="w-1/6 py-3 px-4 text-left border-b border-gray-700">Status</th>
        <th className="w-1/6 py-3 px-4 text-left border-b border-gray-700">Action</th>
      </tr>
    </thead>
    <tbody>
      {telephonyList.map((ele, index) => (
        <tr className={`hover:bg-gray-700 ${ele.status==="disable"?'disable-row':''}`} key={index}>
          <td className="py-3 px-4 border-b border-gray-700">{index+1}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.date}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.name}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.didnumber}</td>
          <td className="py-3 px-4 border-b border-gray-700">
          {ele.status==="enable" && (<button onClick={() => {
                    onChangeStatus(ele,"disable")
                    }} style={{marginLeft:'10px'}}  className=" text-gray-600 hover:text-gray-900 focus:outline-none"><FaEye size={30}  /></button>)}
                    {ele.status==="disable" && (<button onClick={() => {
                      onChangeStatus(ele,"enable")
                    }} style={{marginLeft:'10px'}}  className=" text-gray-600 hover:text-gray-900 focus:outline-none"><IoEyeOff size={30}  /></button>)}
            </td>
          <td className="py-3 px-4 border-b border-gray-700">
          <button className="bg-transparent mr-3" type="button">
              <FaEdit size={20}/>
            </button>
            <Popup
              trigger={<button className="bg-transparent mr-3" type="button"><MdDelete size={20}/></button>}
              modal
              nested
            >
              {close => (
                <div
                  style={{
                    width: '300px',
                    height: '250px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#2d3748',
                    color: 'white',
                    borderRadius: '10px',
                    textAlign: 'center',
                  }}
                >
                  <h1 style={{fontSize:'20px'}}>Are you sure want to delete item?</h1>
                  <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                    <button
                      onClick={() => { close(); }}
                      className="addnew-button"
                      style={{ width: '60px', fontSize: '12px', marginRight: '5px' }}
                    >
                      Close
                    </button>
                    <button
                      onClick={() => {
                        deleteData(ele.id)
                        close(); 
                      }}
                      className="addnew-button"
                      style={{
                        backgroundColor: 'red',
                        width: '60px',
                        fontSize: '12px',
                        color: 'white',
                        marginLeft: '5px',
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
            </div>

       </div>
    )
}

export default Telephony;