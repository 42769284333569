import React, { useState } from "react";
import axios from "axios";

const FileUpload = () => {
  const [file, setFile] = useState(null); // Store the selected file
  const [isUploading, setIsUploading] = useState(false); // Track uploading state
  const [fileUrl, setFileUrl] = useState(""); // Store the uploaded file URL

  // Handle file input change
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files[0]) {
      setFile(files[0]);
    }
  };

  // Handle file upload
  const handleUpload = async () => {
    if (!file) return; // Ensure a file is selected

    try {
      // Set uploading state
      setIsUploading(true);

      // Check if the file size is less than 10MB (single upload)
      if (file.size < 10000000) {
        // Call the API to get the presigned URL for single file upload
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/generate-single-presigned-url`,
          {
            fileName: file.name,
          }
        );
        const { url } = response.data;

        // Upload file using presigned URL
        const uploadResponse = await axios.put(url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });

        // Check upload response
        if (uploadResponse.status === 200) {
          alert("File uploaded successfully.");
          // Set the file URL (this is the URL where the file was uploaded)
          setFileUrl(url.split("?")[0]);
        } else {
          alert("Upload failed.");
        }
        setIsUploading(false); // Reset uploading state
      } else {
        // Multipart upload for large files (more than 10MB)
        const startUploadResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/start-multipart-upload`,
          {
            fileName: file.name,
            contentType: file.type,
          }
        );

        const { uploadId } = startUploadResponse.data;

        // Chunk file into 10MB parts
        const chunkSize = 10000000; // 10MB
        const numChunks = Math.ceil(file.size / chunkSize);
        const totalSize = file.size;

        // Request presigned URLs for each part
        const presignedUrlsResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/generate-presigned-url`,
          {
            fileName: file.name,
            uploadId,
            partNumbers: numChunks,
          }
        );

        const presignedUrls = presignedUrlsResponse.data.presignedUrls;

        const parts = [];
        const uploadPromises = [];

        // Upload each chunk separately
        for (let i = 0; i < numChunks; i++) {
          const start = i * chunkSize;
          const end = Math.min(start + chunkSize, totalSize);
          const chunk = file.slice(start, end);

          const presignedUrl = presignedUrls[i];

          uploadPromises.push(
            axios.put(presignedUrl, chunk, {
              headers: {
                "Content-Type": file.type,
              },
            })
          );
        }

        // Wait for all chunks to upload
        const uploadResponses = await Promise.all(uploadPromises);

        uploadResponses.forEach((response, i) => {
          parts.push({
            etag: response.headers.etag,
            PartNumber: i + 1,
          });
        });

        // Complete multipart upload
        const completeUploadResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/complete-multipart-upload`,
          {
            fileName: file.name,
            uploadId,
            parts,
          }
        );

        if (completeUploadResponse.status === 200) {
          alert("File uploaded successfully.");
          // Set the file URL (construct the S3 object URL)
          setFileUrl(
            `https://YOUR_S3_BUCKET_NAME.s3.amazonaws.com/${file.name}`
          );
        } else {
          alert("Upload failed.");
        }

        setIsUploading(false); // Reset uploading state
      }
    } catch (error) {
      console.error("Upload error:", error);
      alert("Upload failed.");
      setIsUploading(false); // Reset uploading state
    }
  };

  return (
    <div>
      <h1>Multipart Upload</h1>
      <br />
      {/* Input field to select file */}
      <input type="file" onChange={handleFileChange} name="file" id="myFile" />
      <br />
      {/* Button to upload file */}
      <button
        onClick={handleUpload}
        disabled={isUploading}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
      >
        {isUploading ? "Uploading..." : "Upload"}
      </button>
      <br />
      {/* Display the uploaded file URL */}
      {fileUrl && (
        <div>
          <h3>File Uploaded Successfully</h3>
          <p>
            File URL:{" "}
            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
              {fileUrl}
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default FileUpload;





































// import React, { useState } from "react";
// import axios from "axios";

// const  FileUpload = () => {
//   const [file, setFile] = useState(null); // Store the selected file
//   const [isUploading, setIsUploading] = useState(false); // Track uploading state

//   // Handle file input change
//   const handleFileChange = (event) => {
//     const files = event.target.files;
//     if (files && files[0]) {
//       setFile(files[0]);
//     }
//   };

//   // Handle file upload
//   const handleUpload = async () => {
//     if (!file) return; // Ensure a file is selected

//     try {
//       // Set uploading state
//       setIsUploading(true);

//       // Check if the file size is less than 10MB (single upload)
//       if (file.size < 10000000) {
//         // Call the API to get the presigned URL for single file upload
//         const response = await axios.post(
//           `${process.env.REACT_APP_API_URL}/generate-single-presigned-url`,
//           {
//             fileName: file.name,
//           }
//         );
//         const { url } = response.data;

//         // Upload file using presigned URL
//         const uploadResponse = await axios.put(url, file, {
//           headers: {
//             "Content-Type": file.type,
//           },
//         });

//         // Check upload response
//         if (uploadResponse.status === 200) {
//           alert("File uploaded successfully.");
//         } else {
//           alert("Upload failed.");
//         }
//         setIsUploading(false); // Reset uploading state
//       } else {
//         // Multipart upload for large files (more than 10MB)
//         const startUploadResponse = await axios.post(
//           `${process.env.REACT_APP_API_URL}/start-multipart-upload`,
//           {
//             fileName: file.name,
//             contentType: file.type,
//           }
//         );

//         const { uploadId } = startUploadResponse.data;

//         // Chunk file into 10MB parts
//         const chunkSize = 10000000; // 10MB
//         const numChunks = Math.ceil(file.size / chunkSize);
//         const totalSize = file.size;

//         // Request presigned URLs for each part
//         const presignedUrlsResponse = await axios.post(
//           `${process.env.REACT_APP_API_URL}/generate-presigned-url`,
//           {
//             fileName: file.name,
//             uploadId,
//             partNumbers: numChunks,
//           }
//         );

//         const presignedUrls = presignedUrlsResponse.data.presignedUrls;

//         const parts = [];
//         const uploadPromises = [];

//         // Upload each chunk separately
//         for (let i = 0; i < numChunks; i++) {
//           const start = i * chunkSize;
//           const end = Math.min(start + chunkSize, totalSize);
//           const chunk = file.slice(start, end);

//           const presignedUrl = presignedUrls[i];

//           uploadPromises.push(
//             axios.put(presignedUrl, chunk, {
//               headers: {
//                 "Content-Type": file.type,
//               },
//             })
//           );
//         }

//         // Wait for all chunks to upload
//         const uploadResponses = await Promise.all(uploadPromises);

//         uploadResponses.forEach((response, i) => {
//           parts.push({
//             etag: response.headers.etag,
//             PartNumber: i + 1,
//           });
//         });

//         // Complete multipart upload
//         const completeUploadResponse = await axios.post(
//           `${process.env.REACT_APP_API_URL}/complete-multipart-upload`,
//           {
//             fileName: file.name,
//             uploadId,
//             parts,
//           }
//         );

//         if (completeUploadResponse.status === 200) {
//           alert("File uploaded successfully.");
//         } else {
//           alert("Upload failed.");
//         }

//         setIsUploading(false); // Reset uploading state
//       }
//     } catch (error) {
//       alert("Upload failed.");
//       setIsUploading(false); // Reset uploading state
//     }
//   };

//   return (
//     <div>
//       <h1>Multipart Upload</h1>
//       <br />
//       {/* Input field to select file */}
//       <input type="file" onChange={handleFileChange} name="file" id="myFile" />
//       <br />
//       {/* Button to upload file */}
//       <button
//         onClick={handleUpload}
//         disabled={isUploading}
//         className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
//       >
//         {isUploading ? "Uploading..." : "Upload"}
//       </button>
//     </div>
//   );
// }

// export default FileUpload