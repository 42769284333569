import "./index.css"
import IconSidebar from "../../../IconSidebar";
import Popup from "reactjs-popup";
import { useState } from "react";
import { FaGear } from "react-icons/fa6";
import { FaUserCircle } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { FaIdCard, FaPassport, FaUserShield, FaWhatsapp, FaEnvelopeOpenText, FaEnvelope, FaCommentDots, FaTelegramPlane, FaCalendarAlt, FaRegClock, FaGoogle, FaFileAlt, FaPlug, FaChartLine, FaLock, FaShieldAlt, FaHandsHelping, FaPoll, FaCloudUploadAlt, FaFileSignature, FaCreditCard, FaFileInvoiceDollar, FaShareAlt, FaChartBar } from 'react-icons/fa';
import { v4 as uuidv4 } from 'uuid';
import { FaTrash } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { IoEyeOff } from "react-icons/io5"

const tools = [
  {
    icon: FaIdCard,
    title: "Aadhaar Verify - KYC",
    description: "Facilitates Aadhaar-based verification for Know Your Customer (KYC) processes, ensuring accurate identity validation.",
    id: uuidv4(),
    category: "Verification",
    visibility : true
  },
  {
    icon: FaPassport,
    title: "PAN Verify - KYC",
    description: "Provides PAN card verification to streamline KYC processes by validating tax identification details.",
    id: uuidv4(),
    category: "Verification",
    visibility : true
  },
  {
    icon: FaUserShield,
    title: "CIBIL Score Verify",
    description: "Integrates CIBIL score checks to evaluate the creditworthiness of individuals for financial and lending decisions.",
    id: uuidv4(),
    category: "Verification",
    visibility : true
  },
  {
    icon: FaWhatsapp,
    title: "WhatsApp Message",
    description: "Enables sending messages via WhatsApp for real-time notifications, reminders, and communications.",
    id: uuidv4(),
    category: "Messaging",
    visibility : true
  },
  {
    icon: FaEnvelopeOpenText,
    title: "Send Thank You Email",
    description: "Automates sending thank you emails to foster relationships and express appreciation after interactions or events.",
    id: uuidv4(),
    category: "Messaging",
    visibility : true
  },
  {
    icon: FaEnvelope,
    title: "Welcome Email",
    description: "Sends a personalized welcome email to new users or clients, setting a positive tone and providing essential information.",
    id: uuidv4(),
    category: "Messaging",
    visibility : true
  },
  {
    icon: FaCommentDots,
    title: "Feedback Email",
    description: "Sends feedback request emails to collect insights and opinions from recipients, helping improve services or products.",
    id: uuidv4(),
    category: "Messaging",
    visibility : true
  },
  {
    icon: FaTelegramPlane,
    title: "Send Files to Telegram",
    description: "Facilitates the sending of various file types directly to Telegram, allowing for easy and efficient sharing of documents, images, and more.",
    id: uuidv4(),
    category: "Messaging",
    visibility : true
  },
  {
    icon: FaCalendarAlt,
    title: "Email Reminder",
    description: "Sends automated email reminders to keep recipients informed about upcoming tasks, events, or deadlines.",
    id: uuidv4(),
    category: "Scheduling",
    visibility : true
  },
  {
    icon: FaRegClock,
    title: "Meet Calendar Booking",
    description: "Integrates calendar booking features to simplify the scheduling of meetings, appointments, and events, enhancing time management.",
    id: uuidv4(),
    category: "Scheduling",
    visibility : true
  },
  {
    icon: FaGoogle,
    title: "Add Data to Google Sheet",
    description: "Automates the addition and updating of data in Google Sheets, ensuring seamless data management and accessibility.",
    id: uuidv4(),
    category: "Data Management",
    visibility : true
  },
  {
    icon: FaGoogle,
    title: "Looker Studio",
    description: "Creates customizable reports and dashboards for data visualization and sharing.",
    id: uuidv4(),
    category: "Data Management",
    visibility : true
  },
  {
    icon: FaPlug,
    title: "Zapier Integration",
    description: "Automates workflows by connecting apps and services, allowing data to move between them based on defined triggers and actions.",
    id: uuidv4(),
    category: "Automation",
    visibility : true
  },
  {
    icon: FaPlug,
    title: "Webhook Trigger",
    description: "Sends data to a specified URL when certain events occur, enabling real-time integrations and automation.",
    id: uuidv4(),
    category: "Automation",
    visibility : true
  },
  {
    icon: FaChartLine,
    title: "Google Analytics Integration",
    description: "Tracks user interactions on websites or apps, providing insights into user behavior, traffic sources, and engagement metrics.",
    id: uuidv4(),
    category: "Analytics & Reporting",
    visibility : true
  },
  {
    icon: FaChartLine,
    title: "Dashboard Reports",
    description: "Creates and shares customized reports that aggregate data from various sources into visual dashboards for better decision-making.",
    id: uuidv4(),
    category: "Analytics & Reporting",
    visibility : true
  },
  {
    icon: FaLock,
    title: "Two-Factor Authentication (2FA)",
    description: "Adds an extra layer of security to user accounts by requiring a second form of verification, such as a code sent to a mobile device.",
    id: uuidv4(),
    category: "Security",
    visibility : true
  },
  {
    icon: FaShieldAlt,
    title: "Data Encryption",
    description: "Encrypts sensitive data to protect it from unauthorized access, ensuring privacy and compliance with data protection standards.",
    id: uuidv4(),
    category: "Security",
    visibility : true
  },
  {
    icon: FaHandsHelping,
    title: "Live Chat Support",
    description: "Provides real-time chat support on websites or apps, enhancing customer service and engagement.",
    id: uuidv4(),
    category: "Customer Engagement",
    visibility : true
  },
  {
    icon: FaPoll,
    title: "Survey & Poll Integration",
    description: "Collects customer feedback and opinions through integrated surveys and polls, helping refine products or services.",
    id: uuidv4(),
    category: "Customer Engagement",
    visibility : true
  },
  {
    icon: FaCloudUploadAlt,
    title: "Cloud Storage Integration",
    description: "Connects with cloud storage services like Google Drive, Dropbox, or OneDrive to upload, organize, and share files easily.",
    id: uuidv4(),
    category: "File Management",
    visibility : true
  },
  {
    icon: FaFileSignature,
    title: "Document Signing (e.g., DocuSign)",
    description: "Facilitates secure electronic document signing, streamlining the approval process for contracts, agreements, and other paperwork.",
    id: uuidv4(),
    category: "File Management",
    visibility : true
  },
  {
    icon: FaCreditCard,
    title: "Payment Gateway Integration",
    description: "Connects with payment processors like Stripe, PayPal, or Razorpay to accept online payments securely and efficiently.",
    id: uuidv4(),
    category: "Payment Processing",
    visibility : true
  },
  {
    icon: FaFileInvoiceDollar,
    title: "Invoice Management",
    description: "Automates the creation, sending, and tracking of invoices, simplifying the billing process for businesses and clients.",
    id: uuidv4(),
    category: "Payment Processing",
    visibility : true
  },
  {
    icon: FaShareAlt,
    title: "Social Media Posting",
    description: "Schedules and publishes posts across multiple social media platforms, helping maintain a consistent online presence.",
    id: uuidv4(),
    category: "Social Media",
    visibility : true
  },
  {
    icon: FaChartBar,
    title: "Social Media Analytics",
    description: "Analyzes social media performance metrics to measure engagement, reach, and overall impact of marketing efforts.",
    id: uuidv4(),
    category: "Social Media",
    visibility : true
  }
];

const categories = [
  "Verification",
  "Messaging",
  "Scheduling",
  "Data Management",
  "Automation",
  "Analytics & Reporting",
  "Security",
  "Customer Engagement",
  "File Management",
  "Payment Processing",
  "Social Media"
];

const AdminTools = () => {
    const [meetingsList, setMeetingsList] = useState(tools);
    const [activeMeetingTab, setActiveMeetingTab] = useState('Available');
    const [activeFunctionTab, setActiveFunctionTab] = useState('Headers')
    const [filteredList, setFilteredList] = useState(tools);
    const [attendees, setAttendees] = useState([{ email: '', name: '' }]);
    const [toolname, setToolName] = useState('');
    const [tooldescription, setToolDescription] = useState('');

    const handleAddAttendee = () => {
      setAttendees([...attendees, { email: '', name: '' }]);
    };

    const handleRemoveAttendee = (index) => {
      const newAttendees = [...attendees];
      newAttendees.splice(index, 1);
      setAttendees(newAttendees);
    };

    const handleFilter = (tab) => {
      if(tab==="ALL")
        setFilteredList(meetingsList);
      else{
        setFilteredList(meetingsList.filter((ele) => ele.category===tab));
      }
    }
    const handleActiveTabChange = (uniqueId,value) => {
      const newList = meetingsList.map((ele) => {
        if(ele.id===uniqueId)
        {
          return {...ele,status:value}
        }
        return ele;
      })
      setMeetingsList(newList);
    }

    const handleActiveMeetingTab = (value) => {
        setActiveMeetingTab(value);
    }

    const handleActiveFunctionTabChange = (value) => {
      setActiveFunctionTab(value)
    }

    const onChangeVisibility = (elem,status) => {
      const newList = meetingsList.map((ele) => {
        if(ele.id===elem.id){
          return {...ele,visibility:status}
        }
        return ele;
      })
      setMeetingsList(newList);
      setFilteredList(newList);
    }

    const addNewTool = (e) => {
      e.preventDefault();
      const toolData = {
        id:uuidv4(),
        title : toolname,
        description : tooldescription
      }
      console.log(toolData);
    }

    

    


    return (
        <div className="main-body-container">
     <IconSidebar />
         <div className="business-container w-80">
            <div className="business-container-header">
                <h1 className="bc-main-heading">Tools</h1>
                <div>
                <select onChange={(e) => handleFilter(e.target.value)} style={{width:'250px'}}>
                <option value="ALL">ALL</option>
                {categories.map((ele,index) => (
                  <option key={index} value={ele}>{ele}</option>
                ))}
                </select>
                <Popup
            trigger={<button className="ml-3 addnew-button">Add Tool</button>}
            modal
            nested
            >
            {close => (
                <div style={{ width: '600px' }} className="hidden-scrollbar">
                <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                    <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
                    <h3 style={{fontSize:'20px'}} className="font-bold mb-6">Add Tool</h3>
                    <form onSubmit={(e) => {
                        addNewTool(e)
                        close();
                    }} className="">
                        <div className="relative">
                        <label
                            htmlFor="toolname"
                        >
                            Name
                        </label>
                        <input
                            type="text"
                            id="toolname"
                            value={toolname}
                            onChange={(e) => setToolName(e.target.value)}
                            className="input-model"
                            placeholder="Enter the Tool Name"
                            required
                        />
                        </div>
                        <br/>

                        {/* Number Field */}
                        <div className="relative">
                        <label htmlFor="number">Description</label>
                        <input
                            type="text"
                            id="description"
                            value={tooldescription}
                            onChange={(e) => setToolDescription(e.target.value)}
                            className="input-model"
                            placeholder="Enter the Tool Description"
                            required
                        />
                        </div>
                        <br/>

                        {/* Submit Button */}
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <button
                        onClick = {() => close()}
                        type="button"
                        className="col-span-2 bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
                        >
                        Cancel
                        </button>
                        <button
                        type="submit"
                        className="col-span-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
                        >
                        Add
                        </button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            )}
            </Popup>
                </div>
            </div>
            <div className="template-container">
            <div className="mb-5 meeting-tabs-container">
              <div className="meeting-tabs">
                <button onClick={() => handleActiveMeetingTab("Available")} type="button" className={`meeting-tab-button ${activeMeetingTab==="Available"?'active':''}`}>Available</button>
                <button onClick={() => handleActiveMeetingTab("Coming Soon")} type="button" className={`meeting-tab-button ${activeMeetingTab==="Coming Soon"?'active':''}`}>Coming Soon</button>
              </div>
              </div>
             {activeMeetingTab==="Available" && ( <div className="hidden-scrollbar" style={{ maxHeight: '500px',overflowY:'auto', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }} >
  {filteredList.map((meeting, index) => (
    <div key={index} style={{ flexBasis: '48%', marginBottom: '20px',color:`${meeting.visibility===false?'gray':'white'}` }} className="template-card">
      <div className="template-heading-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p><meeting.icon size={30} /></p>
        {/* Popups */}
        <div style={{display:'flex',alignItems:'center'}}>
        <div>
        {meeting.visibility===true && (<button onClick={() => {
                    onChangeVisibility(meeting,false)
                    }} style={{marginRight:'10px'}}  className=" text-white-600 hover:text-white-900 focus:outline-none"><FaEye size={30}  /></button>)}
                    {meeting.visibility===false && (<button onClick={() => {
                      onChangeVisibility(meeting,true)
                    }} style={{marginRight:'10px'}}  className=" text-white-600 hover:text-white-900 focus:outline-none"><IoEyeOff size={30}  /></button>)}
          </div>
    <div>
  {meeting.category==="Data Management" && (  <Popup
          trigger={<p className="ml-2"><FaGear size={20} /></p>}
          modal
          nested
        >
          {close => (
            <div style={{ width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
              <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
        <h1 className="text-white text-2xl mb-4">{meeting.title}</h1>
        
        <div className="mb-4">
          <label className="text-white mb-1 block">Calendar</label>
          <input type="text" placeholder="Enter email to link calendar" className="w-full p-2 bg-gray-700 text-white rounded-md" />
        </div>

        <div className="flex items-center mb-4">
          <input type="checkbox" checked={true} id="addGmeet" />
          <label htmlFor="addGmeet" className="text-blue-400 ml-2">Add Google Meet Video Conference</label>
          <select style={{width:'150px',marginLeft:'auto'}} className="bg-gray-700 text-white p-2 rounded-md">
            <option value="15">15m (Default)</option>
            <option value="30">30m (Default)</option>
            <option value="60">60m (Default)</option>
            {/* Add more duration options here */}
          </select>
        </div>

        <div className="mb-4">
          <label className="text-white mb-1 block">Event Title</label>
          <input
            type="text"
            placeholder="Added by AI Assistant"
            className="w-full p-2 bg-gray-700 text-white rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="text-white mb-1 block">Attendees</label>
          {attendees.map((attendee, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="email"
                placeholder="person-email"
                className="p-2 bg-gray-700 text-white rounded-md w-full mr-2"
              />
              <input
                type="text"
                placeholder="person-name"
                className="p-2 bg-gray-700 text-white rounded-md w-full mr-2"
              />
              <button onClick={() => handleRemoveAttendee(index)} className="text-red-500">
                <FaTrash />
              </button>
            </div>
          ))}
          <button onClick={handleAddAttendee} className="text-green-500">+ Add Attendee</button>
        </div>

        <div className="mb-4">
          <label className="text-white mb-1 block">Meeting Notes</label>
          <textarea
            className="w-full p-2 bg-gray-700 text-white rounded-md"
            placeholder="Meeting booked by your AI assistant."
            rows="4"
          />
        </div>

        <div className="text-center">
          <button type="button" className="bg-green-500 text-white px-4 py-2 rounded-md">Save</button>
        </div>
              </div>
              </div>
            </div>
          )}
        </Popup>)}
        {meeting.category!=="Data Management" && (  
          <Popup
          trigger={
            <p className="ml-2"><FaGear size={20} /></p>
          }
          modal
          nested
        >
          {close => (
            <div style={{width:'600px',height:'600px',overflowY:'auto'}} className="hidden-scrollbar bg-gray-800 text-white p-6 rounded-lg max-w-lg w-full shadow-lg">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-bold">Function</h2>
                <button
                  className="text-white bg-red-500 px-2 py-1 rounded-full"
                  onClick={close}
                >
                  X
                </button>
              </div>
  
              {/* Form */}
              <form className="mt-4">
                <div className="mb-4">
                  <label className="block text-sm font-medium">Name</label>
                  <input
                    type="text"
                    placeholder="Action name"
                    className="w-full p-2 mt-2 rounded bg-gray-700 border border-gray-600 text-white"
                  />
                </div>
  
                <div className="mb-4">
                  <label className="block text-sm font-medium">Description</label>
                  <textarea
                    placeholder="Action description"
                    className="w-full p-2 mt-2 rounded bg-gray-700 border border-gray-600 text-white"
                  />
                </div>
  
                <div className="mb-4">
                  <label className="block text-sm font-medium">Endpoint URL</label>
                  <input
                    type="text"
                    placeholder="Action endpoint url"
                    className="w-full p-2 mt-2 rounded bg-gray-700 border border-gray-600 text-white"
                  />
                </div>
  
                <div className="mb-4">
                  <label className="block text-sm font-medium">Method</label>
                  <select className="w-full p-2 mt-2 rounded bg-gray-700 border border-gray-600 text-white">
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                    <option value="PUT">PUT</option>
                    <option value="DELETE">DELETE</option>
                  </select>
                </div>
  
                {/* Tabs for Headers, Body, Query, URL */}
                <div className="flex space-x-4 mb-4">
                  <button
                    type="button"
                    className={`${activeFunctionTab==="Headers"?'bg-green-600':'bg-gray-700'} py-2 px-4 rounded-lg`}
                    onClick={() =>setActiveFunctionTab("Headers")}
                  >
                    Headers
                  </button>
                  <button
                    type="button"
                    onClick={() =>setActiveFunctionTab("Body")}
                    className={`${activeFunctionTab==="Body"?'bg-green-600':'bg-gray-700'} py-2 px-4 rounded-lg`}
                  >
                    Body
                  </button>
                  <button
                  onClick={() =>setActiveFunctionTab("Query")}
                    type="button"
                    className={`${activeFunctionTab==="Query"?'bg-green-600':'bg-gray-700'} py-2 px-4 rounded-lg`}
                  >
                    Query
                  </button>
                  <button
                  onClick={() =>setActiveFunctionTab("URL")}
                    type="button"
                    className={`${activeFunctionTab==="URL"?'bg-green-600':'bg-gray-700'} py-2 px-4 rounded-lg`}
                  >
                    URL
                  </button>
                </div>
  
                {/* Add Header */}
                <div className="mb-4">
                  <label className="block text-sm font-medium">{activeFunctionTab}</label>
                  <div className="flex space-x-2 mt-2">
                    <input
                      type="text"
                      placeholder="Key"
                      className="w-full p-2 rounded bg-gray-700 border border-gray-600 text-white"
                    />
                    <select className="w-full p-2 rounded bg-gray-700 border border-gray-600 text-white">
                      <option value="string">string</option>
                      <option value="number">number</option>
                    </select>
                  </div>
                  <textarea
                    placeholder="Description"
                    className="w-full p-2 mt-2 rounded bg-gray-700 border border-gray-600 text-white"
                  />
                </div>
  
                {/* Checkbox */}
                <div className="mb-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-green-600"
                    />
                    <span className="ml-2">Add parameter from conversation</span>
                  </label>
                </div>
  
                {/* Submit Button */}
                <button
                  type="submit"
                  className="w-full bg-green-600 py-2 px-4 rounded-lg"
                >
                  Save
                </button>
              </form>
            </div>
          )}
        </Popup>
        )}
        </div>
        </div>









        {/* <Popup
          trigger={<p className="ml-2"><FaGear size={20} /></p>}
          modal
          nested
        >
          {close => (
            <div style={{ width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
              <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
                </div>
              </div>
            </div>
          )}
        </Popup> */}




























      </div>
      <h1 style={{ fontSize: '18px', marginTop: '8px', color: 'yellow', width: '90%' }} className="template-heading">
        {meeting.title}
      </h1>
      <p>{meeting.description}</p>
      <p>{meeting.participant}</p>
      <p>{meeting.participantEmail}</p>
      <p>{meeting.participantMobileNo}</p>
      <div style={{ textAlign: 'right', marginTop: '20px' }}>
        <a href={meeting.meetUrl} style={{ color: 'white', textDecoration: 'none' }}>
          <button className="join-button-new" type="button">Activity</button>
        </a>
      </div>
    </div>
  ))}
</div>)}
            {activeMeetingTab==="Coming Soon" && (
                <h1 style={{width:'100%',fontWeight:'bold',textAlign:'center',color:'white',fontSize:'40px'}}>Coming Soon</h1>
                )}


            </div>
            </div>
       </div>
    )
}

export default AdminTools;





