import "./index.css"
import IconClientSidebar from "../../../IconClientSidebar";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import { useState } from "react";
import { Link } from "react-router-dom";
import {v4 as uuidv4} from 'uuid';
import { useEffect } from "react";

const Campaign = () => {
    const [formData, setFormData] = useState({
        campaignName:'',
        campaignCategory:'',
        status:'active'
    })
    const [message, setMessage] = useState('');
    const [messageStyle, setMessageStyle] = useState('');
    const [campaignsList, setCampaignsList] = useState([]);
    let user = localStorage.getItem("user");
    if(user){
      user = JSON.parse(user);
    }

    useEffect(() => {
      const getItems = async () => {
        try {
          const options = {
            method : "POST",
            headers : {
              "Content-Type" : "application/json"
            },
            body:JSON.stringify({email:user.email,businessname:user.businessName})
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/getcampaigns`,options);
          const data = await response.json();
          if(data.CampaignArray)
          setCampaignsList(data.CampaignArray)
        } catch (err) {
          console.error(`Error Occurred: ${err}`);
        }
      };
  
      getItems();
    }, []);


    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
          ...formData,
          [id]: value
        });
      };
      
      const postData = async (obj) => {
        try{
          const options = {
            method : "POST",
            headers : {
              "Content-Type" : "application/json"
            },
            body:JSON.stringify({ email:user.email, CampaignItem : {...obj,businessname:user.businessName} })
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/addcampaign`,options)
          const data = await response.json();
          if (data.success) {
            // On success, set the success message
            setMessage('Campaign Inserted Successfully');
            setMessageStyle('green');
            
            // Automatically hide the message after 3 seconds
            setTimeout(() => {
              setMessage('');
            }, 3000);
            return true;
          } else {
            setMessageStyle('red');
            setMessage(data.message);
            setTimeout(() => {
              setMessage('');
            }, 3000);
          }
        }
        catch(Err){
          setMessageStyle('red');
          setMessage('An error occurred');
        setTimeout(() => {
          setMessage('');
        }, 3000);
          console.log(`Error Occurred : ${Err}`)
        }
      }
  
      const deleteData = async (obj) => {
        try {
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              email: user.email,
              CampaignItem: {
                id: obj
              }
            })
          };
      
          const response = await fetch(`${process.env.REACT_APP_API_URL}/deletecampaign`, options);
          
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const data = await response.json();
      
          if (data.success) {
            // On success, set the success message
            setMessage(data.message);
            setMessageStyle('green');
            
            // Automatically hide the message after 3 seconds
            setTimeout(() => {
              setMessage('');
            }, 3000);
      
            // Refresh or update the telephony list
            setCampaignsList(campaignsList.filter((ele) => ele.id!==obj)); // Ensure this function is correctly implemented
          } else {
            setMessageStyle('red');
            setMessage(data.message);
            setTimeout(() => {
              setMessage('');
            }, 3000);
          }
        } catch (Err) {
          setMessageStyle('red');
          setMessage('An error occurred');
          setTimeout(() => {
            setMessage('');
          }, 3000);
          console.error(`Error Occurred: ${Err}`);
        }
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const finalData = {
            id : uuidv4(),
            campaignname : formData.campaignName,
            campaigncategory : formData.campaignCategory,
            status : formData.status,
            date : (new Date()).toLocaleDateString('en-GB')+"\n"+(new Date()).toLocaleTimeString('en-GB'),
            users : [],
            businessname:user.businessName
        }
        const res = await postData(finalData);
        if(res){
        setCampaignsList([finalData,...campaignsList]);
        setFormData({
            campaignName : '',
            campaignCategory : ''
        })
      }
    }

    console.log(campaignsList)
    

    


    return (
         <div className="business-container">
          {message && <div style={{backgroundColor:`${messageStyle==='green'?'green':'red'}`}} className="notification">{message}</div>}
            <div className="business-container-header">
                <h1 className="bc-main-heading">Campaign</h1>
                <Popup
  trigger={<button className="addnew-button">Add Campaign +</button>}
  modal
  nested
>
  {close => (
    <div style={{ width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
      <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
        <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
          <h2 className="text-2xl font-bold mb-6">Add Campaign</h2>
          <form onSubmit={(e) => {
            handleSubmit(e);
            close();
          }}>
            
            {/* Group Name Field */}
            <div className="relative">
              <input
                type="text"
                id="campaignName"
                value={formData.campaignName}
                onChange={handleChange}
                className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
                placeholder="Campaign Name"
                required
              />
              <label
                htmlFor="campaignName"
                className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
              >
                Campaign Name
              </label>
            </div>
            <br/>

            {/* Group Category Field */}
            <div className="relative w-100">
              <input
                type="text"
                id="campaignCategory"
                value={formData.campaignCategory}
                onChange={handleChange}
                className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
                placeholder="Campaign Category"
                required
              />
              <label
                htmlFor="campaignCategory"
                className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
              >
                Campaign Category
              </label>
            </div>
            <br/>
            {/* <div className="relative col-span-2">
          <select
            id="status"
            value={formData.status}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300"
            required
          >
            <option value="" disabled>Select Status</option>
            <option value="Inactive">Inactive</option>
            <option value="Active">Active</option>
          </select>
          <label
            htmlFor="status"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Status
          </label>
        </div> */}

            {/* Submit Button */}
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <button
            onClick = {() => close()}
              type="button"
              className="col-span-2 bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="col-span-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Add
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )}
</Popup>

            </div>
            <div>
            <div className="table-outer-container bg-[#444444] mt-5 p-4">
      <table className="min-w-full bg-[#15161A] text-white rounded-lg shadow-lg">
        <thead>
          <tr style={{ color: 'white', fontSize: '16px' }}>
            <th className="w-1/12 py-3 px-4 text-left border-b border-gray-700">S.No</th>
            <th className="w-1/12 py-3 px-4 text-left border-b border-gray-700">Date</th>
            <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Campaign Name</th>
            <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Category</th>
            <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Status</th>
            <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Action</th>
          </tr>
        </thead>
        <tbody>
          {campaignsList.map((ele, index) => (
            <tr key={index} className="hover:bg-gray-700">
              <td className="py-3 px-4 border-b border-gray-700">{index + 1}</td>
              <td className="py-3 px-4 border-b border-gray-700">{ele.date}</td>
              <td className="py-3 px-4 border-b border-gray-700">
                <Link to={`/campaigns/campaign/${ele.campaignname}`}>
                  {ele.campaignname}
                </Link>
              </td>
              <td className="py-3 px-4 border-b border-gray-700">{ele.campaigncategory}</td>
              <td className="py-3 px-4 border-b border-gray-700">{ele.status}</td>
              <td style={{border:'none'}} className="py-3 px-4 border-b border-gray-700 flex items-center">
                <button className="bg-transparent mr-3" type="button">
                  <FaEdit size={20} />
                </button>
                <Popup
                  trigger={<button className="bg-transparent" type="button"><MdDelete size={20} /></button>}
                  modal
                  nested
                >
                  {close => (
                    <div
                      style={{
                        width: '300px',
                        height: '250px',
                        padding: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#2d3748',
                        color: 'white',
                        borderRadius: '10px',
                        textAlign: 'center',
                      }}
                    >
                      <h1 style={{ fontSize: '20px' }}>Are you sure you want to delete this campaign?</h1>
                      <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                        <button
                          onClick={() => {
                            close()
                          }}
                          className="addnew-button"
                          style={{ width: '60px', fontSize: '12px', marginRight: '5px' }}
                        >
                          Close
                        </button>
                        <button
                          onClick={() => {
                            deleteData(ele.id)
                            close();
                          }}
                          className="addnew-button"
                          style={{
                            backgroundColor: 'red',
                            width: '60px',
                            fontSize: '12px',
                            color: 'white',
                            marginLeft: '5px',
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  )}
                </Popup>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
            {/* <div className="table-outer-container bg-[#444444] mt-5 p-4">
  <table className="min-w-full bg-[#15161A] text-white rounded-lg shadow-lg">
    <thead>
      <tr style={{color:'white',fontSize:'16px'}}>
        <th className="w-1/12 py-3 px-4 text-left border-b border-gray-700">S.No</th>
        <th className="w-1/12 py-3 px-4 text-left border-b border-gray-700">Date</th>
        <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Campaign Name</th>
        <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Category</th>
        <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Status</th>
        <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Action</th>
      </tr>
    </thead>
    <tbody>
        {campaignsList.map((ele,index) => (
                  <tr className="hover:bg-gray-700">
                  <td className="py-3 px-4 border-b border-gray-700">{index+1}</td>
                  <td className="py-3 px-4 border-b border-gray-700">{ele.date}</td>
                  <td className="py-3 px-4 border-b border-gray-700">
                  <Link to={`/campaigns/campaign/${ele.campaignName}`}>
                    {ele.campaignName}
                  </Link>
                  </td>
                  <td className="py-3 px-4 border-b border-gray-700">{ele.campaignCategory}</td>
                  <td className="py-3 px-4 border-b border-gray-700">{ele.status}</td>
                  <td className="py-3 px-4 border-b border-gray-700">
                      <button className="bg-transparent mr-3" type="button"><FaEdit size={20}/></button>
                      <Popup
          trigger={<button className="bg-transparent mr-3" type="button"><MdDelete size={20}/></button>}
          modal
          nested
        >
          {close => (
            <div
              style={{
                width: '300px',
                height: '250px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#2d3748',
                color: 'white',
                borderRadius: '10px',
                textAlign: 'center',
              }}
            >
              <h1 style={{fontSize:'20px'}}>Are you sure you want to delete Campaign?</h1>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                <button
                  onClick={() => {
                    close()
                }}
                  className="addnew-button"
                  style={{ width: '60px', fontSize: '12px', marginRight: '5px' }}
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    close();
                  }}
                  className="addnew-button"
                  style={{
                    backgroundColor: 'red',
                    width: '60px',
                    fontSize: '12px',
                    color: 'white',
                    marginLeft: '5px',
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </Popup>

                  </td>
                </tr>
        ))}

    </tbody>
  </table>
</div> */}


                {/* <table className="">
                    <thead>
                        <tr>
                        <th>S.NO</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Details</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                    </tbody>
                </table> */}
            </div>

       </div>
    )
}

export default Campaign;