import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './index.css';
import Popup from 'reactjs-popup';
import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../Context/userContext';



const MyNavbar = () => {
  const navigate = useNavigate();
  const {loginUser} = useUser();


  const checkUser = async (email) => {
    try{
        const options = {
            method : "POST",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({email})
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/checkuser`,options);
        const data = await response.json();
        console.log(data);
        if(data.exist===true && data.regstatus==="approved"){
          // loginUser(data.user);
            return true;
        }
        else if(data.exist===true && data.regstatus==="pending"){
            return "pending";
        }
        else if(data.exist===true && data.regstatus==="rejected"){
            return "rejected";
        }
        else
        return false;
    }
    catch(Err){
        console.log(`Error Occurred : ${Err}`);
    }
  }

  return (
    <Navbar bg="transparent" expand="lg">
      <Container style={{width:'100%',backgroundColor:'black'}}>
        <Navbar.Brand as={Link} to="/" className="text-white navbar-brand-custom d-flex align-items-center">
          Aitota
        </Navbar.Brand>
        <Nav.Link as={Link} to="/help" className="text-white d-flex align-items-center small-btn-custom " style={{ fontSize: '20px' }}>
              <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#25D366', fontSize: '21px', marginRight: '8px' }} />
              Help
            </Nav.Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="navbar-toggle-custom" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/about" className="text-white nav-link-custom">Home</Nav.Link>
            <Nav.Link as={Link} to="/about" className="text-white nav-link-custom">About</Nav.Link>
            <Nav.Link as={Link} to="/business" className="text-white nav-link-custom">Business</Nav.Link>
            <Nav.Link style={{textDecoration:'none',color:'white'}} as={Link} to="/blogs" className="text-white nav-link-custom">Blog</Nav.Link>
          </Nav>
          <Nav>
          {/* <Nav.Link className="text-white d-flex align-items-center btn-custom " style={{ fontSize: '20px' }}>
              <FontAwesomeIcon icon={faWhatsapp} style={{ color: '#25D366', fontSize: '21px', marginRight: '8px' }} />
              Help
            </Nav.Link> */}
            <Popup
        trigger={ <button className='signup-button' as={Link} to="/signup">Sign Up</button>}
        modal
        nested
      >
        {close => (
          <div 
          style={{ width: '300px' }} 
          className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg"
        >
          <h1 className="text-2xl font-bold mb-4">Sign In</h1>
          <div className="flex flex-col items-center mt-4">
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <GoogleLogin
                onSuccess={async (credentialResponse) => {
                  Cookies.set("userId",credentialResponse.credential)
                  const {email} = jwtDecode(credentialResponse.credential);
                  const res = await checkUser(email);
                  if(res===false)
                  navigate("/registrationform",{replace:true});
                  else if(res===true)
                  navigate("/performance",{replace:true});
                  else if(res==="pending")
                      navigate("/regpending",{replace:true});
                  else if(res==="rejected")
                      navigate("/regrejected", {replace:true});
                }
              }
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </GoogleOAuthProvider>
          </div>
          <div className="mt-6">
            <button
              className="bg-red-600 text-white p-2 rounded-md hover:bg-red-700 transition-colors duration-300"
              onClick={() => close()}
            >
              Close
            </button>
          </div>
        </div>
        )}
      </Popup>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default MyNavbar;