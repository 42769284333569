import "./index.css"
import { FaChartPie, FaBusinessTime, FaTicketAlt, FaComments, FaDollarSign, FaCog, FaRupeeSign, FaUser, FaPhone, FaUsers, FaTools, FaFile, FaChevronUp, FaChevronDown, FaQuestionCircle, FaBook, FaEnvelope } from 'react-icons/fa';
import { IoSettings } from 'react-icons/io5';
import { MdHelpOutline } from 'react-icons/md';
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { MdCampaign } from "react-icons/md";
import { FaMusic } from "react-icons/fa";
import {FaRegFileAlt} from 'react-icons/fa';
import { FaCalendarAlt } from "react-icons/fa";
import {Tooltip} from "@nextui-org/react";
import { BiSolidConversation } from "react-icons/bi";

const IconClientSidebar = () => {
    const [activeTabID, setActiveTabID] = useState("Overview");
    const [activeTabItem, setActiveItem] = useState("");


  const email = jwtDecode(Cookies.get("userId")).email;

  const handleTabItemClick = (item) => {
    setActiveItem(item);
  };

  const handleTabClick = (tabID) => {
    setActiveTabID(tabID);
  };

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith("/performance")) {
      setActiveTabID("performance");
      setActiveItem("performance");
    } else if (path.startsWith("/platform")) {
      setActiveTabID("Platform");
      setActiveItem(path.split("/")[2]);
    } else if (path.startsWith("/campaigns")) {
      setActiveTabID("Campaigns");
      setActiveItem(path.split("/")[2]);
    } else if (path.startsWith("/credits")) {
      setActiveTabID("credits");
      setActiveItem(path.split("/")[2]);
    } else if (path.startsWith("/meetings")) {
      setActiveTabID("Meetings");
      setActiveItem("Meetings");
    } else if (path.startsWith("/conversations")) {
      setActiveTabID("Conversations");
      setActiveItem("Conversations");
    } else if (path.startsWith("/account")) {
      setActiveTabID("Profile");
      setActiveItem("Profile");
    } else if (path.startsWith("/settings")) {
      setActiveTabID("Settings");
      setActiveItem("Settings");
    }
  }, [location]);



    


    return (
        <div className="sidebar-main-container">
            <div className="icon-bar">
              <Link className="nav-link" to="/performance">
                <div className="icon-bar-img-container">
              <img className="icon-sidebar-img-logo" src="https://res.cloudinary.com/haibegwvw/image/upload/v1721307308/file_krjkgp.png" alt="logo"/>
              </div>
              </Link>
            <div className="iconic-container">
                <ul className="iconic-list-container">
                <Link className={`nav-link`} to="/performance">
                <Tooltip className="custom-tool" content="Performance" placement="right">
                    <li onClick={() => handleTabClick("performance")} className={`iconic-sidebar-item ${activeTabID === "performance" ? "active" : ""}`}><FaChartPie className="iconic-sidebar-icon"/></li>
                    </Tooltip>
                </Link>
                <Link className={`nav-link `} to="/platform/assistants">
                  <Tooltip className="custom-tool" content="Platform" placement="right">
                    <li onClick={() => handleTabClick("Platform")} className={`iconic-sidebar-item ${activeTabID === "Platform" ? "active" : ""}`}><FaTools className="iconic-sidebar-icon"/></li>
                    </Tooltip>
                    </Link>
                    <Link className={`nav-link`} to="/campaigns/users">
                    <Tooltip className="custom-tool" content="Campaigns" placement="right">
                    <li onClick={() => handleTabClick("Campaigns")} className={`iconic-sidebar-item ${activeTabID === "Campaigns" ? "active" : ""}`}><MdCampaign className="iconic-sidebar-icon"/></li>
                    </Tooltip>
                    </Link>
                    <Link className={`nav-link`} to="/credits/credits">
                    <Tooltip className="custom-tool" content="Credits" placement="right">
                    <li onClick={() => handleTabClick("credits")} className={`iconic-sidebar-item ${activeTabID === "credits" ? "active" : ""}`}><FaRupeeSign className="iconic-sidebar-icon" /></li>
                    </Tooltip>
                    </Link>
                    <Link className={`nav-link`} to="/meetings">
                    <Tooltip className="custom-tool" content="Meetings" placement="right">
                    <li onClick={() => handleTabClick("Meetings")} className={`iconic-sidebar-item ${activeTabID === "Meetings" ? "active" : ""}`}><FaCalendarAlt className="iconic-sidebar-icon" /></li>
                    </Tooltip>
                    </Link>
                    <Link className={`nav-link`} to="/conversations">
                    <Tooltip className="custom-tool" content="Conversations" placement="right">
                    <li onClick={() => handleTabClick("Call Logs")} className={`iconic-sidebar-item ${activeTabID === "Conversations" ? "active" : ""}`}><BiSolidConversation className="iconic-sidebar-icon" /></li>
                    </Tooltip>
                    </Link>
                </ul>
                <ul className="iconic-list-container">
                    <Link className={`nav-link`} to="/account">
                    <Tooltip className="custom-tool" content="Profile" placement="right">
                    <li onClick={() => handleTabClick("Profile")} className={`iconic-sidebar-item ${activeTabID === "Profile" ? "active" : ""}`}><FaUser className="iconic-sidebar-icon" /></li>
                    </Tooltip>
                    </Link>
                    <Link className="nav-link" to="/settings">
                    <Tooltip className="custom-tool" content="Settings" placement="right">
                    <li onClick={() => handleTabClick("Settings")} className={`iconic-sidebar-item ${activeTabID === "Settings" ? "active" : ""}`} ><IoSettings className="iconic-sidebar-icon" /></li>
                    </Tooltip>
                    </Link>
                </ul>
                </div>
            </div>
            <div className="description-bar">
                <div className="description-main-container">
                <h1 className="description-heading">Aitota</h1> 
                <p style={{marginBottom:'0'}}>Connecting People</p>
                <p style={{marginTop:'0'}}>with AI Voices</p>
                </div>
                {activeTabID==="performance" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <Link className="iconic-link" to="/performance">
                        <li onClick={() => handleTabItemClick("performance")} className={`iconic-tab-item ${activeTabItem === "performance" ? "active" : ""}`}>Performance(KPIs)</li>
                        </Link>
                    </ul>
                    </div>
                )}
                {activeTabID==="Platform" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                      <Link className="nav-link" to="/platform/assistants">
                        <li onClick={() => handleTabItemClick("assistants")} className={`iconic-tab-item ${activeTabItem === "assistants" ? "active" : ""}`}>Assistants</li>
                        </Link>
                        <Link className="nav-link" to="/platform/telephony">
                        <li onClick={() => handleTabItemClick("telephony")} className={`iconic-tab-item ${activeTabItem === "telephony" ? "active" : ""}`}>Telephony</li>
                        </Link>
                        <Link className="nav-link" to="/platform/knowledge">
                        <li onClick={() => handleTabItemClick("knowledge")} className={`iconic-tab-item ${activeTabItem === "knowledge" ? "active" : ""}`}>Knowledge</li>
                        </Link>
                        <Link className="nav-link" to="/platform/tools">
                        <li onClick={() => handleTabItemClick("tools")} className={`iconic-tab-item ${activeTabItem === "tools" ? "active" : ""}`}>Tools</li>
                        </Link>
                        {/* <li onClick={() => handleTabItemClick("Team")} className={`iconic-tab-item ${activeTabItem === "Team" ? "active" : ""}`}>Team</li> */}
                    </ul>
                    </div>
                )}
                {activeTabID==="Campaigns" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                      <Link className="nav-link" to="/campaigns/users">
                        <li onClick={() => handleTabItemClick("users")} className={`iconic-tab-item ${activeTabItem === "users" ? "active" : ""}`}>Users</li>
                        </Link>
                        <Link className="nav-link" to="/campaigns/campaign">
                        <li onClick={() => handleTabItemClick("campaign")} className={`iconic-tab-item ${activeTabItem === "campaign" ? "active" : ""}`}>Campaign</li>
                        </Link>
                        <Link className="nav-link" to="/campaigns/report">
                        <li onClick={() => handleTabItemClick("report")} className={`iconic-tab-item ${activeTabItem === "report" ? "active" : ""}`}>Report</li>
                        </Link>
                        <li onClick={() => handleTabItemClick("live")} className={`iconic-tab-item ${activeTabItem === "live" ? "active" : ""}`}>Live</li>
                    </ul>
                    </div>
                )}
                {activeTabID==="credits" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                      <Link to="/credits/credits" className="nav-link">
                        <li onClick={() => handleTabItemClick("credits")} className={`iconic-tab-item ${activeTabItem === "credits" ? "active" : ""}`}>Credits</li>
                        </Link>
                        <Link className="nav-link" to="/credits/usage">
                        <li onClick={() => handleTabItemClick("usage")} className={`iconic-tab-item ${activeTabItem === "usage" ? "active" : ""}`}>Usage</li>
                        </Link>
                    </ul>
                    </div>
                )}
                {activeTabID==="Meetings" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("Meetings")} className={`iconic-tab-item ${activeTabItem === "Meetings" ? "active" : ""}`}>Meetings</li>
                    </ul>
                    </div>
                )}
                {activeTabID==="Conversations" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("Conversations")} className={`iconic-tab-item ${activeTabItem === "Conversations" ? "active" : ""}`}>Conversations</li>
                    </ul>
                    </div>
                )}
                {activeTabID==="Help" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("FAQs")} className={`iconic-tab-item ${activeTabItem === "FAQs" ? "active" : ""}`}>FAQs</li>
                        <li onClick={() => handleTabItemClick("Training")} className={`iconic-tab-item ${activeTabItem === "Training" ? "active" : ""}`}>Training</li>
                        <li onClick={() => handleTabItemClick("Contact")} className={`iconic-tab-item ${activeTabItem === "Contact" ? "active" : ""}`}>Contact</li>
                    </ul>
                    </div>
                )}
                {activeTabID==="Profile" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("Profile")} className={`iconic-tab-item ${activeTabItem === "Profile" ? "active" : ""}`}>Profile</li>
                    </ul>
                    </div>
                )}
                {activeTabID==="Settings" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("Settings")} className={`iconic-tab-item ${activeTabItem === "Settings" ? "active" : ""}`}>Settings</li>
                    </ul>
                    </div>
                )}
            </div>
        </div>
    )
}

export default IconClientSidebar;