import "./index.css"
import { FaPhone } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import {Popup} from 'reactjs-popup';
import { useNavigate } from "react-router-dom";

const BusinessItem = (props) => {
    const {itemDetails} = props;
    const navigate = useNavigate();
    const {businessName,
        ownerName,
        businessNumber,
        businessEmail,
        gstNumber,
        businessAddress,
        businessPincode,
        businessWebsite,
        youtubeChannel,
        turnOverRange,
        businessLogo,
        city,
        mobileNumber,
        panNumber,
        email,
        name,
        picture,
        regstatus} = itemDetails
    const updateStatus = async (newregstatus) => {
        try{
            const options = {
                method : "PUT",
                headers : {
                    "Content-Type" : "application/json"
                },
                body : JSON.stringify({newregstatus,...{businessemail:businessEmail}})
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/updateregstatus`,options);
            const data = await response.json();
            console.log(data);
            window.location.reload();
        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`);
        }
    }
    const onLog = () => {
        navigate(`/businesses/${businessName}`);
    }


    return (
        <li style={{color:'white'}} className="businesslist-item">
                <div style={{marginRight:'80px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img className="business-img-logo" src={`data:image/png;base64,${businessLogo}`} alt="businesslogo"/>
                <div style={{marginLeft:'60px'}}>
                <h1 className="businesslist-itemheading">{businessName}</h1>
                <p className="joiningdate">Join on : 23/03/2024</p>
                </div>
                </div>
                <div>
                    <div className="blockitem flexi">
                    <FaUser className="business-icon"/>
                    <p>{ownerName}</p>
                    </div>
                    <div className="blockitem flexi">
                    <FaAddressCard className="business-icon"/>
                    <p>{city}</p>
                    </div>
                </div>
                <div>
                    <div className="blockitem flexi">
                    <MdEmail className="business-icon"/>
                <p>{businessEmail}</p>
                </div>
                <div className="blockitem flexi">
                    <FaPhone className="business-icon"/>
                <p>{businessNumber}</p>
                </div>
                </div>
                {regstatus==="rejected" && (<button style={{color:'lightred'}} className="addnew-button">Rejected</button>)}
                {regstatus==="approved" && (<button onClick={onLog} className="addnew-button">Login</button>)}
                {regstatus==="pending" && (
                          <Popup
                          trigger={<button className="addnew-button">Action</button>}
                          modal
                          nested
                        >
                          {close => (
                            <div style={{width:'300px',height:'250px',padding:'20px'}} className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg">
                              <div className="flex flex-col items-center mt-4">
                                <h1>Are you sure want to Approve or Reject?</h1>
                                <div style={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                                <button onClick={() => {updateStatus("approved")
                                    close()
                                }} className='addnew-button mr-3' style={{backgroundColor:'green',width:'60px',fontSize:'12px',color:'white'}}>Approve</button>
                                <button onClick={() => {
                                    updateStatus("rejected")
                                    close()
                                    }} className='addnew-button mr-3' style={{backgroundColor:'red',width:'60px',fontSize:'12px',color:'white'}}>Reject</button>
                                </div>
                                <button onClick={() => close()} className='addnew-button mt-5' style={{width:'60px',fontSize:'12px'}}>Close</button>
                              </div>
                            </div>
                          )}
                        </Popup>
                )}
            </li>
    )

}

export default BusinessItem