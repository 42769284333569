// src/components/BoxComponent.js

import React from 'react';
import './index.css'; // Ensure you have this CSS file for styling
import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';
import Popup from 'reactjs-popup';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import { useUser } from '../../Context/userContext';



const BoxComponent = ({ content }) => {
  const { image, heading, paragraph, order,button } = content;
  const navigate = useNavigate();
  const {loginUser} = useUser();

  const checkUser = async (email) => {
    try{
        const options = {
            method : "POST",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({email})
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/checkuser`,options);
        const data = await response.json();
        console.log(data);
        if(data.exist===true && data.regstatus==="approved"){
          loginUser(data.user);
            return true;
        }
        else if(data.exist===true && data.regstatus==="pending"){
            return "pending";
        }
        else if(data.exist===true && data.regstatus==="rejected"){
            return "rejected";
        }
        else
        return false;
    }
    catch(Err){
        console.log(`Error Occurred : ${Err}`);
    }
  }

  return (
    <div className="Box-container">
      <div className={`Box-content ${order}`}>
        <h1 className="Box-header">{heading}</h1>
        <p  className='Box-Paragraph'>{paragraph}</p>
        <div className="landing-buttons">
        <Popup
        trigger={<button className="start-button">{button}</button>}
        modal
        nested
      >
        {close => (
          <div 
          style={{ width: '300px' }} 
          className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg"
        >
          <h1 className="text-2xl font-bold mb-4">Sign In</h1>
          <div className="flex flex-col items-center mt-4">
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <GoogleLogin
                onSuccess={async (credentialResponse) => {
                  Cookies.set("userId",credentialResponse.credential)
                  const {email} = jwtDecode(credentialResponse.credential);
                  const res = await checkUser(email);
                  if(res===false)
                  navigate("/registrationform",{replace:true});
                  else if(res===true)
                  navigate("/performance",{replace:true});
                  else if(res==="pending")
                      navigate("/regpending",{replace:true});
                  else if(res==="rejected")
                      navigate("/regrejected", {replace:true});
                }
              }
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </GoogleOAuthProvider>
          </div>
          <div className="mt-6">
            <button
              className="bg-red-600 text-white p-2 rounded-md hover:bg-red-700 transition-colors duration-300"
              onClick={() => close()}
            >
              Close
            </button>
          </div>
        </div>
        )}
      </Popup>
      <Popup
        trigger={<button className="signin-button">Already have an Account ? Sign in</button>}
        modal
        nested
      >
        {close => (
          <div 
          style={{ width: '300px' }} 
          className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg"
        >
          <h1 className="text-2xl font-bold mb-4">Sign In</h1>
          <div className="flex flex-col items-center mt-4">
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
              <GoogleLogin
                onSuccess={async (credentialResponse) => {
                  Cookies.set("userId",credentialResponse.credential)
                  const {email} = jwtDecode(credentialResponse.credential);
                  const res = await checkUser(email);
                  if(res===false)
                  navigate("/registrationform",{replace:true});
                  else if(res===true)
                  navigate("/performance",{replace:true});
                  else if(res==="pending")
                      navigate("/regpending",{replace:true});
                  else if(res==="rejected")
                      navigate("/regrejected", {replace:true});
                }
              }
                onError={() => {
                  console.log("Login Failed");
                }}
              />
            </GoogleOAuthProvider>
          </div>
          <div className="mt-6">
            <button
              className="bg-red-600 text-white p-2 rounded-md hover:bg-red-700 transition-colors duration-300"
              onClick={() => close()}
            >
              Close
            </button>
          </div>
        </div>
        )}
      </Popup>
        </div>
      </div>
      <div className='Box-content'>
        <div className='image-box' >
         <img className='box-image' src={image.src} alt={image.alt} />
        </div>
      </div>
   
    </div>
  );
};

export default BoxComponent;