import "./index.css"
import { Link } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const BlogPage = () => {
    const navigate = useNavigate();
    return (
        <div className="blogs-main-container">
            <div>
            <button style={{display:'flex',alignItems:'center',marginBottom:'20px',marginLeft:'10px',marginTop:'10px'}} onClick={() => navigate("/",{replace:true})}>
                <MdArrowBackIos/>
                </button>
                <div className="blog-main-header">
                <img className="blog-main-image" src="https://res.cloudinary.com/haibegwvw/image/upload/v1721307308/file_krjkgp.png" alt="blog-image"/>
                <h1 className="blog-container-main-heading">Aitota Blogs</h1>
                </div>
                <ul className="blog-card-container">
                    <a className="blog-link" href="https://aitota.blogspot.com/2024/08/aitota.html" rel="noreferrer" target="_blank">
                    <li className="blog-card">
                            <img className="blog-card-image" src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEi-gtBodiF2xXfrQho52DJsCSc-woNzdOlfiI-Ykhgwd9M4jDmbQ33HRnO6729kPjJFD17-lGZOmEdEdtP2y_WxZwJA2K1OUUmpDM6fOnDaZG1Drs6iTCjnta9Dmv8j7wAZCj7Cyl6LUHhhqyXoMfv1MAorulS4aadUV59ExAXfC9nZC3r-i7tsT_3vFvs/w1200-h630-p-k-no-nu/aitotablog1.png" alt="blog-image"/>
                            <div className="blog-card-content">
                                <h1 className="blog-card-heading">Converation AI for Businesses</h1>
                                <p className="blog-card-paragraph">Aitota.com's Vision for India Revolutionizing Communication with Conversational AI  In today’s fast-paced world, where billions of mobile ...</p>
                            </div>
                    </li>
                    </a>
                    <a className="blog-link" href="https://aitota.blogspot.com/2024/08/revolutionizing-patient-care-how.html" target="_blank" rel="noreferrer">
                    <li className="blog-card">
                            <img className="blog-card-image" src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjya-pVZENh9t8hz9Mc6qEgqioGUSGDQIpOqYZZrwQPqW_9c7sp-2a2QtZKFGnfczPVljQHh651JmewDhdz6qfJWHTxgy1awEonRZl80x4HS82ZwXpVe8Y2LOuDbtb-MfBTncyAhRFlykoXLGvSrp2ORR37b0DS4g7yc6cH2X8n33F0ocnheB4WR0svw5M/w1200-h630-p-k-no-nu/aidoc2.png" alt="blog-image"/>
                            <div className="blog-card-content">
                                <h1 className="blog-card-heading">Converation AI for Businesses </h1>
                                <p className="blog-card-paragraph">Revolutionizing Patient Care:  How Aitota's AI Compounder is Transforming Healthcare In the ever-evolving world of healthcare, providing e...</p>
                            </div>
                    </li>
                    </a>
                    <a className="blog-link" href="https://aitotatech.blogspot.com/2024/08/aitota-redefining-customer.html" target="_blank" rel="noreferrer">
                    <li className="blog-card">
                            <img className="blog-card-image" src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEixkGvQHrZ8j2Gmk_6RUr3AfNJ4EpJcpX34NwRDaZX6Wy1pk9Q4XPxu1egjXuqk3wgPHiEDnGBnL5CNS8qBNVitZWCLiipOM_2tjJrwEVFHNL1xvEpWf4pwj9OQV_IZoaqCEGOE_PzpcqIi6WsG1Chjp4Au8enHQBDG-G31i8mhuOl_QCGG4OZ06vbfJiIy/w1200-h630-p-k-no-nu/cal2.png" alt="blog-image"/>
                            <div className="blog-card-content">
                                <h1 className="blog-card-heading">Aitota CX Technology </h1>
                                <p className="blog-card-paragraph">Aitota: Redefining Customer Conversations with Advanced Voice AI Date: August 24, 2024  Author: Ved Prakash   Read Time: 12 min At Aitota, w...</p>
                            </div>
                    </li>
                    </a>
                    <a className="blog-link" href="https://aitotatech.blogspot.com/2024/08/unleashing-power-of-aitotas-ai-voice.html" target="_blank" rel="noreferrer">
                    <li className="blog-card">
                            <img className="blog-card-image" src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgIGdsKmC49InYpKRj5Lw8NLogC1ifmrGa9VH3-xARcSXQv8uRg5MDYKrn1XtaW0jpkLF56L_j6x8J4MCWQeY4RxWSmiWLq40xHKfa3tbTKvbErxiPpqNb6MZj2OsPJbm4fYM37LLkmoA_cc_lG4YQrBp91wC4ybgZIRR2kAMLZCBhL1-65tYjgSyR3V92e/w1200-h630-p-k-no-nu/b1.png" alt="blog-image"/>
                            <div className="blog-card-content">
                                <h1 className="blog-card-heading">Aitota CX Technology </h1>
                                <p className="blog-card-paragraph">Unleashing the Power of Aitota’s AI Voice Bot:  Revolutionizing Conversations with Conversational AI In a world where instant, personalize...</p>
                            </div>
                    </li>
                    </a>
                    <a className="blog-link" href="https://aitotaconversationalai.blogspot.com/2024/08/conversational-ai-future-of-indian.html" target="_blank" rel="noreferrer">
                    <li className="blog-card">
                            <img className="blog-card-image" src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh46V_VAvAGUOIa-TEptJG0CB_fUZUxhZ94QMB2F-Z00kyMfyuYCaDZRdFsDDtzG3yhyphenhyphenU29w8E0ZynGXp85h9PuWsih8Y4uCNaUeoVapURzeaUhXM_5ElsvyQumQZAIcG63FSNy0_m2IbDiY_n5iDyMSX6sPWV0v0vZpR4uy_HWnbbufqdRZB3TPRzItpQ/w1200-h630-p-k-no-nu/fffbff7f-1f05-4287-82c8-5f177eb6f275.png" alt="blog-image"/>
                            <div className="blog-card-content">
                                <h1 className="blog-card-heading">Why India Need Conversational AI ?</h1>
                                <p className="blog-card-paragraph">Rising Conversational AI Transforming the Future of Indian Businesses In 2024, the pace of business is faster than ever, and customer exp...</p>
                            </div>
                    </li>
                    </a>
                </ul>
            </div>
        </div>
    )
}

export default BlogPage;