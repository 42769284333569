"use client";
import React from "react";
import MicIcon from "../MicIcon";
import { SparklesCore } from "../ui/sparkles";
import Popup from "reactjs-popup";
import { useNavigate } from "react-router-dom";



export function SparklesPreview() {
  const navigate = useNavigate();
  return (
    <div style={{color:'white',textAlign:'center'}}  className="h-[40rem] w-100 bg-black flex flex-col items-center overflow-hidden rounded-md">
      <h1 style={{marginTop:'40px',color:'white'}} className="md:text-6xl text-4xl lg:text-5xl font-bold text-center relative z-20">
      Conversational AI for Businesses
      </h1>
      <p style={{fontSize:'20px'}} className="text-white w-50 mt-3 text-center">
      Aitota is revolutionizing communication with cutting-edge conversational AI, connecting people and businesses with AI Voice.
      </p>
      <MicIcon style={{margin:'0',width:'100%'}}/>
      {/* <div className="mt-3 w-[40rem] h-40 relative">
        <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
        <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
        <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm" />
        <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4" />
        <SparklesCore
          background="transparent"
          minSize={0.4}
          maxSize={1}
          particleDensity={1200}
          className="w-full h-full"
          particleColor="#FFFFFF"
        /> */}
        {/* <div className="absolute inset-0 w-full h-full bg-black [mask-image:radial-gradient(350px_200px_at_top,transparent_20%,white)]"></div> */}
      {/* </div> */}
    </div>
  );
}
