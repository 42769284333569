import "./index.css"
import AdminHeader from "../../AdminHeader";
import AdminSidebar from "../../AdminSidebar";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { useState , useEffect, act } from "react";
import Popup from "reactjs-popup";
import { FaPhone } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { IoEar, IoMail } from "react-icons/io5";
import IconSidebar from "../../IconSidebar";

const AdminSection = () => {
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(false);
    const [businessesList,setBusinessList] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredList, setFilteredList] = useState(businessesList);
    const [activeTab, setActiveTab] = useState(0);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        number: "",
        accessItems: [], // Array to store the selected access items
      });
    const [subadminList,setSubAdminList] = useState([]);

    const onLogOut = () => {
      googleLogout();
      Cookies.remove("userId");
      navigate("/");
  }

  useEffect(() => {
    const getVideos = async () => {
      setIsLoading(true);
      try{
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getsubadmins`);
        const data = await response.json();
        console.log(data);
        setSubAdminList(data);
      }
      catch(Err){
        console.log(`Error Occurred : ${Err}`);
      }
    };

    getVideos();
  }, []);

  const postData = async (obj) => {
    try{
        const options = {
            method : "POST",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify(obj)
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/addsubadmin`,options);
        const data = await response.json();
        if(data.acknowledged===false)
            alert("There is already a subadmin with the provided email");
        return true;
    }
    catch(Err){

    }
  }

  const deleteSubAdmin = async (email) => {
    try{
        const options = {
            method : "delete",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({email})
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/deletesubadmin`,options);
        const data = await response.json();
        console.log(data);
    }
    catch(Err){
        console.log(`Error Occurred : ${Err}`);
    }
  }

  



  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    const res = await postData(formData)
    if(res)
    setSubAdminList([formData,...subadminList]);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };
  

  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    const newAccessItems = formData.accessItems.includes(value)
      ? formData.accessItems.filter((item) => item !== value)
      : [...formData.accessItems, value];
  
    setFormData({
      ...formData,
      accessItems: newAccessItems,
    });
  };

  const onChangeSearchInput = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchInput(searchValue);
  
    if (searchValue.length === 0) {
      setFilteredList(businessesList);
    } else {
      const newList = businessesList.filter((ele) => 
        ele.businessName.toLowerCase().includes(searchValue)
      );
      setFilteredList(newList);
    }
  
    console.log(searchValue);
  }
  


  const deleteBusiness = async (email) => {
    try{
        const options = {
            method : "DELETE",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({businessemail:email})
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/deletebusiness`,options);
        const data = await response.json();
        console.log(data);
        window.location.reload();
    }
    catch(Err){
        console.log(`Error Occurred : ${Err}`);
    }
}

    useEffect(() => {
      const getVideos = async () => {
        setIsLoading(true);
        try{
          const response = await fetch(`${process.env.REACT_APP_API_URL}/businesses`);
          const data = await response.json();
          console.log(data);
          setBusinessList(data);
          setFilteredList(data);
        }
        catch(Err){
          console.log(`Error Occurred : ${Err}`);
        }
      };
  
      getVideos();
    }, []);

    return (
        <div style={{width:'100%'}} className="app">
       {/* <AdminHeader /> */}
      <div className="main-body-container">
        <IconSidebar />
        <div className="business-container">
            <div className="flexi mb-5" >
                <button onClick={() => setActiveTab(0)} style={{width:'50%'}} className={`${activeTab===0?'activeTabSection':''} addnew-button`}>Add Sub Admins</button>
                <button onClick={() => setActiveTab(1)} style={{width:'50%'}} className={`${activeTab===1?'activeTabSection':''} addnew-button`}> Remove Businesses</button>
            </div>
        {activeTab===1 && (
            <>
          <div className="flexi mb-5" style={{justifyContent:'space-between'}}>
          <h1 style={{fontWeight:'bold',color:'white',fontSize:'25px'}}>Remove Businesses</h1>
          </div>
          <div className="mb-2 filter-item-container">
                <label htmlFor="search">Search</label>
                <br/>
                <input onChange={onChangeSearchInput} id="search" className="filter-input" placeholder="Search for business" />
            </div>
        <div className="businesses-list-outer-container">
        <ul className="businesseslist-container">
            {filteredList.map((ele,index) => (
               <li key={index} style={{color:'white'}} className="businesslist-item">
              <div style={{marginRight:'80px',display:'flex',justifyContent:'center',alignItems:'center'}}>
              <img className="business-img-logo" src={`data:image/png;base64,${ele.businessLogo}`} alt="businesslogo"/>
              <div style={{marginLeft:'60px'}}>
              <h1 className="businesslist-itemheading">{ele.businessName}</h1>
              <p className="joiningdate">Join on : 23/03/2024</p>
              </div>
              </div>
              <div>
                  <div className="blockitem flexi">
                  <FaUser className="business-icon"/>
                  <p>{ele.ownerName}</p>
                  </div>
                  <div className="blockitem flexi">
                  <FaAddressCard className="business-icon"/>
                  <p>{ele.city}</p>
                  </div>
              </div>
              <div>
              <div className="blockitem flexi">
                  <IoMail className="business-icon"/>
              <p>{ele.businessEmail}</p>
              </div>
              <div className="blockitem flexi">
                  <FaPhone className="business-icon"/>
              <p>{ele.businessNumber}</p>
              </div>
              </div>
                        <Popup
                        trigger={<button className="addnew-button">Delete</button>}
                        modal
                        nested
                      >
                        {close => (
                          <div style={{width:'300px',height:'250px',padding:'20px'}} className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg">
                            <div className="flex flex-col items-center mt-4">
                              <h1 style={{fontSize:'20px'}}>Are you sure want to Delete Business?</h1>
                              <div style={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                              <button onClick={() => close()} className='addnew-button mr-5' style={{width:'60px',fontSize:'12px'}}>Close</button>
                              <button onClick={() => {deleteBusiness(ele.businessEmail)
                                  close()
                              }} className='addnew-button mr-3' style={{backgroundColor:'red',width:'60px',fontSize:'12px',color:'white'}}>Delete</button>
                              </div>
                            </div>
                          </div>
                        )}
                      </Popup>
          </li>
            ))}
        </ul>
      </div>
      </>
      )}
      {activeTab===0 && (
            <>
            <div style={{display:'flex',marginBottom:'10px',marginTop:'10px',justifyContent:'space-between',alignItems:'center'}}>
            <h1 style={{fontWeight:'bold',color:'white',fontSize:'25px'}}>Add Sub Admin</h1>

            <Popup
        trigger={<button className="addnew-button">Add New +</button>}
        modal
        nested
      >
        {close => (
            <div style={{ height: '600px', width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
  <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
    <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
      <h2 className="text-2xl font-bold mb-6">Add Sub Admin</h2>
      <form onSubmit={(e) => {
        handleSubmit(e);
        close();
      }} className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="relative">
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Name"
            required
          />
          <label
            htmlFor="name"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Name
          </label>
        </div>
        <div className="relative">
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Email"
            required
          />
          <label
            htmlFor="email"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Email
          </label>
        </div>
        <div className="relative">
          <input
            type="tel"
            id="number"
            value={formData.number}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Phone Number"
            required
          />
          <label
            htmlFor="number"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Phone Number
          </label>
        </div>
        <div className="relative col-span-2">
          <label className="block text-gray-500 mb-2">Access Items</label>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {['Performance', 'Business', 'System', 'Tickets', 'Feedback', 'Collections', 'Admin'].map((item, index) => (
              <div key={index} className="flex items-center">
                <input
                  type="checkbox"
                  id={item.toLowerCase()}
                  value={item}
                  checked={formData.accessItems.includes(item)}
                  onChange={handleCheckboxChange}
                  className="form-checkbox h-5 w-5 text-blue-600 bg-gray-900 border border-gray-700 rounded focus:outline-none focus:border-blue-500 transition-all duration-300"
                />
                <label htmlFor={item.toLowerCase()} className="ml-2 text-gray-500">{item}</label>
              </div>
            ))}
          </div>
        </div>
        <button
          type="submit"
          className="col-span-2 bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
        >
          Add
        </button>
      </form>
    </div>
  </div>
</div>

        )}
      </Popup>
        </div>
        <div className="businesses-outer-container2">
  <ul className="businesseslist-container">
    <li className="businesslist-item" style={{color:'white',fontSize:'20px',fontWeight:'bold',position:'sticky'}}>
        <h1 style={{fontSize:'20px'}}>Name</h1>
        <h1 style={{fontSize:'20px'}}>Email</h1>
        <h1 style={{fontSize:'20px'}}>Mobile No</h1>
        <h1 style={{fontSize:'20px'}}>Access Items</h1>
        <h1 style={{fontSize:'20px'}}>Action</h1>
    </li>
    {subadminList.map((ele, index) => (
      <li key={index} className="businesslist-item" style={{ color: 'white' }}>
          <p>{ele.name}</p>
          <p>{ele.email}</p>
          <p>{ele.number}</p>
          <div>
            {ele.accessItems.map((item, itemIndex) => (
              <span key={itemIndex} style={{ display: 'block' }}>{item}</span>
            ))}
          </div>
        <Popup
          trigger={<button className="addnew-button">Delete</button>}
          modal
          nested
        >
          {close => (
            <div
              style={{
                width: '300px',
                height: '250px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#2d3748',
                color: 'white',
                borderRadius: '10px',
                textAlign: 'center',
              }}
            >
              <h1 style={{fontSize:'20px'}}>Are you sure you want to delete Subadmin?</h1>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                <button
                  onClick={() => {
                    deleteSubAdmin(ele.email)
                    close()
                }}
                  className="addnew-button"
                  style={{ width: '60px', fontSize: '12px', marginRight: '5px' }}
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    deleteBusiness(ele.email);
                    close();
                  }}
                  className="addnew-button"
                  style={{
                    backgroundColor: 'red',
                    width: '60px',
                    fontSize: '12px',
                    color: 'white',
                    marginLeft: '5px',
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </Popup>
      </li>
    ))}
  </ul>
</div>

      </>
      )}
        </div>
      </div>
      </div>
    )
}

export default AdminSection;