import "./index.css"
import AdminHeader from "../../AdminHeader";
import AdminSidebar from "../../AdminSidebar";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { useState , useEffect } from "react";
import Popup from "reactjs-popup";
import { FaPhone } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { IoEar, IoMail } from "react-icons/io5";
import IconSidebar from "../../IconSidebar";

const AdminSettings = () => {
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(false);
    const [businessesList,setBusinessList] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredList, setFilteredList] = useState(businessesList);
    const onLogOut = () => {
      googleLogout();
      Cookies.remove("userId");
      navigate("/");
  }

  const onChangeSearchInput = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchInput(searchValue);
  
    if (searchValue.length === 0) {
      setFilteredList(businessesList);
    } else {
      const newList = businessesList.filter((ele) => 
        ele.businessName.toLowerCase().includes(searchValue)
      );
      setFilteredList(newList);
    }
  
    console.log(searchValue);
  }
  


  const deleteBusiness = async (email) => {
    try{
        const options = {
            method : "DELETE",
            headers : {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({businessemail:email})
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/deletebusiness`,options);
        const data = await response.json();
        console.log(data);
        window.location.reload();
    }
    catch(Err){
        console.log(`Error Occurred : ${Err}`);
    }
}

    useEffect(() => {
      const getVideos = async () => {
        setIsLoading(true);
        try{
          const response = await fetch(`${process.env.REACT_APP_API_URL}/businesses`);
          const data = await response.json();
          console.log(data);
          setBusinessList(data);
          setFilteredList(data);
        }
        catch(Err){
          console.log(`Error Occurred : ${Err}`);
        }
      };
  
      getVideos();
    }, []);

    return (
        <div style={{width:'100%'}} className="app">
      <div className="main-body-container">
        <IconSidebar />
        <div className="business-container">
          <div className="flexi mb-5" style={{justifyContent:'space-between'}}>
          <button onClick={onLogOut} className="logout-button">Log Out</button>
          </div>
          </div>
          </div>
      </div>
    )
}

export default AdminSettings;