import { MdAccountBox } from "react-icons/md";
import React from 'react';
import './index.css';
import Popup from "reactjs-popup";
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { FaEye } from "react-icons/fa";
import { useState } from "react";
import { IoEyeOff } from "react-icons/io5";
import { FaCopy } from "react-icons/fa";

function PhoneNumber() {
  const navigate = useNavigate();

  const [visible,setVisible] = useState(false);
  const onLogOut = () => {
    googleLogout();
    Cookies.remove("userId");
    navigate("/");
  }

  return (
    <div className="account-container">
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <div className="account-container-section">
        <MdAccountBox className="profile-iimage"/>
        <h1 className="profile-heading">Account</h1>
      </div>
      <Popup
        trigger={<button className="logout-button">Log Out</button>}
        modal
        nested
      >
        {close => (
          <div style={{width:'300px',height:'150px',padding:'20px'}} className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg">
            <div className="flex flex-col items-center mt-4">
              <h1>Are you sure want to Log Out?</h1>
              <div style={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
              <button onClick={() => close()} className='addnew-button mr-3' style={{width:'60px',fontSize:'12px'}}>Close</button>
              <button onClick={onLogOut} className='addnew-button mr-3' style={{backgroundColor:'red',width:'60px',fontSize:'12px'}}>Log Out</button>
              </div>
            </div>
          </div>
        )}
      </Popup>
      </div>
      <div style={{width:'600px'}} className="mt-3 password-chnage-container" >
        <div className="setting-container">
            <h1 style={{color:'white'}} className="setting-ele">Your Credentials</h1>
        </div>
        <div className="password-reset-containaer">
            <p style={{color:'white'}} className="side">Business ID</p>
            <div style={{height:'40px'}} className="mb-3 mt-2 email flex justify-between items-center">
            <p style={{color:'white'}}>8ade8sdf8gsjfihessgf</p>
            <p onClick={() => navigator.clipboard.writeText("8ade8sdf8gsjfihessgf").then(function() {
                alert('Text copied to clipboard!');
            }).catch(function(error) {
                alert('Failed to copy text: ', error);
            })} style={{color:'white',marginRight:'5px'}}><FaCopy/></p>
            </div>
            <p style={{color:'white'}} className="side">Secret Key</p>
            <div style={{height:'40px'}} className="email flex justify-between">
            {visible===true && (<p style={{color:'white',width:'90%'}}>8ade8sdf8gsjfihessgf</p>)}
            {visible===false && (<p style={{color:'white',width:'90%'}} >{'******************'}</p>)}
            <div style={{width:'10%',display:'flex',marginRight:'10px'}}>
            {visible===false && <p onClick={() => setVisible(true)} style={{color:'white'}}><FaEye/></p>}
              {visible===true && <p onClick={() => setVisible(false)} style={{color:'white'}}><IoEyeOff/></p>}
              <p onClick={() => navigator.clipboard.writeText("8ade8sdf8gsjfihessgf").then(function() {
                alert('Text copied to clipboard!');
            }).catch(function(error) {
                alert('Failed to copy text: ', error);
            })} style={{color:'white',marginLeft:'8px'}}><FaCopy/></p>
            </div>
              </div>
              <div className="mb-3">
              <Popup
        trigger={<button style={{marginTop:'10px',borderRadius:'8px',backgroundColor:'red',color:'white',padding:'5px'}} type="button">Delete Secret Key</button>}
        modal
        nested
      >
        {close => (
          <div style={{width:'300px',height:'150px',padding:'20px'}} className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg">
            <div className="flex flex-col items-center mt-4">
              <h1 style={{fontWeight:'bold',fontSize:'20px'}}>Are you sure want to delete secret key?</h1>
              <p>Apps with this key will lose API access</p>
              <div style={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
              <button onClick={() => close()} className='addnew-button mr-3' style={{width:'60px',fontSize:'12px'}}>Cancel</button>
              <button className='addnew-button mr-3' style={{backgroundColor:'red',width:'60px',fontSize:'12px'}}>Delete</button>
              </div>
            </div>
          </div>
        )}
      </Popup>
      </div>
                <button style={{width:'100%',marinTop:'20px',color:'white'}} type="button" className="addnew-button">API Documentation</button>
        </div>
      </div>
    </div>
  );
}

export default PhoneNumber;