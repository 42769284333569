import React, { useState } from 'react';
import {v4 as uuidv4} from 'uuid'
import CreateAssistantModal from '../CreateAssistantModal'; // Assuming this is your modal component
import './index.css'; // Example CSS for Assistants component
import StatusDashboard from '../StatusDashboard';
import { FaEllipsisV } from 'react-icons/fa';
import { MdDelete } from "react-icons/md";
import {Popup} from 'reactjs-popup';

function Assistants() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);


  const [activeAssistants, setActiveAssistant] = useState([])

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onClickCreateAssistantButton = (activeAssistant,assistantName) => {
    setIsModalOpen(false);
    let assistantDetails = {
      id : uuidv4(),
      name : assistantName,
      activeAssistant,
      isActive: false,
    }
    setActiveAssistant(prev => [...prev,assistantDetails])
  }

  return (
    <div className="assistants-container">
      <div className="create-assistant-container">
        <div className='createbutton-container'>
          <button className="button-assistant hover" onClick={openModal}>
            Create Assistant +
          </button>
        </div>
        {activeAssistants.map(each => (
          <div className='newAssistant-container'>
            <div style={{display:'flex',justifyContent:'space-between'}}>
              <div>
            <h1 className='assistant-name'>{each.name}</h1>
            <p className='copy-link'>248be419-c632-4f23-adf1-5324ed7dbf1d</p>
            </div>
            <Popup
        trigger={<button style={{marginLeft:'30px'}} className=" text-gray-600 hover:text-gray-900 focus:outline-none"><MdDelete size={20}  /></button>}
        modal
        nested
      >
        {close => (
          <div style={{width:'300px',height:'150px',padding:'20px'}} className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg">
            <div className="flex flex-col items-center mt-4">
              <h1>Are you sure want to delete Assistant?</h1>
              <div style={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
              <button onClick={() => close()} className='addnew-button mr-3' style={{width:'60px',fontSize:'12px'}}>Close</button>
              <button className='addnew-button mr-3' style={{backgroundColor:'red',width:'60px',fontSize:'12px'}}>Delete</button>
              </div>
            </div>
          </div>
        )}
      </Popup>
            </div>
        </div>
        ))}
      </div>
      <div className='side-container'>
        {activeAssistants.length === 0 ? (
          <div className="no-assistants">
            <h2>No Assistants Found</h2>
            <p>Create a new assistant to get started with your first conversation.</p>
          </div>) :( 
            <StatusDashboard />
        ) }
      </div>
      
     
      <CreateAssistantModal onClickCreateAssistantButton={onClickCreateAssistantButton} isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
}

export default Assistants;