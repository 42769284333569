import "./about.css"
import Logo2 from '../../assets/Vijay Kumar Circular.png'
import { MdArrowBackIos } from "react-icons/md"
import { useNavigate } from "react-router-dom"

const About = () => {
    const navigate = useNavigate();

    return (
        
        <div className="about-container">
                <button style={{display:'flex',alignItems:'center',marginBottom:'20px',marginLeft:'10px',marginTop:'10px'}} onClick={() => navigate("/",{replace:true})}>
                <MdArrowBackIos/>
                </button>
            <div style={{marginBottom:'10px'}} className="about-founder-image">
                <img className="about-founder-image" src={Logo2} alt="Vijay Kumar Singh"/>
            </div>

            <header className="about-header">
                <h1 className="about-heading1">Vijay Kumar Singh</h1>
                <p className="about-paragraph">Founder - Aitota.com</p>
                <p className="about-paragraph">Mobishaala.com, Ex-Intel</p>
            </header>

            <section className="about-bio">
                <h2 className="about-heading2">About the Founder</h2>
                <p className="about-paragraph">
                    Vijay Kumar Singh’s journey is a testament to the power of innovation and impact. 
                    An electronic engineer with over 8 years of chip design experience at Intel, 
                    Vijay's career began with a deep dive into the technical intricacies of technology. 
                    His passion for making a difference soon led him to new realms of possibility.
                </p>
                <p className="about-paragraph">
                    He played a pivotal role in developing Swachh Map for Swachh Bharat, 
                    dedicating 5 years to driving national social initiatives that aimed to improve 
                    public sanitation and awareness. This experience underscored his belief in 
                    technology's ability to drive social change.
                </p>
                <p className="about-paragraph">
                    Building on this foundation, Vijay embarked on a transformative journey with <a className="about-link" rel="noreferrer" href="https://www.mobishaala.com" target="_blank">Mobishaala.com</a>, a platform he has been running for the past 6 years. 
                    Here, he empowered hundreds of teachers and lakhs of students, seamlessly 
                    bridging the gap between traditional and online education. His innovative approach 
                    also extends to education with Aishaala, an AI assistant designed to enhance 
                    learning for teachers and students.
                </p>
            </section>

            <section className="about-aitota">
                <p className="about-paragraph">
                    Aitota is revolutionizing communication with cutting-edge conversational AI, 
                    connecting people and businesses with AI Voice. Our mission is to use conversational 
                    AI to transform communication across multiple domains, including health, contact centers, 
                    and business sectors. At Aitota, we are dedicated to leveraging technology to enhance 
                    interactions and drive meaningful change.
                </p>
                <p className="about-paragraph">
                    Vijay’s approach to innovation is embodied in his philosophy: 
                    <br/>
                    <br/>
                    <em>"Seeding Ideas, Watering Vision, Planting Prototype, Nurturing Products for People, 
                    and Harvesting Change."</em>
                    <br/>
                    <br/>
                     This guiding principle drives Aitota’s commitment to creating 
                    technology solutions that make a significant difference.
                </p>
            </section>

            <footer className="about-footer">
                <p className="about-footer-paragraph">For more details on Vijay's journey, visit his <a className="about-link" rel="noreferrer" href="https://www.linkedin.com/in/vijaywiz/" target="_blank">LinkedIn profile</a>.
                </p>
                <p style={{textAlign:'center'}}>contact@aitota.com</p>
            </footer>
        </div>
    )
}

export default About;
