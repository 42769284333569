import React from 'react';
import { RiLandscapeLine } from "react-icons/ri";


import './index.css';

const ActionTab = () => {
  return (
        <div className="model-section">
            <div className="section-body">
                <div className="section">
        <h2 className="section-know-heading">Actions</h2>
        <p className="section-description">
          Select actions available for your assistant.
        </p>
      </div>
      <div className="space-y-4">
        <label className="flex items-center bg-gray-800 p-4 rounded-lg cursor-pointer">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-green-500"
          />
          <div>
          <p className="ml-3 text-green-500 font-medium">
            Provide Coupon Codes
          </p>
          <p className="ml-3 text-gray-400 text-sm">
          Share current coupon codes or promotional offers with the caller.
          </p>
          </div>
        </label>

        <label className="flex items-center bg-gray-800 p-4 rounded-lg cursor-pointer">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-green-500"
          />
          <div>
          <p className="ml-3 text-green-500 font-medium">
                Check CIBIL Score
          </p>
          <p className="ml-3 text-gray-400 text-sm">
          Retrieve and provide information about the caller’s CIBIL credit score.
          </p>
          </div>
        </label>

        <label className="flex items-center bg-gray-800 p-4 rounded-lg cursor-pointer">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-green-500"
          />
          <div>
          <p className="ml-3 text-green-500 font-medium">
          Verify Personal Details
          </p>
          <p className="ml-3 text-gray-400 text-sm">
          Confirm and validate personal information provided by the caller.
          </p>
          </div>
        </label>

        <label className="flex items-center bg-gray-800 p-4 rounded-lg cursor-pointer">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-green-500"
          />
          <div>
          <p className="ml-3 text-green-500 font-medium">
          KYC Verification
          </p>
          <p className="ml-3 text-gray-400 text-sm">
          Authenticate the caller’s identity using official documents like Aadhar numbers and ensure compliance with regulatory requirements.
          </p>
          </div>
        </label>

        <label className="flex items-center bg-gray-800 p-4 rounded-lg cursor-pointer">
          <input
            type="checkbox"
            className="form-checkbox h-5 w-5 text-green-500"
          />
          <div>
          <p className="ml-3 text-green-500 font-medium">
          Reschedule Meetings
          </p>
          <p className="ml-3 text-gray-400 text-sm">
          Change the date or time of previously scheduled meetings based on the caller’s request.
          </p>
          </div>
        </label>
        

      </div>
                
            </div>
        </div>
  );
};

export default ActionTab;



