import "./index.css"
import IconClientSidebar from "../../../IconClientSidebar";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import { useState } from "react";
import { Link } from "react-router-dom";
import React from "react";

const Knowledge = () => {
    const [activeType, setActiveType] = useState('')
    const [fileInput, setFileInput] = useState('');
    const [dataList, setDataList] = useState([]);
    const [webpageUrl, setWebPageUrl] = useState('');
    const [youtubeUrl, setYoutubeVideoUrl] = useState('');
    const [text, setText] = useState('')
    const [finalData, setFinalData] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [showedtype, setShowedType] = useState('ALL');
    const [filteredList, setFilteredList] = useState([]);

      
    const handleFileChange = (e) => {
        const files = e.target.files[0];
        setFileInput(files)
    }

    const handleSubmit = (e) => {
      e.preventDefault();
      let newFinalData = null; // Initialize newFinalData variable
      
      if (activeType === "File" && fileInput) {
          newFinalData = {
              date: `${(new Date()).toLocaleDateString('en-GB')}\n${(new Date()).toLocaleTimeString('en-GB')}`,
              source: fileInput,
              type: "File"
          };
      } else if (activeType === "Webpage" && webpageUrl) {
          newFinalData = {
              date: `${(new Date()).toLocaleDateString('en-GB')}\n${(new Date()).toLocaleTimeString('en-GB')}`,
              source: webpageUrl,
              type: "Webpage"
          };
      } else if (activeType === "Youtube Video Link" && youtubeUrl) {
          newFinalData = {
              date: `${(new Date()).toLocaleDateString('en-GB')}\n${(new Date()).toLocaleTimeString('en-GB')}`,
              source: youtubeUrl,
              type: "Youtube Video Link"
          };
      } else if (activeType === "Text" && text) {
          newFinalData = {
              date: `${(new Date()).toLocaleDateString('en-GB')}\n${(new Date()).toLocaleTimeString('en-GB')}`,
              source: text,
              type: "Text"
          };
      }
  
      // Check if newFinalData is valid before setting it
      if (newFinalData) {
        const newList = [newFinalData, ...dataList];
          setDataList(newList);
          setFilteredList(newList);
          console.log(newFinalData); // Log the final data
      }
  
      // Reset states
      setActiveType('');
      setFileInput('');
      setText('');
      setWebPageUrl('');
      setYoutubeVideoUrl('');
  }

  const onChangeSearchInput = (e) => {
    const searchinput = e.target.value
    const newList = dataList.filter((ele) => ele.source.toLowerCase().includes(searchinput.toLowerCase()));
    setFilteredList(newList)
  }

  const onChangeShowedType = (e) => {
    const showedtype = e.target.value
    console.log(showedtype)
    if(showedtype==="ALL")
      setFilteredList(dataList);
    else
    setFilteredList(dataList.filter((ele) => ele.type===showedtype))
  setShowedType(e.target.value)
  }
  
    

    


    return (
         <div className="business-container">
            <div className="business-container-header">
                <h1 className="bc-main-heading">Knowledge</h1>
                <select value={activeType} className="addnew-button custom-dropdown" onChange={(e) => setActiveType(e.target.value)}>
                    <option value="">New Record</option>
                    <option value="File">File</option>
                    <option value="Webpage">Web Page</option>
                    <option value="Youtube Video Link">YT Video Link</option>
                    <option value="Text">Text</option> 
                </select>
                {activeType !== "" && (
  <div style={{
    position: 'fixed', // Changed to fixed to keep the popup on the screen
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Center the popup
    zIndex: '9999',
    backgroundColor: 'black',
    padding: '20px', // Added padding for better spacing
    borderRadius: '8px', // Added border-radius for rounded corners
    width: '700px', // Set a fixed width for the popup
    height:`${activeType==="Text"?'500px':'300px'}`,
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)' // Added shadow for better visibility
  }}>
    <h2 className="text-2xl font-bold mb-6 text-white">Add {activeType}</h2>
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="mt-3 mb-3">
        {activeType === "File" && (
          <>
            <label htmlFor="file" className="text-white">Insert File</label>
            <br />
            <input className="mt-3 input-model" type="file" id="file" />
          </>
        )}
        {activeType === "Webpage" && (
          <>
            <label htmlFor="webpage" className="text-white">Add Webpage URL</label>
            <input onChange={(e) => setWebPageUrl(e.target.value)} className="mt-3 input-model" placeholder="Enter the Web page URL" type="text" id="webpage" />
            <br />
          </>
        )}
        {activeType === "Youtube Video Link" && (
          <>
            <label htmlFor="ytvideolink" className="text-white">Video Link</label>
            <br />
            <input onChange={(e) => setYoutubeVideoUrl(e.target.value)} className="mt-3 input-model" placeholder="Enter the Youtube video link" type="text" id="ytvideolink" />
          </>
        )}
        {activeType === "Text" && (
          <>
            <label htmlFor="text" className="text-white">Text</label>
            <br />
            <textarea style={{height:'250px'}} onChange={(e) => setText(e.target.value)} className="mt-3 input-model" type="text" id="text" placeholder="Enter the Text"> </textarea>
          </>
        )}
      </div>

      {/* Submit Button */}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <button
        onClick={() => setActiveType('')}
          type="button"
          className=" bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
        >
          Cancel
        </button>
        <button
          type="submit"
          className=" bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
        >
          Add
        </button>
      </div>
    </form>
  </div>
)}

    </div>
            <div>
              <div className="flex justify-between items-center mt-3 mb-3">
              <div className="flex items-center ">
                <input style={{width:'250px',marginRight:'15px'}} className="input-model" type="text" placeholder="Search by Source" onChange={onChangeSearchInput}/>
                <select style={{width:'250px'}} value={showedtype} className="addnew-button custom-dropdown" onChange={onChangeShowedType}>
                    <option value="ALL">Select by Type</option>
                    <option value="File">Files</option>
                    <option value="Webpage">Web Page</option>
                    <option value="Youtube Video Link">YT Video Link</option>
                    <option value="Text">Text</option> 
                </select>
              </div>
              <button
          type="button"
          className=" bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300"
        >
          Build
        </button>
              </div>
              
            <div className="table-outer-container bg-[#444444] mt-5 p-4">
  <table className="min-w-full bg-[#15161A] text-white rounded-lg shadow-lg">
    <thead>
      <tr style={{color:'white',fontSize:'16px'}}>
        <th className="w-1/12 py-3 px-4 text-left border-b border-gray-700">S.No</th>
        <th className="w-1/12 py-3 px-4 text-left border-b border-gray-700">Date</th>
        <th className="w-4/12 py-3 px-4 text-left border-b border-gray-700">Source</th>
        <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Type</th>
        <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Action</th>
      </tr>
    </thead>
    <tbody>
        {filteredList.map((ele,index) => (
                  <tr key={index+1} className="hover:bg-gray-700">
                  <td className="py-3 px-4 border-b border-gray-700">{index+1}</td>
                  <td className="py-3 px-4 border-b border-gray-700">
                    {ele.date}            
                  </td>
                   <td className="py-3 px-4 border-b border-gray-700">{(ele.source).slice(0,30)}</td>
                  <td className="py-3 px-4 border-b border-gray-700">{ele.type}</td>
                  <td className="py-3 px-4 border-b border-gray-700">
                      <button className="bg-transparent mr-3" type="button"><FaEdit size={20}/></button>
                      <Popup
          trigger={<button className="bg-transparent mr-3" type="button"><MdDelete size={20}/></button>}
          modal
          nested
        >
          {close => (
            <div
              style={{
                width: '300px',
                height: '250px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#2d3748',
                color: 'white',
                borderRadius: '10px',
                textAlign: 'center',
              }}
            >
              <h1 style={{fontSize:'20px'}}>Are you sure you want to delete data?</h1>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                <button
                  onClick={() => {
                    close()
                }}
                  className="addnew-button"
                  style={{ width: '60px', fontSize: '12px', marginRight: '5px' }}
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    close();
                  }}
                  className="addnew-button"
                  style={{
                    backgroundColor: 'red',
                    width: '60px',
                    fontSize: '12px',
                    color: 'white',
                    marginLeft: '5px',
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </Popup>

                  </td>
                </tr>
        ))}

    </tbody>
  </table>
</div>


            </div>

       </div>
    )
}

export default Knowledge;