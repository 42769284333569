import "./index.css"
import IconClientSidebar from "../../../IconClientSidebar";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import { useRef } from "react";
import {v4 as uuidv4} from 'uuid';
import { useEffect } from "react";

const Users = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name:'',
        number:'',
        details:''
    });
    const params = useParams();
    const {groupName} = params;
    const [usersList, setUsersList] = useState([]);
    const fileInputRef = useRef(null); // Reference to the file input
    const [message, setMessage] = useState('');
    const [messageStyle, setMessageStyle] = useState('');
    let user = localStorage.getItem("user");
    if(user)
      user = JSON.parse(user);
 
  // Required columns
  const requiredColumns = ['Name', 'Number', 'Details'];

  useEffect(() => {
    const getItems = async () => {
      try {
        const options = {
          method : "POST",
          headers : {
            "Content-Type" : "application/json"
          },
          body:JSON.stringify({email:user.email,groupname:groupName,businessname:user.businessName})
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/getusersfromgroup`,options);
        const data = await response.json();
        if(data.UsersArray)
        setUsersList(data.UsersArray)
      } catch (err) {
        console.error(`Error Occurred: ${err}`);
      }
    };

    getItems();
  }, []);


  const postData = async (obj) => {
    try{
      const options = {
        method : "POST",
        headers : {
          "Content-Type" : "application/json"
        },
        body:JSON.stringify({email:user.email,UserArray:obj,groupname:groupName,businessname:user.businessName})
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/adduseringroup`,options)
      const data = await response.json();
      if (data.success) {
        // On success, set the success message
        setMessage('User Inserted Successfully');
        setMessageStyle('green');
        
        // Automatically hide the message after 3 seconds
        setTimeout(() => {
          setMessage('');
        }, 3000);
        return true;
      } else {
        setMessageStyle('red');
        setMessage(data.message);
        setTimeout(() => {
          setMessage('');
        }, 3000);
      }
    }
    catch(Err){
      setMessageStyle('red');
      setMessage('An error occurred');
    setTimeout(() => {
      setMessage('');
    }, 3000);
      console.log(`Error Occurred : ${Err}`)
    }
  }

  // Function to handle file upload
  const handleFileUpload =  (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      // Assuming the Excel file has data in the first sheet
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      // Convert the Excel data to JSON format
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Check if the first row contains the required columns
      const headers = jsonData[0];
      const hasRequiredColumns = requiredColumns.every(col => headers.includes(col));

      if (hasRequiredColumns) {
        // Map the data to the expected format
        const parsedData = jsonData.slice(1).map(row => ({
          id : uuidv4(),
          date : (new Date()).toLocaleDateString('en-GB')+"\n"+(new Date()).toLocaleTimeString('en-GB'),
          name: row[headers.indexOf('Name')],
          number: row[headers.indexOf('Number')],
          details: row[headers.indexOf('Details')],
        }));

        const uniqueUsers = parsedData.reduce((acc, current) => {
          const duplicate = acc.find(user => user.name === current.name || user.number === current.number);
          if (!duplicate) {
            acc.push(current);
          }
          return acc;
        }, []);

        console.log(uniqueUsers);

        const res = await postData(uniqueUsers);
        if(res){
          setUsersList([...uniqueUsers,...usersList]);
        }

        // Set the data in state
      } else {
        // Alert the user and clear the file input
        alert(`The uploaded file must contain the following columns: ${requiredColumns.join(', ')}`);
        fileInputRef.current.value = ''; // Clear the file input
        setUsersList([]); // Clear any previously stored data
      }
    };

    reader.readAsArrayBuffer(file);
  };


    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
          ...formData,
          [id]: value
        });
      };    



      const deleteData = async (obj) => {
        try {
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              email: user.email,
              groupname:groupName,
              businessname:user.businessName,
              UserItem: {
                id: obj
              }
            })
          };
          const response = await fetch(`${process.env.REACT_APP_API_URL}/deleteusersfromgroup`, options);
          
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const data = await response.json();
          console.log("I am from Delete Data");
      
          if (data.success) {
            // On success, set the success message
            setMessage(data.message);
            setMessageStyle('green');
            
            // Automatically hide the message after 3 seconds
            setTimeout(() => {
              setMessage('');
            }, 3000);
      
            // Refresh or update the telephony list
            setUsersList(usersList.filter((ele) => ele.id!==obj)); // Ensure this function is correctly implemented
          } else {
            setMessageStyle('red');
            setMessage(data.message);
            setTimeout(() => {
              setMessage('');
            }, 3000);
          }
        } catch (Err) {
          setMessageStyle('red');
          setMessage('An error occurred');
          setTimeout(() => {
            setMessage('');
          }, 3000);
          console.error(`Error Occurred: ${Err}`);
        }
      };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const finalData = {
            id : uuidv4(),
            name : formData.name,
            number : formData.number,
            details : formData.details,
            date : (new Date()).toLocaleDateString('en-GB')+"\n"+(new Date()).toLocaleTimeString('en-GB')
        }
        const res = await postData([finalData]);
        if(res){
        setUsersList([finalData,...usersList]);
        setFormData({
            name:'',
            number:'',
            details:''
        })
      }
    }

    const parseExcelDate = (excelDate) => {
        // If the date is already a string, just return it
        if (typeof excelDate === 'string') {
          return excelDate;
        }
        // Excel dates are usually stored as serial numbers starting from Jan 1, 1900
        const epoch = new Date(0);
        epoch.setUTCDate(epoch.getUTCDate() + excelDate - 25569);
        return epoch.toISOString().split('T')[0]; // Return in YYYY-MM-DD format
      };


    console.log(usersList)
    

    


    return (
         <div className="business-container">
          {message && <div style={{backgroundColor:`${messageStyle==='green'?'green':'red'}`}} className="notification">{message}</div>}
            <div className="business-container-header">
                <div style={{display:'flex',alignItems:'center'}}>
            <button style={{display:'flex',alignItems:'center'}} onClick={() => navigate("/campaigns/users")}>
                <MdArrowBackIos/>
                </button>
                <h1 style={{margin:0}} className="bc-main-heading">Users</h1>
                </div>
                <Popup
  trigger={<button className="addnew-button">Add User +</button>}
  modal
  nested
>
  {close => (
    <div style={{ width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
      <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
        <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
          <h3 style={{fontSize:'20px'}} className="font-bold mb-6">Add User</h3>
          <form onSubmit={(e) => {
            handleSubmit(e);
            close();
          }} className="">
            
            {/* Name Field */}
            <div className="relative">
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
                placeholder="Name"
                required
              />
              <label
                htmlFor="name"
                className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
              >
                Name
              </label>
            </div>
            <br/>

            {/* Number Field */}
            <div className="relative">
              <input
                type="tel"
                id="number"
                value={formData.number}
                onChange={handleChange}
                className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
                placeholder="Number"
                required
              />
              <label
                htmlFor="number"
                className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
              >
                Number
              </label>
            </div>
            <br/>

            {/* Details Field */}
            <div className="relative">
            <input
                type="text"
                id="details"
                value={formData.details}
                onChange={handleChange}
                className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
                placeholder="Number"
                required
              />
              <label
                htmlFor="details"
                className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
              >
                Details
              </label>
            </div>
            <div className="relative">
                <p style={{fontSize:'18px',fontWeight:'bold',textAlign:'center',marginTop:'10px'}}>OR</p>
                <label style={{marginBottom:'10px'}} htmlFor="exceldata">Upload Excel file</label>
                <br/>
                    <input
                    id="exceldata"
                type="file"
                accept=".xlsx, .xls"
                onChange={(event) => {handleFileUpload(event)
                                close();
                }}
                ref={fileInputRef} // Attach the ref to the file input
            />
            </div>

            {/* Submit Button */}
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <button
            onClick = {() => close()}
              type="button"
              className="col-span-2 bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="col-span-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Add
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )}
</Popup>
        </div>
            <div>
              <div style={{marginTop:'25px'}}>
                <h1 style={{fontWeight:'bold',color:'white',fontSize:'18px'}}>Group : {groupName}</h1>
              <h3 style={{fontWeight:'bold',color:'white',fontSize:'14px'}}>Total Users : {usersList.length}</h3>
              </div>
              <div>
                
              </div>
            <div className="table-outer-container bg-[#444444] mt-2 p-4">
  <table className="min-w-full bg-[#15161A] text-white rounded-lg shadow-lg">
    <thead>
    <tr style={{color:'white',fontSize:'16px'}}>
    <th className="w-1/12 py-3 px-4 text-left border-b border-gray-700">S.No</th>
    <th className="w-2/12 py-3 px-4 text-left border-b border-gray-700">Date</th>
    <th className="w-2/12 py-3 px-4 text-left border-b border-gray-700">Name</th>
    <th className="w-2/12 py-3 px-4 text-left border-b border-gray-700">Number</th>
    <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Details</th>
    <th className="w-2/12 py-3 px-4 text-left border-b border-gray-700">Action</th>
</tr>

    </thead>
    <tbody>
        {usersList.map((ele,index) => (
                  <tr key={index} className="hover:bg-gray-700">
                  <td className="py-3 px-4 border-b border-gray-700">{(usersList.length-index)}</td>
                  <td className="py-3 px-4 border-b border-gray-700">
                    {ele.date}
                  </td>
                   <td className="py-3 px-4 border-b border-gray-700">{ele.name}</td>
                  <td className="py-3 px-4 border-b border-gray-700">{ele.number}</td>
                  <td className="py-3 px-4 border-b border-gray-700">{ele.details}</td>
                  <td className="py-3 px-4 border-b border-gray-700">
                      <button className="bg-transparent mr-3" type="button"><FaEdit size={20}/></button>
                      <Popup
          trigger={<button className="bg-transparent mr-3" type="button"><MdDelete size={20}/></button>}
          modal
          nested
        >
          {close => (
            <div
              style={{
                width: '300px',
                height: '250px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#2d3748',
                color: 'white',
                borderRadius: '10px',
                textAlign: 'center',
              }}
            >
              <h1 style={{fontSize:'20px'}}>Are you sure you want to delete User?</h1>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                <button
                  onClick={() => {
                    close()
                }}
                  className="addnew-button"
                  style={{ width: '60px', fontSize: '12px', marginRight: '5px' }}
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    deleteData(ele.id);
                    close();
                  }}
                  className="addnew-button"
                  style={{
                    backgroundColor: 'red',
                    width: '60px',
                    fontSize: '12px',
                    color: 'white',
                    marginLeft: '5px',
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </Popup>
                  </td>
                </tr>
        ))}

    </tbody>
  </table>
</div>


                {/* <table className="">
                    <thead>
                        <tr>
                        <th>S.NO</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Details</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                    </tbody>
                </table> */}
            </div>

       </div>
    )
}

export default Users;