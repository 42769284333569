import "./index.css"
import IconSidebar from "../../IconSidebar";
import Logo from '../../../assets/comingsoon.png';

const Collections = () => {
    return (
        <div style={{width:'100%'}} className="app">
        {/* <AdminHeader /> */}
       <div className="main-body-container">
         <IconSidebar />
         <div style={{minHeight:'100vh',display:'flex-column',justifyContent:'center',alignItems:'center'}} className="business-container">
          <img style={{height:'500px',width:'500px',margin:'auto'}} src={Logo} alt="coming soon"/>
       </div>
       </div>
       </div>
    )
}

export default Collections;