import "./index.css"
import { MdArrowBackIos } from "react-icons/md";
import { useState } from "react";
import { IoMicCircle } from "react-icons/io5";
import { RiLandscapeLine } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import { useEffect } from "react";


const personalityBehaviour = ['Professional Use Case','Character Use Case','Super Chatty'];
const options = ['Personality','Knowledge','Function','Voice','Finetune','Outcome'];


const voices = [
    { 
      name: 'Aaditya', 
      gender: 'Male', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: true 
    },
    { 
      name: 'Aditi', 
      gender: 'Female', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Aarav', 
      gender: 'Male', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Ananya', 
      gender: 'Female', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Anaya', 
      gender: 'Female', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Aria', 
      gender: 'Female', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Dhruv', 
      gender: 'Male', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Ishaan', 
      gender: 'Male', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Kartik', 
      gender: 'Male', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Kavya', 
      gender: 'Female', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Lakshmi', 
      gender: 'Female', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Maya', 
      gender: 'Female', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Meera', 
      gender: 'Female', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Nisha', 
      gender: 'Female', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Priya', 
      gender: 'Female', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Radhika', 
      gender: 'Female', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Rishi', 
      gender: 'Male', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Sanya', 
      gender: 'Female', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Siddharth', 
      gender: 'Male', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Tarun', 
      gender: 'Male', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Vijay', 
      gender: 'Male', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Vivaan', 
      gender: 'Male', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    },
    { 
      name: 'Yash', 
      gender: 'Male', 
      type: 'Conversational', 
      flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
      isSelect: false 
    }
  ];

const outcomeOptions = [
  {
    "title": "Action Required",
    "description": "Specific steps or follow-ups are needed after the call.",
    "isSelect": true
  },
  {
    "title": "Budget Constraint",
    "description": "The prospect is interested but has budget limitations.",
    "isSelect": true
  },
  {
    "title": "Closed Sale",
    "description": "The prospect agrees to purchase the product or service.",
    "isSelect": true
  },
  {
    "title": "Contact Update Needed",
    "description": "The prospect’s contact information needs updating.",
    "isSelect": true
  },
  {
    "title": "Decision Maker",
    "description": "The person with the authority to make the purchase decision is identified.",
    "isSelect": true
  },
  {
    "title": "Demo Requested",
    "description": "The prospect requests a demonstration or trial of the product.",
    "isSelect": true
  },
  {
    "title": "Follow-Up Needed",
    "description": "The prospect shows interest but requires further contact or information.",
    "isSelect": true
  },
  {
    "title": "Information Provided",
    "description": "Additional details or documentation are sent to the prospect after the call.",
    "isSelect": true
  },
  {
    "title": "Language Barrier",
    "description": "The prospect struggles with language or communication issues.",
    "isSelect": true
  },
  {
    "title": "Meeting Scheduled",
    "description": "A more detailed meeting or presentation is arranged with the prospect.",
    "isSelect": true
  },
  {
    "title": "Miscontact",
    "description": "The AI reaches the wrong person or department for the sale.",
    "isSelect": true
  },
  {
    "title": "Negotiation Needed",
    "description": "The prospect is interested but requires negotiation on terms or price.",
    "isSelect": true
  },
  {
    "title": "No Further Action",
    "description": "No immediate plans for follow-up or further action are agreed upon.",
    "isSelect": true
  },
  {
    "title": "No Interest",
    "description": "The prospect is not interested in the product or service.",
    "isSelect": true
  },
  {
    "title": "Objection Raised",
    "description": "The prospect presents concerns or issues that need addressing.",
    "isSelect": true
  },
  {
    "title": "Proposal Requested",
    "description": "The prospect asks for a formal proposal or quote.",
    "isSelect": true
  },
  {
    "title": "Qualified Lead",
    "description": "The prospect meets criteria for a potential customer but is not ready to buy yet.",
    "isSelect": true
  },
  {
    "title": "Referral Provided",
    "description": "The prospect refers the AI to another potential customer.",
    "isSelect": true
  },
  {
    "title": "Timing Issue",
    "description": "The prospect is interested but indicates a different timing for purchase.",
    "isSelect": true
  },
  {
    "title": "Wrong Contact",
    "description": "The AI reaches the wrong person or department for the sale.",
    "isSelect": true
  }
];


const toneOptions = {
    "" : ["Select Type"],
    "Others" : ["Others"],
    "Affectionate": [
      "Affectionate Tone",
      "Sweet Voice",
      "Tender Touch",
      "Loving Sound",
      "Caring Presence"
    ],
    "Friendly": [
      "Friendly Tone",
      "Warm Greeting",
      "Cheerful Sound",
      "Playful Inflection",
      "Inviting Style"
    ],
    "Calm and Soothing": [
      "Calm Presence",
      "Soothing Touch",
      "Gentle Whisper",
      "Soft Voice",
      "Relaxing Tone"
    ],
    "Energetic and Vibrant": [
      "Energetic Delivery",
      "Vibrant Delivery",
      "Bright Tone",
      "Upbeat Inflection",
      "Dynamic Voice"
    ],
    "Confident and Engaging": [
      "Confident Voice",
      "Engaging Speech",
      "Assertive Tone",
      "Supportive Voice",
      "Clear Delivery"
    ]
  };



const AssistantHome = (props) => {
  const {onChangeAssistantName,onClickBack, templateDetails} = props;
  const [activeTab, setActiveTab] = useState('Personality');
  const [message, setMessage] = useState('');
  const [messageStyle, setMessageStyle] = useState('');
  const user = localStorage.getItem("user");


    const onClickTab = (value) => {
        setActiveTab(value);
    }

        // Personality Form Data Starts

        const [greetingMessage, setGreetingMessage] = useState('');
        const [interactionGuidelines, setInteractionGuidelines] = useState('');
        const [activeBehaviour,setActiveBehaviour] = useState('Professional Behaviour');
    
    
        const postData = async (tab,templateItem) => {
          try{
            const options = {
              method : "POST",
              headers : {
                "Content-Type" : "application/json"
              },
              body: JSON.stringify({  tab: tab,...{id:templateDetails.id}, templateItem   })
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/configureassistant`,options)
            const data = await response.json();
            if (data.success) {
              setMessage(`${tab.charAt(0).toUpperCase() + tab.slice(1)} updated Successfully`);
              setMessageStyle('green');
              
              // Automatically hide the message after 3 seconds
              setTimeout(() => {
                setMessage('');
              }, 3000);
              return true;
            } else {
              setMessageStyle('red');
              setMessage(data.message);
              setTimeout(() => {
                setMessage('');
              }, 3000);
            }
          }
          catch(Err){
            setMessageStyle('red');
            setMessage('An error occurred');
          setTimeout(() => {
            setMessage('');
          }, 3000);
            console.log(`Error Occurred : ${Err}`)
          }
        } 
    
        const onChangeBehaviour = (value) => {
          setActiveBehaviour(value);
        }
    
        const onSavePersonality = async (e) => {
          e.preventDefault()
          const personality = {
            greetingMessage,
            interactionGuidelines,
            activeBehaviour
          }
          postData("personality",personality)
        }
    
        // Personality Form Data Ends
    
        // Knowledge Form Data Starts
    
        const [specializedKnowledge, setSpecializedKnowledge] = useState('');
        const [file, setKnowledgeFile] = useState('');
        const [useContentFromKnowledgeBase, setUseContentFromKnowledgeBase] = useState(false);
        const [fileUrl, setFileUrl] = useState('');
        const [knowledgeFiles, setKnowledgeFiles] = useState([]);
        const [isUpload, setIsUploading] = useState(false);
    
        const onToggleUseContentFromKnowledgeBase = () => {
          setUseContentFromKnowledgeBase(!useContentFromKnowledgeBase)
        }
    
        // const handleFileChange = (e) => {
        //   setKnowledgeFile(e.target.files[0]);
        // }
    
        const handleFileChange = async (e) => {
          const file = e.target.files[0];
          if (!file) return; // Ensure a file is selected
      
          try {
            // Set uploading state
            setIsUploading(true);
      
            // Check if the file size is less than 10MB (single upload)
            if (file.size < 10000000) {
              // Call the API to get the presigned URL for single file upload
              const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/generate-single-presigned-url`,
                {
                  fileName: `${uuidv4}-${file.name}`,
                }
              );
              const { url } = response.data;
      
              // Upload file using presigned URL
              const uploadResponse = await axios.put(url, file, {
                headers: {
                  "Content-Type": file.type,
                },
              });
      
              // Check upload response
              if (uploadResponse.status === 200) {
                // Set the file URL (this is the URL where the file was uploaded)
                setFileUrl(url.split("?")[0]);
                setMessage('File Uploaded Successfully');
                setMessageStyle('green');
                
                // Automatically hide the message after 3 seconds
                setTimeout(() => {
                  setMessage('');
                }, 3000);
                setKnowledgeFiles([url.split("?")[0],...knowledgeFiles])
              } else {
                setMessage('Failed to Upload');
                setMessageStyle('red');
                
                // Automatically hide the message after 3 seconds
                setTimeout(() => {
                  setMessage('');
                }, 3000);
              }
              setIsUploading(false); // Reset uploading state
            } else {
              // Multipart upload for large files (more than 10MB)
              const uniqueFilename = `${uuidv4()}-${file.name}`;
              const startUploadResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/start-multipart-upload`,
                {
                  fileName: uniqueFilename,
                  contentType: file.type,
                }
              );
      
              const { uploadId } = startUploadResponse.data;
      
              // Chunk file into 10MB parts
              const chunkSize = 10000000; // 10MB
              const numChunks = Math.ceil(file.size / chunkSize);
              const totalSize = file.size;
      
              // Request presigned URLs for each part
              const presignedUrlsResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/generate-presigned-url`,
                {
                  fileName: uniqueFilename,
                  uploadId,
                  partNumbers: numChunks,
                }
              );
      
              const presignedUrls = presignedUrlsResponse.data.presignedUrls;
      
              const parts = [];
              const uploadPromises = [];
      
              // Upload each chunk separately
              for (let i = 0; i < numChunks; i++) {
                const start = i * chunkSize;
                const end = Math.min(start + chunkSize, totalSize);
                const chunk = file.slice(start, end);
      
                const presignedUrl = presignedUrls[i];
      
                uploadPromises.push(
                  axios.put(presignedUrl, chunk, {
                    headers: {
                      "Content-Type": file.type,
                    },
                  })
                );
              }
      
              // Wait for all chunks to upload
              const uploadResponses = await Promise.all(uploadPromises);
      
              uploadResponses.forEach((response, i) => {
                parts.push({
                  etag: response.headers.etag,
                  PartNumber: i + 1,
                });
              });
      
              // Complete multipart upload
              const completeUploadResponse = await axios.post(
                `${process.env.REACT_APP_API_URL}/complete-multipart-upload`,
                {
                  fileName: uniqueFilename,
                  uploadId,
                  parts,
                }
              );
      
              if (completeUploadResponse.status === 200) {
                // Set the file URL (construct the S3 object URL)
                console.log(presignedUrls[0].split("?")[0])
                setFileUrl(
                  presignedUrls[0].split("?")[0]
                );
                setMessage('File Uploaded Successfully');
              setMessageStyle('green');
              
              // Automatically hide the message after 3 seconds
              setTimeout(() => {
                setMessage('');
              }, 3000);
                setKnowledgeFiles([presignedUrls[0].split("?")[0],...knowledgeFiles])
              } else {
              setMessage('File Upload Failed');
              setMessageStyle('red');
              
              // Automatically hide the message after 3 seconds
              setTimeout(() => {
                setMessage('');
              }, 3000);
              }
    
      
              setIsUploading(false);
            }
          } catch (error) {
            console.error("Upload error:", error);
            setMessage('Failed to Upload');
            setMessageStyle('red');
            
            // Automatically hide the message after 3 seconds
            setTimeout(() => {
              setMessage('');
            }, 3000);
            setIsUploading(false); // Reset uploading state
          }
        };
    
        const onSaveKnowledge = async (e) => {
          e.preventDefault();
          const knowledgeData = {
            specializedKnowledge,
            knowledgeFiles:knowledgeFiles.filter((ele) => ele!==""),
            useContentFromKnowledgeBase
          }
          postData("knowledge",knowledgeData);
        }
    
        // Knowledge Form Data Ends
    
    
        // Action Form Data Starts
        
        const [selectedActions, setSelectedActions] = useState({
          provideCouponCodes: false,
          checkCibilScore: false,
          verifyPersonalDetails: false,
          kycVerification: false,
          rescheduleMeetings: false,
        });
      
        const handleActionChange = (action) => {
          setSelectedActions((prevState) => ({
            ...prevState,
            [action]: !prevState[action],
          }));
        
        };
        
        const onSaveAction = async (e) => {
          e.preventDefault();
          const actionData  = selectedActions
          
          postData("action",actionData);
        }
    
        // Action Form Data Ends
    
        // Voice Form Data Starts
        const [voiceCategory, setVoiceCategory] = useState('');
        const [voiceType, setVoiceType] = useState('');
        const [gender, setGender] = useState('');
        const [Voicelanguage, setVoiceLanguage] = useState('');
        const [availableVoices, setAvailableVoices] = useState(voices);
        const [voiceToneOptions, setVoiceToneOptions] = useState([]);
    
    
        const handleVoiceChange = (voice) => {
          const newList = availableVoices.map((ele) => {
            if(ele.name===voice.name){
              return {...ele,isSelect:true};
            }
            else
            return {...ele,isSelect:false};
          })
          setAvailableVoices(newList)
          }
    
          const onSaveVoice = async (e) => {
            e.preventDefault();
            const voiceData = {
              category : voiceCategory,
              type : voiceType,
              gender : gender,
              language : Voicelanguage,
              voice : availableVoices.filter((ele) => ele.isSelect===true)[0]
            }
            postData("voice",voiceData)
          }
    
        // Voice Form Data Ends
    
        // Finetune Form Data Starts
    
        const [finetunemodel, setFineTuneModel] = useState('');
        const [suffix, setSuffix] = useState('');
        const [role, setRole] = useState('');
        const [inputBoxes, setInputBoxes] = useState([]);
    
        const handleInputBoxChange = (index, event) => {
          const { name, value } = event.target;
          
          // Create a copy of the current input boxes state
          const updatedInputBoxes = [...inputBoxes];
          
          // Update the specific field (userquestion/systemanswer) of the specific input box
          updatedInputBoxes[index] = {
              ...updatedInputBoxes[index],
              [name]: value
          };
          
          // Update the state with the modified array
          setInputBoxes(updatedInputBoxes);
      };
    
      const addInputBox = () => {
        setInputBoxes([...inputBoxes, '']);
      };
    
      const DeleteInputBox = (value) => {
        const newList = inputBoxes.filter((ele,index) => index!==value);
        setInputBoxes(newList);
      }
    
      const onSaveFinetune = async (e) => {
        e.preventDefault();
        const finetuneData = {
          model : finetunemodel,
          suffix,
          role,
          questions : inputBoxes
        }
        postData("finetune",finetuneData);
      }
    
        // Finetune Form Data Ends
    
        // Outcome Form Data Starts
        const [outcomes, setOutcomes] = useState(outcomeOptions);
        const onSaveOutcomes = (e) => {
          e.preventDefault();
          postData("outcome",outcomes);
        }
        // Outcome Form Data Ends
    
    
    
    
    
    
    
        useEffect(() => {
          const getItems = async () => {
            try {
              const response = await fetch(`${process.env.REACT_APP_API_URL}/getassistants/${templateDetails.id}`);
              const data = await response.json();
              if(data.templateArray){
              let assistantData = data.templateArray.configure;
              if(assistantData){
                if(assistantData.personality){
                  const {personality} = assistantData
                  setGreetingMessage(personality.greetingMessage)
                  setInteractionGuidelines(personality.interactionGuidelines)
                  setActiveBehaviour(personality.activeBehaviour)
                }
                if(assistantData.knowledge){
                  const {knowledge} = assistantData
                  setSpecializedKnowledge(knowledge.specializedKnowledge);
                  setUseContentFromKnowledgeBase(knowledge.useContentFromKnowledgeBase);
                }
                if(assistantData.action){
                  const {action} = assistantData
                  setSelectedActions(action);
                }
                if(assistantData.voice){
                  const {voice} = assistantData
                  setVoiceCategory(voice.category);
                  setVoiceType(voice.type);
                  setGender(voice.gender);
                  handleVoiceChange(voice.voice);
                }
                if(assistantData.finetune){
                  const {finetune} = assistantData
                  setFineTuneModel(finetune.model);
                  setSuffix(finetune.suffix);
                  setRole(finetune.role);
                  setInputBoxes(finetune.questions);
                }
                if(assistantData.outcome){
                  const {outcome} = assistantData
                  setOutcomes(outcome)
                }
              }
              }
            } catch (err) {
              console.error(`Error Occurred: ${err}`);
            }
          };
      
          getItems();
        }, []);


































  





    return (
        <div className="assistants-container">
        <div style={{width:'300px'}} className="create-assistant-container">
          <div className='createbutton-container'>
          <button style={{display:'flex',alignItems:'center',marginBottom:'20px',marginLeft:'10px',marginTop:'10px'}} onClick={onClickBack}>
                <MdArrowBackIos/>
                </button>
            <h1 style={{fontSize:'20px',fontWeight:'bold',marginTop:'15px'}}>AI Assistant Details</h1>
      </div>
        <div className="domain-container">
        <ul className="domain-container">
      {/* <li
        className={`${activeTab === "ALL" ? 'active' : ''} domain-item`}
        style={{ cursor: 'pointer' }}
        onClick={() => onClickDomain("ALL")}
      >
        ALL
      </li> */}
      {options.map((ele, index) => (
        <li
          key={index}
          style={{ cursor: 'pointer' }}
          onClick={() => onClickTab(ele)}
          className={`${activeTab === ele ? 'active' : ''} domain-item`}
        >
          {ele}
        </li>
      ))}
    </ul>
        </div>

          {/* {activeAssistants.map(each => (
            <div className='newAssistant-container'>
              <div style={{display:'flex',justifyContent:'space-between'}}>
                <div>
              <h1 className='assistant-name'>{each.name}</h1>
              <p className='copy-link'>{each.id}</p>
              </div>
              <Popup
          trigger={<button style={{marginLeft:'30px'}} className=" text-gray-600 hover:text-gray-900 focus:outline-none"><MdDelete size={20}  /></button>}
          modal
          nested
        >
          {close => (
            <div style={{width:'300px',height:'150px',padding:'20px'}} className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg">
              <div className="flex flex-col items-center mt-4">
                <h1>Are you sure want to delete Assistant?</h1>
                <div style={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                <button onClick={() => close()} className='addnew-button mr-3' style={{width:'60px',fontSize:'12px'}}>Close</button>
                <button className='addnew-button mr-3' style={{backgroundColor:'red',width:'60px',fontSize:'12px'}}>Delete</button>
                </div>
              </div>
            </div>
          )}
        </Popup>
              </div>
          </div>
          ))} */}
        </div>
        <div className='side-container p-4 hidden-scrollbar'>
        {message && <div style={{backgroundColor:`${messageStyle==='green'?'green':'red'}`}} className="notification">{message}</div>}
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-between',marginBottom:'10px'}}>
        <h1 style={{fontSize:'25px',fontWeight:'bold',marginTop:'15px',marginBottom:'15px',marginRight:'10px'}}> {templateDetails.assistantName} </h1>
        <IoMicCircle size={50} style={{marginRight:'20px'}}/>
        </div>

        {/* <div className="template-form-card temp2">
            <h1 style={{fontSize:'20px',fontWeight:'bold'}}>Template Details</h1>
            <div className="template-form-main-card">
                <h1 style={{fontSize:'20px',fontWeight:'bold'}}>Name : {templateDetails.assistantName}</h1>
                <p>Domain : {templateDetails.domain}</p>
                <p>Type : {templateDetails.type}</p>
                <p>Description : {templateDetails.description}</p>
            </div>
        </div> */}

                {/*         Personality Tab          */}

        {/* <h1 className="tab-section-heading">Personality Section</h1> */}
        <form className="w-100">
                {activeTab==="Personality" && (<div className="model-section">
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'10px',marginBottom:'10px'}}>
            <h1 className="template-form-section-heading">Personality</h1>
            <button onClick={(e) => onSavePersonality(e)} className="template-form-save-button" type="button">Save</button>
            </div>
            {/* <div className="section-header">
                <h2 className='identity-heading'>Identity</h2>
            </div>
            <div className="section-peroson-body">
                <div className="input-group">
                    <label htmlFor="Ainame" className='label-peroson-element'>Name</label>
                    <br/>
                    <input id="Ainame" className='input-model' type="text" placeholder="Give Your AI a name..." />
                </div>
                <div className="w-100 avatar-container">
                    <div className="avatar-header">
                        <RiLandscapeLine className='avatar-image' />
                        <h2 className="avatar-heading">AVATAR</h2>
                    </div>
                    <button className="upload-button-image">Upload Image</button>
                    <div className="avatar-options">
                    </div>
                 </div>
            </div> */}

            <div className="section-header">
                <h2 className='behaviour-heading'>Behaviour Settings</h2>
            </div>
            <div className="section-body">
                <div className="section">
                    <h2 className="section-agent-heading">Greeting Message</h2>
                    <p className="section-description">
                    Define the introductory message your assistant will use to start each conversation.  
                    </p>
                    <textarea value={greetingMessage} onChange={(e) => setGreetingMessage(e.target.value)} className="greeting-agent-input" placeholder="Hello! How can I assist you today?"></textarea>
                </div>
                <div className="section">
        <h2 className="section-know-heading">Interaction Guidelines</h2>
        <label htmlFor="AssistantGreeting" className="section-description">
        Outline the behavior and interaction style your AI should follow during conversations.  
        </label>
        <textarea value={interactionGuidelines} onChange={(e) => setInteractionGuidelines(e.target.value)} id="AssistantGreeting" className="input" rows="6" placeholder="e.g. You are a customer support assistant. Provide accurate answers based on your knowledge, and never invent information." ></textarea>
      </div>
                <div className="section">
                    <h2 className="section-agent-heading">Assistant Behaviour</h2>
                    <div onClick={() => onChangeBehaviour("Professional Assistant")} className={`behavior-option ${activeBehaviour==="Professional Assistant"?'active':''}`}>
                        <h3 className="option-heading">Professional Assistant</h3>
                        <p className="option-description">
                          Polite, formal, and task-focused.
                        </p>
                    </div>
                    <div onClick={() => onChangeBehaviour("Character Impersonation")} className={`behavior-option ${activeBehaviour==="Character Impersonation"?'active':''}`}>
                        <h3 className="option-heading">Character Impersonation</h3>
                            <p className="option-description">
                                    Adopts specific identities.
                            </p>
                    </div>
                    <div onClick={() => onChangeBehaviour("Casual Chat Mode")} className={`behavior-option ${activeBehaviour==="Casual Chat Mode"?'active':''}`}>
                        <h3 className="option-heading">Casual Chat Mode</h3>
                        <p className="option-description">
                        Friendly and laid-back conversations.
                        </p>
                    </div>
                    <div onClick={() => onChangeBehaviour("Quick Response Mode")} className={`behavior-option ${activeBehaviour==="Quick Response Mode"?'active':''}`}>
                        <h3 className="option-heading">Quick Response Mode</h3>
                        <p className="option-description">
                        Provides brief, to-the-point answers.
                        </p>
                    </div>
                    <div onClick={() => onChangeBehaviour("Knowledge Expert")} className={`behavior-option ${activeBehaviour==="Knowledge Expert"?'active':''}`}>
                        <h3 className="option-heading">Knowledge Expert</h3>
                        <p className="option-description">
                        Offers detailed, expert-level information and guidance.
                        </p>
                    </div>
                 </div>
            </div>
        </div>)}

        
                {/*             Knowledge Section             */}
                {/* <h1 className="tab-section-heading">Knowledge Section</h1> */}
                {activeTab==="Knowledge"  && 
                (
                  <div className="model-section">
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
                      <h1 className="template-form-section-heading">Knowledge</h1>
                      <button className="template-form-save-button" type="button" onClick={onSaveKnowledge}>
                        Save
                      </button>
                    </div>
                    <div className="section">
                      <h2 className="section-know-heading">Knowledge Customization</h2>
                      <p className="section-description">
                        Add Specialized Knowledge
                      </p>
                      <textarea
                        className="input"
                        rows="6"
                        placeholder="Integrate specific information to refine your assistant's responses. The more targeted the knowledge, the closer the assistant will meet your expectations. For business contexts, upload details like business hours, FAQs, customer service policies, etc."
                        value={specializedKnowledge}
                        onChange={(e) => setSpecializedKnowledge(e.target.value)}
                      />
                    </div>
                    <div className="upload-section">
                      <label className="upload-button" htmlFor="knowledgeFileInput">
                        {isUpload===false?'Upload Information Sources':'Uploading...'}
                      </label>
                      <input
                        id="knowledgeFileInput"
                        type="file"
                        accept=".pdf,.csv,.xlsx,.txt"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                      <p className="upload-description">
                        Add documents such as PDFs, CSV, EXCEL, .TXT, and more to expand your assistant's knowledge base.
                      </p>
                      {file && (
                        <p className="file-name">
                          Selected file: {file.name}
                        </p>
                      )}
                    </div>
                    <div className="guardrails-section">
                      <h2 className="section-know-heading">Enforce Knowledge Base Use</h2>
                      <p className="section-description">
                        Limit the agent to respond solely from the custom knowledge base, excluding general knowledge.
                      </p>
                      <div className="checkbox-wrapper">
                        <input
                          type="checkbox"
                          id="guardrails-checkbox"
                          className="checkbox"
                          checked={useContentFromKnowledgeBase}
                          onChange={onToggleUseContentFromKnowledgeBase}
                        />
                        <label htmlFor="guardrails-checkbox">
                          Yes, only use content from the knowledge base.
                        </label>
                      </div>
                    </div>
                  </div>
                )}

        
        {/*             Actions Section             */}
        {/* <h1 className="tab-section-heading">Action Section</h1> */}
        {activeTab==="Function" && (
          <div className="model-section">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
              <h1 className="template-form-section-heading">Actions</h1>
              <button onClick={(e) => onSaveAction(e)} className="template-form-save-button" type="button">
                Save
              </button>
            </div>
            <div className="section-body">
              <div className="section">
                <h2 className="section-know-heading">Actions</h2>
                <p className="section-description">
                  Select actions available for your assistant.
                </p>
              </div>
              <div className="space-y-4">
                <label className="flex items-center bg-gray-800 p-4 rounded-lg cursor-pointer">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-green-500"
                    checked={selectedActions.provideCouponCodes}
                    onChange={() => handleActionChange('provideCouponCodes')}
                  />
                  <div>
                    <p className="ml-3 text-green-500 font-medium">Provide Coupon Codes</p>
                    <p className="ml-3 text-gray-400 text-sm">
                      Share current coupon codes or promotional offers with the caller.
                    </p>
                  </div>
                </label>
    
                <label className="flex items-center bg-gray-800 p-4 rounded-lg cursor-pointer">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-green-500"
                    checked={selectedActions.checkCibilScore}
                    onChange={() => handleActionChange('checkCibilScore')}
                  />
                  <div>
                    <p className="ml-3 text-green-500 font-medium">Check CIBIL Score</p>
                    <p className="ml-3 text-gray-400 text-sm">
                      Retrieve and provide information about the caller’s CIBIL credit score.
                    </p>
                  </div>
                </label>
    
                <label className="flex items-center bg-gray-800 p-4 rounded-lg cursor-pointer">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-green-500"
                    checked={selectedActions.verifyPersonalDetails}
                    onChange={() => handleActionChange('verifyPersonalDetails')}
                  />
                  <div>
                    <p className="ml-3 text-green-500 font-medium">Verify Personal Details</p>
                    <p className="ml-3 text-gray-400 text-sm">
                      Confirm and validate personal information provided by the caller.
                    </p>
                  </div>
                </label>
    
                <label className="flex items-center bg-gray-800 p-4 rounded-lg cursor-pointer">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-green-500"
                    checked={selectedActions.kycVerification}
                    onChange={() => handleActionChange('kycVerification')}
                  />
                  <div>
                    <p className="ml-3 text-green-500 font-medium">KYC Verification</p>
                    <p className="ml-3 text-gray-400 text-sm">
                      Authenticate the caller's identity using official documents like Aadhar numbers and ensure compliance with regulatory requirements.
                    </p>
                  </div>
                </label>
    
                <label className="flex items-center bg-gray-800 p-4 rounded-lg cursor-pointer">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-green-500"
                    checked={selectedActions.rescheduleMeetings}
                    onChange={() => handleActionChange('rescheduleMeetings')}
                  />
                  <div>
                    <p className="ml-3 text-green-500 font-medium">Reschedule Meetings</p>
                    <p className="ml-3 text-gray-400 text-sm">
                      Change the date or time of previously scheduled meetings based on the caller’s request.
                    </p>
                  </div>
                </label>
              </div>
            </div>
          </div>)}

                            {/*             Model Section             */}
                            {/* <h1 className="tab-section-heading">Model Section</h1> */}
        {/* {activeTab==="Model" && (<div className="model-section">
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'10px',marginBottom:'10px'}}>
            <h1 className="template-form-section-heading">Model</h1>
            <button className="template-form-save-button" type="button">Save</button>
            </div>
            <div className="section-header">
                <h2 className='model-heading'>Model</h2>
                <p className='para-model'>This section allows you to configure the model for the assistant.</p>
            </div>
            <div className="section-body">
                <div className="input-group">
                    <label htmlFor="domain" className='model-label'>Domain</label>
                    <select
                        id="domain"
                        name="domain"
                        value={formData.domain} // Bind the select value to the formData state
                        onChange={handleModelChange} // Attach the handleChange function to onChange
                    >
                        <option value="">Select Domain</option>
                        <option value="openai">OpenAI</option>
                        <option value="together-ai">Together AI</option>
                        <option value="anyscale">Anyscale</option>
                        <option value="openrouter">OpenRouter</option>
                        <option value="groq">Groq</option>
                        <option value="anthropic">Anthropic</option>
                        <option value="custom-llp">Custom LLP</option>
                    </select>
                </div>
                <div className="input-group">
                    <label htmlFor="category" className='model-label'>Category</label>
                    <select
                        id="category"
                        name="category"
                        value={formData.category} // Bind the select value to the formData state
                        onChange={handleModelChange} // Attach the handleChange function to onChange
                    >
                        <option value="">Select Category</option>
                        <option value="gpt-4-cluster">GPT 4 Cluster</option>
                        <option value="gpt-3.5-turbo-cluster">GPT 3.5 Turbo Cluster</option>
                        <option value="gpt-4-turbo-cluster">GPT 4 Turbo Cluster</option>
                    </select>
                </div>
            </div>
        </div>)} */}


        {/*             Transcriber Section             */}
        {/* <h1 className="tab-section-heading">Transcriber Section</h1> */}
        {/* {activeTab==="Transcriber" && (<div className="transcription-settings">
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'10px',marginBottom:'10px'}}>
            <h1 className="template-form-section-heading">Transcriber</h1>
            <button className="template-form-save-button" type="button">Save</button>
            </div>
      <h2>Transcription</h2>
      <p>This section allows you to configure the transcription settings for the assistant.</p>
      <div className="settings-group">
        <div className="setting">
          <label htmlFor="provider">Model</label>
          <select
            id="provider"
            name="provider"
            value={formData.provider} // Bind the select value to the formData state
          >
            <option value="">Select Model</option>
            <option value="deepgram">Deepgram</option>
            <option value="talkscriber">Talkscriber</option>
            <option value="gladia">Gladia</option>
          </select>
        </div>
        <div className="setting">
          <label htmlFor="language">Language</label>
          <select
            id="language"
            name="language"
            value={formData.language} // Bind the select value to the formData state
             // Attach the handleChange function to onChange
          >
            <option value="">Select Language</option>
            <option value="English">English</option>
            <option value="Hindi">Hindi</option>
            <option value="Telugu">Telugu</option>
            <option value="English-AU">English (Australia)</option>
            <option value="English-AB">English (AB)</option>
          </select>
        </div>
      </div>
    </div>)} */}

    {/*             Voice Section             */}
    {/* <h1 className="tab-section-heading">Voice Section</h1> */}
    {activeTab === "Voice" && (
  <div className="pop-up-container">
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', marginBottom: '10px' }}>
      <h1 className="template-form-section-heading">Voice</h1>
      <button onClick={(e) => onSaveVoice(e)} className="template-form-save-button" type="button">Save</button>
    </div>
    <p>Configure the voice settings for the assistant.</p>

    <div className="settings-group">
      {/* Category */}
      <div className="setting">
        <label htmlFor="category">Category</label>
        <select
          onChange={(e) => {
            const selectedCategory = e.target.value;
            setVoiceCategory(selectedCategory);  // Set the selected voice category
            setVoiceToneOptions(toneOptions[selectedCategory] || []);  // Update tone options based on selected category
          }}
          id="category"
          name="domain"
          value={voiceCategory} // Binding the current selected value
        >
          <option value="">Select Category</option>
          <option value="Affectionate">Affectionate</option>
          <option value="Calm and Soothing">Calm and Soothing</option>
          <option value="Energetic and Vibrant">Energetic and Vibrant</option>
          <option value="Confident and Engaging">Confident and Engaging</option>
          <option value="Others">Others</option>
        </select>
      </div>

      {/* Type */}
      <div className="setting">
        <label htmlFor="type">Type</label>
        <select
          onChange={(e) => setVoiceType(e.target.value)}
          id="type"
          name="type"
          value={voiceType} // Binding the current selected value
        >
          <option value="">Select Type</option>
          {voiceToneOptions.map((tone, index) => (
            <option key={index} value={tone}>{tone}</option>
          ))}
        </select>
      </div>

      {/* Gender */}
      <div className="setting">
        <label htmlFor="gender">Gender</label>
        <select
          onChange={(e) => setGender(e.target.value)}
          id="gender"
          name="gender"
          value={gender} // Binding the current selected value
        >
          <option value="">Select Gender</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
        </select>
      </div>

      {/* Language */}
      <div className="setting">
        <label htmlFor="voicelanguage">Language</label>
        <select
          onChange={(e) => setVoiceLanguage(e.target.value)}
          id="voicelanguage"
          name="language"
          value={Voicelanguage} // Binding the current selected value
        >
          <option value="">Select Language</option>
          <option value="English">English</option>
          <option value="Hindi">Hindi</option>
          <option value="Telugu">Telugu</option>
          <option value="Multi Language">Multi Language</option>
          <option value="English-AU">en-AU</option>
          <option value="English-AB">en-AB</option>
        </select>
      </div>
    </div>

    {/* Voices List */}
    <div className="voices-container">
      <ul className="voice-list">
        {availableVoices.map((voice, index) => (
          <li key={index} className={`voice-item ${voice.isSelect ? 'selected-2' : ''}`}>
            <img src={voice.flag} alt={voice.name} className="flag-icon" />
            <div className="voice-info">
              <div className="voice-name">{voice.name}</div>
              <div className="voice-meta">{voice.gender}</div>
            </div>
            <button type="button" className="play-button">▶️</button>
            {voice.isSelect ? (
              <button type="button" style={{ width: '110px', backgroundColor: '#2684ff', color: 'white' }} className="use-voice-button" onClick={() => handleVoiceChange(voice)}>
                Selected
              </button>
            ) : (
              <button type="button" className="use-voice-button" onClick={() => handleVoiceChange(voice)}>Select Voice</button>
            )}
          </li>
        ))}
      </ul>
    </div>
  </div>
)}


{activeTab==="Finetune" && (
        <div className="model-section">
        <div className="section-header">
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'10px',marginBottom:'10px'}}>
            <h1 className="template-form-section-heading">Finetune</h1>
            <button onClick={onSaveFinetune} className="template-form-save-button" type="button">Save</button>
            </div>
          <div style={{display:'flex',alignItems:'center'}}>
            <select value={finetunemodel} onChange={(e) => setFineTuneModel(e.target.value)} id="finetunemodel" style={{width:'50%',marginBottom:'0'}} className="input-group">
              <option value="">Select Model</option>
              <option value="gpt-4o">gpt-4o</option>
              <option value="gpt-4o mini">gpt-4o mini</option>
              <option value="Ilama 3.1 8b">Ilama 3.1 8b</option>
              <option value="Ilama 3.1 70b">Ilama 3.1 70b</option>
            </select>
            <input value={suffix} onChange={(e) => setSuffix(e.target.value)} id="finetunesuffix" className="template-form-input w-50" type="text" placeholder="Enter Suffix"/>
          </div>
          <div className="mt-3">
            <label htmlFor="systemprompt">System Prompt (Role)</label>
            <br/>
            <textarea value={role} onChange={(e) => setRole(e.target.value)} id="systemprompt" className="mt-2 template-form-input" style={{width:'100%'}} placeholder="e.g. Hey! Enter the System Prompt"></textarea>
          </div>
        <div style={{marginTop:'5px', marginBottom:'5px',display:'flex',justifyContent:'center',alignItems:'center'}}>
        <button type="button" onClick={addInputBox} className="addnew-button">Add Q/A   +</button>
        </div>
    </div>
    {inputBoxes.map((values, index) => (
        <div key={index} className="section-body mt-5 mb-5">
        <div className="section">
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <div>
            <h2 style={{fontSize:'18px'}} className="section-agent-heading">User (Question {index+1})</h2>
            <p className="section-description">
                Ask the question to train the model.
            </p>
            </div>
            <Popup
          trigger={<button  type="button" style={{marginRight:'30px'}} className=" text-gray-600 hover:text-gray-900 focus:outline-none"><MdDelete size={20}  /></button>}
          modal
          nested
        >
          {close => (
            <div style={{width:'300px',height:'150px',padding:'20px'}} className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg">
              <div className="flex flex-col items-center mt-4">
                <h1 style={{fontSize:'20px'}}>Are you sure want to delete?</h1>
                <div style={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                <button onClick={() => close()} className='addnew-button mr-3' style={{width:'60px',fontSize:'12px'}}>Close</button>
                <button onClick={() => {
            DeleteInputBox(index)
            close()
          }} className='addnew-button mr-3' style={{backgroundColor:'red',width:'60px',fontSize:'12px'}}>Delete</button>
                </div>
              </div>
            </div>
          )}
        </Popup>
            </div>
            <textarea style={{color:'white'}} name="userquestion" onChange={(event) => handleInputBoxChange(index, event)} value={values.userquestion} className="greeting-agent-input" placeholder="e.g. Hey! How may we be of assistance today?"></textarea>
        </div>
        <div className="section">
<h2 style={{fontSize:'18px'}} className="section-know-heading">Assistant (Answer {index+1})</h2>
<label htmlFor="AssistantGreeting" className="section-description">
    Add the expected response from the Assistant.
</label>
<textarea name="systemanswer" value={values.systemanswer} onChange={(event) => handleInputBoxChange(index, event)} id="AssistantGreeting" className="input" rows="6" placeholder="e.g. You are a customer support assistant. You will try to respond to the user’s questions with the best answers given your knowledge. You will never make up information." ></textarea>
</div>
</div>
      ))}
    
</div>)}















        {/*             Outcome Section             */}
        {/* <h1 className="tab-section-heading">Action Section</h1> */}
        {activeTab==="Outcome" && (<div className="model-section">
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'10px',marginBottom:'10px'}}>
            <h1 className="template-form-section-heading">Outcomes</h1>
            <button onClick={onSaveOutcomes} className="template-form-save-button" type="button">Save</button>
            </div>
            <div className="section-body">
                <div className="section">
        <h2 className="section-know-heading">Outcome</h2>
        <p className="section-description">
        The result of an AI caller’s interaction with users
        </p>
      </div>
      <div className="space-y-4">

      {outcomes.map((ele, index) => (
  <label key={index + 1} className="flex items-center bg-gray-800 p-4 rounded-lg cursor-pointer">
    <input
      type="checkbox"
      checked={ele.isSelect}
      className="form-checkbox h-5 w-5 text-green-500"
      onChange={() => {
        const updatedOptions = outcomes.map((option, i) => i === index ? { ...option, isSelect: !option.isSelect } : option);
        setOutcomes(updatedOptions);
      }}
    />
    <div className="w-100">
      <div className="flex justify-between items-center">
        <p style={{ color: 'aqua' }} className="ml-3 font-medium">
          {ele.title}
        </p>
        {ele.rebuttle?(<Popup
          trigger={<button type="button" className="addnew-button">View Rebuttle</button>}
          modal
          nested
        >
          {close => (
            <div style={{ width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
              <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
                  <h3 style={{ fontSize: '20px' }} className="font-bold mb-6">Rebuttle</h3>
                  <textarea value={ele.rebuttle} onChange={(e) => {
              const updatedOptions = outcomes.map((option, i) => i === index ? { ...option, rebuttle:e.target.value } : option);
              setOutcomes(updatedOptions);
                  }} style={{ height: '200px' }} className="greeting-agent-input" placeholder="Enter your response"></textarea>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <button onClick={() => close()} className='addnew-button mr-3' style={{ width: '60px', fontSize: '12px' }}>Close</button>
                    <button onClick={() => {
                      close()
                    }} className='addnew-button mr-3' style={{ backgroundColor: 'darkgreen', width: '60px', fontSize: '12px' }}>Save</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Popup>):(

        <Popup
          trigger={<button type="button" className="addnew-button">Add Rebuttle</button>}
          modal
          nested
        >
          {close => (
            <div style={{ width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
              <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
                  <h3 style={{ fontSize: '20px' }} className="font-bold mb-6">Add Rebuttle</h3>
                  <textarea onChange={(e) => {
              const updatedOptions = outcomes.map((option, i) => i === index ? { ...option, rebuttle:e.target.value } : option);
              setOutcomes(updatedOptions);
                  }} style={{ height: '200px' }} className="greeting-agent-input" placeholder="Enter your response"></textarea>
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                    <button onClick={() => close()} className='addnew-button mr-3' style={{ width: '60px', fontSize: '12px' }}>Close</button>
                    <button onClick={() => {
                      close()
                    }} className='addnew-button mr-3' style={{ backgroundColor: 'darkgreen', width: '60px', fontSize: '12px' }}>Save</button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Popup>)}
      </div>
      <p className="ml-3 text-gray-400 text-sm">
        {ele.description}
      </p>
    </div>
  </label>
))}


      </div>
                
            </div>
        </div>)}


















        </form>
        </div>
        </div>
    )
}

export default AssistantHome;