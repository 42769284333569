import "./index.css"
import IconSidebar from "../../IconSidebar";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import { useRef } from "react";
import { IoMicCircle } from "react-icons/io5";
import { FaMicrophoneLines } from "react-icons/fa6";
import {v4 as uuidv4} from 'uuid';
import { HiSpeakerWave } from "react-icons/hi2";
import { IoMdClose } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { AiFillRobot } from "react-icons/ai";
import { MdOutlineAddIcCall } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { RiRobot3Fill } from "react-icons/ri";
import { CgTranscript } from "react-icons/cg";
import { FaUser } from "react-icons/fa";
import { MdHeadphones } from "react-icons/md";
const assistantArray = ['Vijay Kumar Singh','John']
const dids = ['07971908298']
const statusOptions = ["Not started",
    "In queue",
    "Ongoing",
    "Completed", 
    "Not Connected" ,
    "Invalid" ,
    "Not reachable", 
    "Incoming Bard"  ,
    'Error']

const AdminConversations = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name:'',
        number:'',
        group:'',
        status:'Not Started',
        isSelected : false
    });
    const [micOn, setMicOn] = useState(false);
    const params = useParams();
    const {campaignName} = params;
    const [usersList, setUsersList] = useState([]);
    const [selectedAssistant, setSelectedAssistant] = useState('');
    const [did, setDID] = useState('7971908298');
    const fileInputRef = useRef(null);
    const [activeAssistant, setActiveAssistant] = useState({
      name : 'Geeta',
      type : 'Customer Care Executive'
    });
    const [user, setUser] = useState({
      name : 'Vijay',
      mobileno : '8147540362'
    });

  // Required columns
  const requiredColumns = ['Name', 'Number', 'Details'];


  console.log(usersList)
    

    


    return (
      <div className="main-body-container">
     <IconSidebar />

         <div className="w-80 business-container">
            <div className="business-container-header">
                <h1 style={{margin:0}} className="bc-main-heading">Conversations</h1>
              
        </div>
            <div>

            <div className="hidden-scrollbar table-outer-container bg-[#444444] mt-5 p-4 overflow-x-auto">
  <table className="min-w-full bg-[#15161A] text-white rounded-lg shadow-lg">
    <thead>
      <tr style={{ color: 'white', fontSize: '16px' }}>
        <th className="w-1/9 py-3 px-4 text-left border-b border-gray-700">AID</th>
        <th className="w-1/9 py-3 px-4 text-left border-b border-gray-700">Company Name</th>
        <th className="w-1/9 py-3 px-4 text-left border-b border-gray-700">Assistant Name</th>
        <th className="w-1/9 py-3 px-4 text-left border-b border-gray-700">Date</th>
        <th className="w-1/9 py-3 px-4 text-left border-b border-gray-700">Duration</th>
        <th className="w-1/9 py-3 px-4 text-left border-b border-gray-700">Email</th>
        <th className="w-1/9 py-3 px-4 text-left border-b border-gray-700">Phone Number</th>
        <th className="w-1/9 py-3 px-4 text-left border-b border-gray-700">User ID</th>
        <th className="w-1/9 py-3 px-4 text-left border-b border-gray-700">Transcript</th>
        <th className="w-1/9 py-3 px-4 text-left border-b border-gray-700">Recording</th>
      </tr>
    </thead>
    <tbody>
      <tr className="hover:bg-gray-700">
        <td className="py-3 px-4 border-b border-gray-700">8ae2d8sg23def</td>
        <td className="py-3 px-4 border-b border-gray-700">HDFC</td>
        <td className="py-3 px-4 border-b border-gray-700">John</td>
        <td className="py-3 px-4 border-b border-gray-700">{new Date().toLocaleDateString('en-GB') + '\n' + new Date().toLocaleTimeString('en-GB')}</td>
        <td className="py-3 px-4 border-b border-gray-700">3:49</td>
        <td className="py-3 px-4 border-b border-gray-700">john@gmail.com</td>
        <td className="py-3 px-4 border-b border-gray-700">83838472847</td>
        <td className="py-3 px-4 border-b border-gray-700">8aedgbsid9f8</td>
        <Popup
          trigger={<td className="cursor-pointer py-3 px-4 border-b border-gray-700"><CgTranscript size={20} /></td>}
          modal
          nested
        >
          {close => (
            <div style={{ width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
              <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
                  <div className="flex justify-between items-center">
                    <div className="mb-3 flex items-center">
                      <h3 style={{ fontSize: '20px' }} className="font-bold mb-6">Transcript</h3>
                    </div>
                  </div>
                  <div>
                    <div className="assistant-chat">
                      <RiRobot3Fill size={20} />
                      <p className="mb-0 ml-2">Hello! How may I help you?</p>
                    </div>
                    <div className="user-chat">
                      <p className="mb-0 mr-2">Book an appointment</p>
                      <FaUser size={20} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Popup>
        <Popup
          trigger={<td className="py-3 px-4 border-b border-gray-700"><MdHeadphones size={30} /></td>}
          modal
          nested
        >
          {close => (
            <div style={{ width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
              <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h3 style={{ fontSize: '25px', marginBottom: '15px', color: 'yellow' }} className="font-bold">
                      Conversation
                    </h3>
                    <button type="button" onClick={() => close()}><IoMdClose /></button>
                  </div>
                  <div className="mt-2 mb-3 flex justify-between items-start">
                    <div className="text-center flex-1">
                      <h1 style={{ fontSize: '18px' }} className="assist-heading">{activeAssistant.name}</h1>
                      <p className="m-auto"><RiRobot3Fill style={{ color: 'aqua' }} className="m-auto" size={60} /></p>
                      <h1 style={{ fontSize: '20px' }} className="assist-heading mb-3 mt-2">AI Assistant</h1>
                      <h2 className="assist-heading">{activeAssistant.type}</h2>
                    </div>
                    <div className="text-center flex-1 relative">
                      <div className="flex justify-center items-center pulsing-ring">
                        <FiPhoneCall style={{ color: 'aqua' }} size={60} className="mx-auto text-white relative z-10" />
                      </div>
                      <p className="text-center">03:49 / 04:00</p>
                    </div>
                    <div className="text-center flex-1">
                      <h1 style={{ fontSize: '18px' }} className="assist-heading">{user.name}</h1>
                      <p className="m-auto"><FaUserCircle style={{ color: 'aqua' }} className="m-auto" size={60} /></p>
                      <h1 style={{ fontSize: '20px' }} className="assist-heading mb-3 mt-2">User</h1>
                      <h2 className="assist-heading">{user.mobileno}</h2>
                    </div>
                  </div>
                  <div style={{ height: '100px', backgroundColor: 'black', borderRadius: '5px', textAlign: 'center' }}>
                    <p className="p-3">Hello! How can I help you?</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Popup>
      </tr>
      {usersList.map((ele, index) => (
        <tr className="hover:bg-gray-700" key={index}>
          <td className="py-3 px-4 border-b border-gray-700">{index + 1}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.name}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.number}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.group}</td>
          <td className="py-3 px-4 border-b border-gray-700"></td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

            </div>
      </div>
       </div>
    )
}

export default AdminConversations;