import "./index.css"
import IconClientSidebar from "../../IconClientSidebar";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FaHistory } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { RiRobot3Fill } from "react-icons/ri";
import { FiPhoneCall } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";
import { CgTranscript } from "react-icons/cg";
import { MdHeadphones } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { FaUser } from "react-icons/fa";

const Credits = () => {
    const [customamount, setCustomAmount] = useState('');
    const [isChecked, setIsChecked] = useState(true);


    return (
         <div className="business-container hidden-scrollbar" style={{overflowY:'auto'}}>
            <div className="business-container-header">
                <h1 className="bc-main-heading">Credits</h1>
                <p style={{cursor:'pointer'}}><FaHistory size={30}/></p>
            </div>
            <div>
              
            <div className="flex justify-around items-center bg-[black] mt-3 p-4">
              <h1 className="credits-main-heading">Available Credits</h1>
              <h1 className="credit-count">8765</h1>
            </div>

            <div className="mt-3 mb-3 credits-card-container">
            <div style={{ width: '20%',flexGrow:1 }} className="template-card text-center">
            <h1 style={{ fontSize: '18px', color: 'yellow', width: '100%',display:'flex',justifyContent:'center' }} className="template-heading w-100">
              <span><FaRupeeSign/></span>1000
            </h1>
          <p>110+ minutes of call time.</p>
          <p>Priority queue. Reduced latency.</p>
          <button type="button" className="mt-5 addnew-button">Purchase</button>
        </div>
        <div style={{ width: '20%',flexGrow:1 }} className="template-card text-center">
            <h1 style={{ fontSize: '18px', color: 'yellow', width: '100%',display:'flex',justifyContent:'center' }} className="template-heading w-100">
              <span><FaRupeeSign/></span>5000
            </h1>
          <p>220+ minutes of call time.</p>
          <p>Priority queue. Reduced latency.</p>
          <button type="button" className="mt-5 addnew-button">Purchase</button>
        </div>
        <div style={{ width: '20%',flexGrow:1 }} className="template-card text-center">
            <h1 style={{ fontSize: '18px', color: 'yellow', width: '100%',display:'flex',justifyContent:'center' }} className="template-heading w-100">
              <span><FaRupeeSign/></span>10000
            </h1>
          <p>Almost 9 hours of call time.</p>
          <p>Priority queue. Reduced latency.</p>
          <button type="button" className="mt-5 addnew-button">Purchase</button>
        </div>
        <div style={{ width: '20%',flexGrow:1 }} className="template-card text-center">
            <h1 style={{ fontSize: '18px', color: 'yellow', width: '100%',display:'flex',justifyContent:'center' }} className="template-heading w-100">
              <span><FaRupeeSign/></span>20000
            </h1>
          <p>Almost 18 hours of call time.</p>
          <p>Priority queue. Reduced latency.</p>
          <button type="button" className="mt-5 addnew-button">Purchase</button>
        </div>
            </div>

            <div className="custom-amount-container">
              <label className="flex items-center" htmlFor="customamount">Custom Amount (<span><FaRupeeSign/></span>)</label>
              <div className="mb-3 flex items-center">
                <input style={{width:'60%'}} className="input-model" type="number" id="customamount" onChange={(e) => setCustomAmount(e.target.value)}/>
                <button style={{width:'500px',textAlign:'center'}} className="flex justify-center items-center ml-3 addnew-button" type="button">Purchase {customamount} credits (<FaRupeeSign/>{customamount})</button>
              </div>
              <input type="checkbox" id="savepaymentinfo" checked={isChecked} onChange={(e) => setIsChecked(!isChecked)}/>
              <label className="ml-3" htmlFor="savepaymentinfo">Save payment method after checkout</label>
            </div>
            </div>



       </div>
    )
}

export default Credits;