import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";


const RegRejected = () => {
    const navigate = useNavigate();
    const onLogOut = () => {
        googleLogout();
        Cookies.remove("userId");
        navigate("/",{replace:true});
    }
    const {email} = jwtDecode(Cookies.get("userId"));

    return (
        <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
      <div style={{fontWeight:'bold',textAlign:'center'}} className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
        <h1 style={{fontSize:'26px'}}>Registration Status</h1>
        <div className="flex justify-start">
      <img style={{objectFit:'contain'}} className="img-logo" src="https://res.cloudinary.com/haibegwvw/image/upload/v1721307308/file_krjkgp.png"/>
      <h1 style={{fontWeight:'bold',fontSize:'20px',marginBottom:'10px',textAlign:'center'}}>Your business application associated with this email {email} was rejected.</h1>
      </div>
        <button onClick={onLogOut} className="try-button">Log Out</button>

        </div>
        </div>
    )
}

export default RegRejected;