import "./index.css"
import { googleLogout } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { useState , useEffect } from "react";
import Popup from "reactjs-popup";
import { FaPhone } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import { IoEar, IoMail } from "react-icons/io5";
import IconClientSidebar from "../IconClientSidebar";

const Settings = () => {
    const navigate = useNavigate();
    const [isLoading,setIsLoading] = useState(false);
    const [businessesList,setBusinessList] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [filteredList, setFilteredList] = useState(businessesList);
    const onLogOut = () => {
      googleLogout();
      Cookies.remove("userId");
      navigate("/");
  }


    return (
        <div style={{width:'100%'}} className="app">
      <div className="main-body-container">
        <IconClientSidebar />
        <div className="business-container">
          <div className="flexi mb-5" style={{justifyContent:'space-between'}}>
          <button onClick={onLogOut} className="logout-button">Log Out</button>
          </div>
          </div>
          </div>
      </div>
    )
}

export default Settings;