import "./index.css"
import AdminHeader from "../../AdminHeader";
import AdminSidebar from "../../AdminSidebar";
import IconSidebar from "../../IconSidebar";

const Dashboard = () => {
    return (

        <div style={{width:'100%'}} className="app">
       {/* <AdminHeader /> */}
      <div className="main-body-container">
        <IconSidebar />
        <div className="business-container">
        <div className="no-data">
        <h1 style={{textAlign:'left',fontWeight:'bold',fontSize:'20px'}}>Performance (KPIs)</h1>
          <div className="no-data-icon-container">
            <img style={{margin:'auto'}} src="https://res.cloudinary.com/haibegwvw/image/upload/v1720683333/Screenshot_2024-07-11_at_1.04.53_PM_fhwiut.png" className="no-data-icon" alt="no-data"/>
          </div>
          <div className="no-data-subtext">
            There is no data available at this time.
          </div>
        </div>
      </div>
      </div>
      </div>
    )
}

export default Dashboard;