import "./index.css"
import IconClientSidebar from "../../../IconClientSidebar";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { IoMic, IoMicCircle } from "react-icons/io5";

const Report = () => {
    const [campaignsList, setCampaignsList] = useState([]);

    

    


    return (
         <div className="business-container">
            <div className="business-container-header">
                <h1 className="bc-main-heading">Report</h1>

            </div>
            <div>
            <div className="table-outer-container bg-[#444444] mt-5 p-4">
      <table className="min-w-full bg-[#15161A] text-white rounded-lg shadow-lg">
        <thead>
          <tr style={{ color: 'white', fontSize: '16px' }}>
            <th className="w-1/8 py-3 px-4 text-left border-b border-gray-700">S.No</th>
            <th className="w-1/8 py-3 px-4 text-left border-b border-gray-700">Campaign Name</th>
            <th className="w-1/8 py-3 px-4 text-left border-b border-gray-700">Campaign Time</th>
            <th className="w-1/8 py-3 px-4 text-left border-b border-gray-700">Assistant Name</th>
            <th className="w-1/8 py-3 px-4 text-left border-b border-gray-700">DID</th>
            <th className="w-1/8 py-3 px-4 text-left border-b border-gray-700">Total Users</th>
            <th className="w-1/8 py-3 px-4 text-left border-b border-gray-700">Status</th>
          </tr>
        </thead>
        <tbody>
        <tr className="hover:bg-gray-700">
              <td className="py-3 px-4 border-b border-gray-700">1</td>
              <td className="py-3 px-4 border-b border-gray-700">
                <Link to={`/campaigns/report/Demo`}>
                  Demo
                </Link>
              </td>
              <td className="py-3 px-4 border-b border-gray-700">{(new Date()).toLocaleDateString('en-GB')+"\n"+(new Date()).toLocaleTimeString('en-GB')}</td>
              <td className="py-3 px-4 border-b border-gray-700">
                <div className="flex justify-center items-center">
                <p>John</p>
                <p className="ml-3"><IoMicCircle size={30}/></p>
                </div>
            </td>
              <td className="py-3 px-4 border-b border-gray-700">7386892863</td>
              <td className="py-3 px-4 border-b border-gray-700">1</td>
              <td className="py-3 px-4 border-b border-gray-700">
                <span style={{color:'green'}}>1 </span>|<span style={{color:'red'}}> 0</span>
              </td>
            </tr>
          {campaignsList.map((ele, index) => (
            <tr key={ele.id} className="hover:bg-gray-700">
              <td className="py-3 px-4 border-b border-gray-700">{index + 1}</td>
              <td className="py-3 px-4 border-b border-gray-700">
                <Link to={`/campaigns/report/${ele.campaignName}`}>
                  {ele.campaignName}
                </Link>
              </td>
              <td className="py-3 px-4 border-b border-gray-700">{ele.campaignTime}</td>
              <td className="py-3 px-4 border-b border-gray-700">{ele.assistantName}</td>
              <td className="py-3 px-4 border-b border-gray-700">{ele.did}</td>
              <td className="py-3 px-4 border-b border-gray-700">{ele.totalUsers}</td>
              <td className="py-3 px-4 border-b border-gray-700">{ele.status}</td>
              <td className="py-3 px-4 border-b border-gray-700">{ele.outcome}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
            {/* <div className="table-outer-container bg-[#444444] mt-5 p-4">
  <table className="min-w-full bg-[#15161A] text-white rounded-lg shadow-lg">
    <thead>
      <tr style={{color:'white',fontSize:'16px'}}>
        <th className="w-1/12 py-3 px-4 text-left border-b border-gray-700">S.No</th>
        <th className="w-1/12 py-3 px-4 text-left border-b border-gray-700">Date</th>
        <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Campaign Name</th>
        <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Category</th>
        <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Status</th>
        <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Action</th>
      </tr>
    </thead>
    <tbody>
        {campaignsList.map((ele,index) => (
                  <tr className="hover:bg-gray-700">
                  <td className="py-3 px-4 border-b border-gray-700">{index+1}</td>
                  <td className="py-3 px-4 border-b border-gray-700">{ele.date}</td>
                  <td className="py-3 px-4 border-b border-gray-700">
                  <Link to={`/campaigns/campaign/${ele.campaignName}`}>
                    {ele.campaignName}
                  </Link>
                  </td>
                  <td className="py-3 px-4 border-b border-gray-700">{ele.campaignCategory}</td>
                  <td className="py-3 px-4 border-b border-gray-700">{ele.status}</td>
                  <td className="py-3 px-4 border-b border-gray-700">
                      <button className="bg-transparent mr-3" type="button"><FaEdit size={20}/></button>
                      <Popup
          trigger={<button className="bg-transparent mr-3" type="button"><MdDelete size={20}/></button>}
          modal
          nested
        >
          {close => (
            <div
              style={{
                width: '300px',
                height: '250px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#2d3748',
                color: 'white',
                borderRadius: '10px',
                textAlign: 'center',
              }}
            >
              <h1 style={{fontSize:'20px'}}>Are you sure you want to delete Campaign?</h1>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                <button
                  onClick={() => {
                    close()
                }}
                  className="addnew-button"
                  style={{ width: '60px', fontSize: '12px', marginRight: '5px' }}
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    close();
                  }}
                  className="addnew-button"
                  style={{
                    backgroundColor: 'red',
                    width: '60px',
                    fontSize: '12px',
                    color: 'white',
                    marginLeft: '5px',
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </Popup>

                  </td>
                </tr>
        ))}

    </tbody>
  </table>
</div> */}


                {/* <table className="">
                    <thead>
                        <tr>
                        <th>S.NO</th>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Details</th>
                        <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                    </tbody>
                </table> */}
            </div>

       </div>
    )
}

export default Report;