import React, { useEffect } from "react";
import { FaChartPie, FaVoicemail} from 'react-icons/fa';
import "./index.css";
import BusinessHeader from "../BusinessHeader";
import Sidebar from "../Sidebar";
import { FaPlus } from "react-icons/fa";
import { FaPhone } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaUser } from "react-icons/fa";
import {Popup} from 'reactjs-popup';
import BusinessItem from "../BusinessItem";

import { useState } from "react";
import AdminSidebar from "../AdminSidebar";
import IconSidebar from "../IconSidebar";

function BusinessContent() {
    const [formData, setFormData] = useState({
        businessName: '',
        ownerName: '',
        businessNumber: '',
        businessEmail: '',
        gstNumber: '',
        businessAddress: '',
        businessPincode: '',
        businessWebsite: '',
        youtubeChannel: '',
        turnOverRange: '',
        businessCategory:'',
        businessLogo: null,
        city:'',
        email:'',
        panNumber:'',
        regstatus : "approved"
      });

      const [imageData, setImageData] = useState('');
      const [avatar, setAvatar] = useState('');

      const [isLoading,setIsLoading] = useState(false);
      const [businessList, setBusinessList] = useState([]);

      const [statusFilter, setStatus] = useState('all');

      useEffect(() => {
        const getVideos = async () => {
          setIsLoading(true);
          try{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/businesses`);
            const data = await response.json();
            console.log(data);
            setBusinessList(data);
          }
          catch(Err){
            console.log(`Error Occurred : ${Err}`);
          }
        };
    
        getVideos();
      }, []);

      const onChangeStatus = (e) => {
        setStatus(e.target.value);
      }

      const handleAvatarChange = (event) => {
        const file = event.target.files[0];
        console.log(file);
    
        if (!file?.type.startsWith('image/')) {
          console.log("Please insert a valid image file.");
          return;
        }
    
        if(file?.size>=50*1024*1024){
            console.log("file is very large cannot upload (>50 mb)"); //here notify
            alert("file is very large cannot upload (>50 mb)")
            return;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const base64Data = reader.result.split(',')[1]; // Extract base64 data
          // Call a new function to send data to backend (explained below)
          setImageData(base64Data);
          // console.log(base64Data);
        };
      };

      const handleChange = (e) => {
        const { id, value, type, files } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [id]: type === 'file' ? files[0] : value,
        }));
      };

      const postData = async (obj) => {
        try{
          const options = {
            method : "POST",
            headers : {
              "Content-Type" : "application/json"
            },
            body : JSON.stringify(obj)
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/users`,options);
          const data = await response.json();
        }
        catch(Err){
          console.log(`Error Occurred : ${Err}`);
        }
      }

      const checkUser = async (email) => {
        try{
            const options = {
                method : "POST",
                headers : {
                    "Content-Type" : "application/json"
                },
                body : JSON.stringify({email})
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/checkuser`,options);
            const data = await response.json();
            if(data.exist===true)
                return true;
            else
            return false;
        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`);
        }
    }

      const handleSubmit = async (e) => {
        e.preventDefault();
        // Add your form submission logic here
        console.log('Form Data:', formData);
        const res = await checkUser(formData.businessEmail);
        if(res===true)
          alert("User already exists with the given email")
        else{
          if(imageData)
          formData.businessLogo = imageData;
        postData(formData);
        setBusinessList([formData,...businessList])
        }
      }
    let filteredData;
    if(statusFilter!=='all')
    filteredData = businessList.filter((ele) => ele.regstatus===statusFilter);
    else
    filteredData = businessList;

  return (
    <div style={{width:'100%'}} className="app">
        {/* <BusinessHeader/> */}
    <div className="main-body-container">
        <IconSidebar/>
      <div className="business-container">
      <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <h1 style={{fontWeight:'bold',color:'white',fontSize:'25px'}}>Business</h1>

            <Popup
        trigger={<button className="addnew-button">Add New +</button>}
        modal
        nested
      >
        {close => (
            <div style={{height:'600px',width:'600px',overflow:'auto'}} className="hidden-scrollbar">
    <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
    <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
      <h2 className="text-2xl font-bold mb-6">Business Registration</h2>
      <form onSubmit={(e) => {
        handleSubmit(e)
        close()
      }} className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="relative">
          <input
            type="text"
            id="businessName"
            value={formData.businessName}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Business Name"
            required
          />
          <label
            htmlFor="businessName"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business Name
          </label>
        </div>
        <div className="relative">
          <input
            type="text"
            id="ownerName"
            value={formData.ownerName}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Business Owner Name"
            required
          />
          <label
            htmlFor="ownerName"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business Owner Name
          </label>
        </div>
        <div className="relative">
          <input
            type="tel"
            id="businessNumber"
            value={formData.businessNumber}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Business Number"
            required
          />
          <label
            htmlFor="businessNumber"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business Number
          </label>
        </div>
        <div className="relative">
          <input
            type="email"
            id="businessEmail"
            value={formData.businessEmail}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Business Email"
            required
          />
          <label
            htmlFor="businessEmail"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business Email
          </label>
        </div>
        <div className="relative">
          <input
            type="text"
            id="gstNumber"
            value={formData.gstNumber}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Business GST Number"
            required
          />
          <label
            htmlFor="gstNumber"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business GST Number
          </label>
        </div>
        <div className="relative">
          <input
            type="text"
            id="panNumber"
            value={formData.panNumber}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Business PAN Number"
            required
          />
          <label
            htmlFor="panNumber"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business PAN Number
          </label>
        </div>
        <div className="relative col-span-2">
          <select
            id="businessCategory"
            value={formData.businessCategory}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300"
            required
          >
            <option value="" disabled>Select</option>
            <option value="E-commerce">E-commerce</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Education">Education</option>
            <option value="Finance & Banking">Finance & Banking</option>
            <option value="Real Estate">Real Estate</option>
            <option value="Technology & IT">Technology & IT</option>
            <option value="Retail">Retail</option>
            <option value="Hospitality & Tourism">Hospitality & Tourism</option>
            <option value="Media & Entertainment">Media & Entertainment</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Consulting & Professional Services">Consulting & Professional Services</option>
            <option value="Transportation & Logistics">Transportation & Logistics</option>
            <option value="Agriculture & Food">Agriculture & Food</option>
            <option value="Energy & Utilities">Energy & Utilities</option>
            <option value="Telecommunications">Telecommunications</option>
            <option value="Legal Services">Legal Services</option>
            <option value="Automotive">Automotive</option>
            <option value="Construction & Engineering">Construction & Engineering</option>
            <option value="Pharmaceuticals & Biotechnology">Pharmaceuticals & Biotechnology</option>
            <option value="Fashion & Apparel">Fashion & Apparel</option>
            <option value="Nonprofit & NGOs">Nonprofit & NGOs</option>
            <option value="Food & Beverage">Food & Beverage</option>
            <option value="Insurance">Insurance</option>
            <option value="Aerospace & Defense">Aerospace & Defense</option>
            <option value="Human Resources & Recruitment">Human Resources & Recruitment</option>
            <option value="Others">Others</option>
          </select>
          <label
            htmlFor="businessCategory"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business Category
          </label>
        </div>
        <div className="relative col-span-2">
          <input
            type="text"
            id="businessAddress"
            value={formData.businessAddress}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Business Address"
            required
          />
          <label
            htmlFor="businessAddress"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business Address
          </label>
        </div>
        <div className="relative">
          <input
            type="text"
            id="city"
            value={formData.city}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="City"
            required
          />
          <label
            htmlFor="city"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            City
          </label>
        </div>
        <div className="relative">
          <input
            type="number"
            id="businessPincode"
            value={formData.businessPincode}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Pincode"
            required
          />
          <label
            htmlFor="businessPincode"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Pin Code
          </label>
        </div>
        <div className="relative col-span-2">
          <label
            htmlFor="businessLogo"
            className="block text-gray-500 mb-2"
          >
            Business Logo
          </label>
          <input
            type="file"
            id="businessLogo"
            onChange={handleAvatarChange}
            className="w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        
        <div className="relative">
          <input
            type="url"
            id="businessWebsite"
            value={formData.businessWebsite}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Business Website"
            required
          />
          <label
            htmlFor="businessWebsite"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business Website
          </label>
        </div>
        <div className="relative">
          <input
            type="url"
            id="youtubeChannel"
            value={formData.youtubeChannel}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Business Youtube Channel"
            required
          />
          <label
            htmlFor="youtubeChannel"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business Youtube Channel
          </label>
        </div>
        <div className="relative col-span-2">
          <select
            id="turnOverRange"
            value={formData.turnOverRange}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300"
            required
          >
            <option value="" disabled>Select</option>
            <option value="0-50k">0-25L</option>
            <option value="25L-1CR">25L-1CR</option>
            <option value="1CR-10CR">1CR-10CR</option>
            <option value="10CR-100CR">10CR-100CR</option>
            <option value="100CR+">100CR+</option>
          </select>
          <label
            htmlFor="turnOverRange"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business Turn Over Range
          </label>
        </div>
        <button
          type="submit"
          className="col-span-2 bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
        >
          Register
        </button>
      </form>
    </div>
  </div>
  </div>
        )}
      </Popup>
        </div>
        <div className="filters-container">
            <div className="filter-item-container">
            <label htmlFor="status">Status</label>
            <select onChange={onChangeStatus} style={{marginTop:'10px'}} id="status">
                <option value="all">All</option>
                <option value="approved">Registered</option>
                <option value="pending">New</option>
                <option value="rejected">Rejected</option>
            </select>
            </div>
            <div className="filter-item-container">
                <label htmlFor="search">Search</label>
                <br/>
                <input id="search" className="filter-input" placeholder="Search for business" />
            </div>
            <div className="filter-item-container">
                <label htmlFor="sortby">Sort By</label>
                <select style={{marginTop:'10px'}} id="sortby">
                    <option>Top to Down</option>
                </select>
            </div>
        </div>
        <div className="businesses-list-outer-container">
        <ul className="businesseslist-container">
            {/* <li className="businesslist-item">
                <div style={{marginRight:'80px',display:'flex',justifyContent:'center',alignItems:'center'}}>
                <img className="img-logo" src="https://res.cloudinary.com/haibegwvw/image/upload/v1721307308/file_krjkgp.png"/>
                <div style={{marginLeft:'60px'}}>
                <h1 className="businesslist-itemheading">HDFC Bank</h1>
                <p className="joiningdate">Join on : 23/03/2024</p>
                </div>
                </div>
                <div>
                    <div className="flexi">
                    <FaUser className="business-icon"/>
                    <p>Ram</p>
                    </div>
                    <div className="flexi">
                    <FaAddressCard className="business-icon"/>
                    <p>Delhi</p>
                    </div>
                </div>
                <div>
                    <div className="flexi">
                    <MdEmail className="business-icon"/>
                <p> ram@gmail.com</p>
                </div>
                <div className="flexi">
                    <FaPhone className="business-icon"/>
                <p> 8284382913</p>
                </div>
                </div>
                <button className="addnew-button">Login</button>
            </li> */}
            {filteredData.map((ele,index) => (<BusinessItem key={index} itemDetails={ele} />))}
        </ul>
      </div>
      </div>
      </div>
      </div>
  );
}

export default BusinessContent;