import "./index.css"
import Popup from "reactjs-popup";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { GrConfigure } from "react-icons/gr";
import AdminHeader from "../../../AdminHeader";
import AdminSidebar from "../../../AdminSidebar";
import TemplateForm from "../TemplateForm";
import { FaE, FaGear } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaEye } from "react-icons/fa6";
import {v4 as uuid} from 'uuid';
import { IoEyeOff } from "react-icons/io5";
import IconSidebar from "../../../IconSidebar";
import { useEffect } from "react";

const typedescriptions = {
    "Counselor": "A helpful AI Assistant which can provide guidance, advice, and support on personal, academic, or career-related matters.",
    "Salesperson": "A helpful AI Assistant which can sell products or services by identifying customer needs and closing sales deals.",
    "Marketing Executive": "A helpful AI Assistant which can develop and implement strategies to promote products, increasing brand awareness.",
    "Appointment Setter": "A helpful AI Assistant which can schedule meetings or appointments with potential clients for sales representatives.",
    "Customer Support Executive": "A helpful AI Assistant which can assist customers with inquiries, complaints, and technical issues.",
    "Technical Support Executive": "A helpful AI Assistant which can provide troubleshooting and problem-solving assistance for technical issues.",
    "Reservation Booking Agent": "A helpful AI Assistant which can manage bookings and reservations for travel, accommodations, or events.",
    "Account Manager": "A helpful AI Assistant which can maintain client relationships, manage accounts, and ensure client satisfaction.",
    "Business Development Executive": "A helpful AI Assistant which can identify new business opportunities and drive company growth.",
    "Client Relationship Manager": "A helpful AI Assistant which can manage and nurture long-term relationships with clients.",
    "Customer Success Manager": "A helpful AI Assistant which can ensure customers achieve desired outcomes with products, driving retention.",
    "Product Manager": "A helpful AI Assistant which can oversee product development, ensuring alignment with market needs and goals.",
    "Operations Manager": "A helpful AI Assistant which can manage day-to-day operations, optimizing efficiency and productivity.",
    "HumanResources Executive": "A helpful AI Assistant which can handle recruitment, employee relations, and training for a positive workplace.",
    "Financial Analyst": "A helpful AI Assistant which can analyze financial data, provide insights, and support decision-making.",
    "Others" : 'others'
  }

const TemplateHome = () => {
    const [activeAssistants, setActiveAssistants] = useState([]);
    const [assistantName, setAssistantName] = useState('');
    const [domain,setDomain] = useState('');
    const [type, setType] = useState('');
    const [description,setDescription] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const [activeDomain, setActiveDomain] = useState('ALL');
    const [configure, setConfigure] = useState(false);
    const [configTemplate, setConfigTemplate] = useState('');
    const user = JSON.parse(localStorage.getItem("user"));
    const [message, setMessage] = useState('');
    const [messageStyle, setMessageStyle] = useState('white');


    const onChangeAssistantName = (oldname,newname) => {
      const newList = activeAssistants.map((ele) => {
        if(ele.assistantName===oldname)
          return {...ele,assistantName:newname}
        return ele;
      });
      setActiveAssistants(newList);
}


    useEffect(() => {
      const getItems = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/gettemplates`);
          const data = await response.json();
          if(data.templateArray)
          setActiveAssistants(data.templateArray);
          setFilteredList(data.templateArray);
        } catch (err) {
          console.error(`Error Occurred: ${err}`);
        }
      };

      getItems();
    }, []);


    const onClickBack = () => {
        setConfigure(false);
    }

    const domains = [...new Set(activeAssistants.map((ele) => ele.domain))].sort();

    const postData = async (obj) => {
      try{
        const options = {
          method : "POST",
          headers : {
            "Content-Type" : "application/json"
          },
          body:JSON.stringify({email:user.email,templateItem:obj})
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/addtemplate`,options)
        const data = await response.json();
        if (data.success) {
          // On success, set the success message
          setMessage('Template Inserted Successfully');
          setMessageStyle('green');
          
          // Automatically hide the message after 3 seconds
          setTimeout(() => {
            setMessage('');
          }, 3000);
          return true;
        } else {
          setMessageStyle('red');
          setMessage(data.message);
          setTimeout(() => {
            setMessage('');
          }, 3000);
        }
      }
      catch(Err){
        setMessageStyle('red');
        setMessage('An error occurred');
      setTimeout(() => {
        setMessage('');
      }, 3000);
        console.log(`Error Occurred : ${Err}`)
      }
    }

    const onRemoveTemplate = async (obj) => {
      console.log(obj);
        try {
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              email: user.email,
              templateItem: {
                id : obj  
              }
            })
          };
      
          const response = await fetch(`${process.env.REACT_APP_API_URL}/deletetemplate`, options);
          
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const data = await response.json();
          console.log("I am from Delete Data");
      
          if (data.success) {
            setMessage(data.message);
            setMessageStyle('green');
            setTimeout(() => {
              setMessage('');
            }, 3000);
            setActiveAssistants(activeAssistants.filter((ele) => ele.id!==obj)); 
          } else {
            setMessageStyle('red');
            setMessage(data.message);
            setTimeout(() => {
              setMessage('');
            }, 3000);
          }
        } catch (Err) {
          setMessageStyle('red');
          setMessage('An error occurred');
          setTimeout(() => {
            setMessage('');
          }, 3000);
          console.error(`Error Occurred: ${Err}`);
        }
    }

    

    const onCreateTemplate = async (e) => {
        const templateData = {
            assistantName,
            domain,
            type,
            description,
            visibility:"private",
            configure : {},
            isOpen:false,
            id:uuid()
        }
        const res = await postData(templateData);
        if(res){
        const newList = [templateData,...activeAssistants];
        setActiveAssistants(newList);
        setFilteredList(newList);
        }
    }

    const onClickDomain = (value) => {
        setActiveDomain(value);
        if(value==="ALL")
            setFilteredList(activeAssistants);
        else{
        const newList = activeAssistants.filter((ele) => ele.domain===value);
        setFilteredList(newList);
        }
    }

    const onChangeField = (newList) => {
      if(activeDomain==="ALL")
        setFilteredList(newList);
    else{
    const newList2 = newList.filter((ele) => ele.domain===activeDomain);
    setFilteredList(newList2);
    }
    }

    const onToggleDropDown = (elem) => {
      const newList = activeAssistants.map((ele) => {
        if(ele.id===elem.id){
          return {...ele,isOpen:!elem.isOpen}
        }
        return ele;
      })
      setActiveAssistants(newList);
      onChangeField(newList);
    }

    const onChangeVisibility = (elem,status) => {
      const newList = activeAssistants.map((ele) => {
        if(ele.id===elem.id){
          return {...ele,visibility:status}
        }
        return ele;
      })
      console.log(newList);
      setActiveAssistants(newList);
      onChangeField(newList);
    }

    



    return (
        <div style={{width:'100%'}} className="app">
      <div className="main-body-container">
      {message && <div style={{backgroundColor:`${messageStyle==='green'?'green':'red'}`}} className="notification">{message}</div>}
        <IconSidebar />
        
            {configure===false && (
              // <div style={{width:'100%'}}>
              // <h1 style={{margin:'10px',textAlign:'left',fontWeight:'bold',fontSize:'20px'}}>Assistant Template</h1>
                <div className="assistants-container">
        <div style={{width:'300px'}} className="create-assistant-container">
          <div className='createbutton-container'>
            <Popup
        trigger={<button className="button-assistant hover">
        Create Assistant Template +
      </button>}
        modal
        nested
      >
        {close => (
                  <div className="modal-assistant">
                  <div className="modal-assistant-body">
                    <div className='chosse-a-template-container'>
                      <p className='choose-heading'>Create Assistant Template</p>
                    </div>
                    <div className="input-group">
                              <label className='label-peroson-element'>Template Name</label>
                              <input onChange={(e) => setAssistantName(e.target.value)} style={{width:'100%'}} className='input-model' type="text" placeholder="Give your Template Name..." />
                  </div>
                      <div className="input-group">
                          <label htmlFor="domain" className='model-label'>Domain</label>
                          <select onChange={(e) => setDomain(e.target.value)} id="domain">
                          <option value="">Select Domain</option>
                          <option value="Education">Education</option>
                          <option value="Insurance">Insurance</option>
                          <option value="Healthcare">Healthcare</option>
                          <option value="Real Estate">Real Estate</option>
                          <option value="Hospitality">Hospitality</option>
                          <option value="E-commerce">E-Commerce</option>
                          <option value="Technology">Technology</option>
                          <option value="Finance">Finance</option>
                          <option value="Manufacturing">Manufacturing</option>
                          <option value="Telecommunications">Telecommunications</option>
                          <option value="Political Campaign">Political Campaign</option>
                          <option value="Others">Others</option>
                          </select>
                      </div>
                      <div className="input-group">
                          <label htmlFor="type" className='model-label'>Type</label>
                          <select onChange={(e) => {
                            setType(e.target.value)
                            setDescription(typedescriptions[e.target.value])
                        }} id="type">
                              <option value="">Select Type</option>
                              <option value="Counselor">Counselor</option>
                              <option value="Salesperson">Salesperson</option>
                              <option value="Marketing Executive">Marketing Executive</option>
                              <option value="Appointment Setter">Appointment Setter</option>
                              <option value="Customer Support Executive">Customer Support Executive</option>
                              <option value="Technical Support Executive">Technical Support Executive</option>
                              <option value="Reservation Booking Agent">Reservation Booking Agent</option>
                              <option value="Account Manager">Account Manager</option>
                              <option value="Business Development Executive">Business Development Executive</option>
                              <option value="Client Relationship Manager">Client Relationship Manager</option>
                              <option value="Outreach Executive">Outreach Executive</option>
                              <option value="Survey Executive">Survey Executive</option>
                              <option value="Others">Others</option>
                          </select>
                      </div>
                      <div className="input-group">
                          <label className='model-label'>Description</label>
                          {/* {typedescriptions[type]!=="others" && (<p style={{color:'white'}}>{typedescriptions[type]}</p>)} */}
                          {typedescriptions[type]!=="others" && (
                            <>
                            <br/>
                           <textarea style={{backgroundColor:'black',color:'white',width:'100%',padding:'5px'}} placeholder={typedescriptions[type]}>
                           </textarea>
                           </>
                          )}
                          {typedescriptions[type]==="others" && (
                            <>
                            <br/>
                           <textarea style={{backgroundColor:'black',color:'white',width:'100%',padding:'5px'}} placeholder="Enter the description">
                           </textarea>
                           </>
                          )}
                      </div>
                    <div className='button-container'>
                        <button onClick={(e) => {
                            onCreateTemplate(e)
                            close()
                        }} type="button" className='create-ass-button'>Create Template
                        </button>
                    </div>
                  </div>
                </div>
        )}
      </Popup>
      </div>
      <div className="domain-container">
  {domains.length !== 0 ? (
    <ul className="domain-container">
      <li
        className={`${activeDomain === "ALL" ? 'active' : ''} domain-item`}
        style={{ cursor: 'pointer' }}
        onClick={() => onClickDomain("ALL")}
      >
        ALL
      </li>
      {domains.map((ele, index) => (
        <li
          key={index}
          style={{ cursor: 'pointer' }}
          onClick={() => onClickDomain(ele)}
          className={`${activeDomain === ele ? 'active' : ''} domain-item`}
        >
          {ele}
        </li>
      ))}
    </ul>
  ) : (
    <></>
  )}
</div>

          {/* {activeAssistants.map(each => (
            <div className='newAssistant-container'>
              <div style={{display:'flex',justifyContent:'space-between'}}>
                <div>
              <h1 className='assistant-name'>{each.name}</h1>
              <p className='copy-link'>{each.id}</p>
              </div>
              <Popup
          trigger={<button style={{marginLeft:'30px'}} className=" text-gray-600 hover:text-gray-900 focus:outline-none"><MdDelete size={20}  /></button>}
          modal
          nested
        >
          {close => (
            <div style={{width:'300px',height:'150px',padding:'20px'}} className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg">
              <div className="flex flex-col items-center mt-4">
                <h1>Are you sure want to delete Assistant?</h1>
                <div style={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                <button onClick={() => close()} className='addnew-button mr-3' style={{width:'60px',fontSize:'12px'}}>Close</button>
                <button className='addnew-button mr-3' style={{backgroundColor:'red',width:'60px',fontSize:'12px'}}>Delete</button>
                </div>
              </div>
            </div>
          )}
        </Popup>
              </div>
          </div>
          ))} */}
        </div>
        <div className='side-container'>
          {activeAssistants.length === 0 ? (
            <div className="no-assistants">
              <h2>No Assistants Found</h2>
              <p>Create a new assistant to get started with your first conversation.</p>
            </div>) :( 
                <>
                <div className='chosse-a-template-container'>
                      <p className='choose-heading p-5' style={{fontSize:'25px'}}>{activeDomain} Templates</p>
                    </div>
                <div className="template-container">
                {filteredList.map((ele,index) => (
                    <div key={index+1} className={`template-card ${ele.visibility==="private"?'disable-text':''}`}>
                    <div className="template-heading-container">
                    <h1 className="template-heading">{ele.assistantName}</h1>
                    <div>
                    <button onClick={() => {
                        setConfigure(true);
                        setConfigTemplate(ele);
                    }}  className=" text-gray-600 hover:text-gray-900 focus:outline-none"><FaGear size={20}  /></button>
                    {ele.visibility==="public" && (<button onClick={() => {
                    onChangeVisibility(ele,"private")
                    }} style={{marginLeft:'10px'}}  className=" text-gray-600 hover:text-gray-900 focus:outline-none"><FaEye size={20}  /></button>)}
                    {ele.visibility==="private" && (<button onClick={() => {
                      onChangeVisibility(ele,"public")
                    }} style={{marginLeft:'10px'}}  className=" text-gray-600 hover:text-gray-900 focus:outline-none"><IoEyeOff size={20}  /></button>)}
                    <div className="relative inline-block text-left">
      <button
        style={{ backgroundColor: 'transparent', marginLeft: '10px' }}
        className="text-gray-600 hover:text-gray-900 focus:outline-none"
        onClick={() => onToggleDropDown(ele)}
      >
        <BsThreeDotsVertical size={20} />
      </button>

      {ele.isOpen && (
        <div
          className="absolute right-0 mt-2 w-40 origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
        >
          <div className="py-1">
            <Popup trigger={
              <button className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-red-600 w-full text-left" >
                        Delete
                      </button>}
                    modal
                    nested
                >
                    {close => (
                    <div style={{width:'300px',height:'150px',padding:'20px'}} className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg">
                        <div className="flex flex-col items-center mt-4">
                        <h1 style={{fontSize:'20px'}}>Are you sure want to delete Template?</h1>
                        <div style={{display:'flex',justifyContent:'space-evenly',marginTop:'10px'}}>
                        <button onClick={() => close()} className='addnew-button mr-3' style={{width:'60px',fontSize:'12px'}}>Close</button>
                        <button onClick={() => {
                          onRemoveTemplate(ele.id)
                          close()
                        }} className='addnew-button mr-3' style={{backgroundColor:'red',width:'60px',fontSize:'12px'}}>Delete</button>
                        </div>
                        </div>
                    </div>
                    )}
                </Popup>
          </div>
        </div>
      )}
    </div>

                </div>
                    </div>
                    <p><span className="template-details-span-element">Domain : </span>{ele.domain}</p>
                    <p><span className="template-details-span-element">Type : </span>{ele.type}</p>
                    <p><span className="template-details-span-element">Description :</span>{ele.description}</p>
                </div>
                )
            )}
            </div>
            </>
          ) }
        </div>
        </div>
        // </div>
            )}
        {configure===true && (
            <TemplateForm onChangeAssistantName={onChangeAssistantName} onClickBack={onClickBack} templateDetails={configTemplate}/>
        )}
      </div>
      </div>

    )
}

export default TemplateHome;