import React from 'react';
import './footersection.css'; // Assuming you have a CSS file for styling
// import TradeMark from '../../assets/img10.png'
import Popup from 'reactjs-popup';
import Cookies from 'js-cookie';
import { GoogleOAuthProvider,GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import {useUser} from '../../Context/userContext'
import CryptoJS from 'crypto-js';



const FooterSection = () => {
    const navigate = useNavigate();
    const { loginUser } = useUser();

    const checkUser = async (email) => {
        try{
            const options = {
                method : "POST",
                headers : {
                    "Content-Type" : "application/json"
                },
                body : JSON.stringify({email})
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/checkuser`,options);
            const data = await response.json();
            console.log(data);
            if(data.exist===true && data.regstatus==="approved"){
                return true;
            } 
            else if(data.exist===true && data.regstatus==="pending"){
                return "pending";
            }
            else if(data.exist===true && data.regstatus==="rejected"){
                return "rejected";
            }
            else
            return false;
        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`);
        }
    }
    
    
    const checkAdmin = async (email) => {
        try{
            const options = {
                method : "POST",
                headers : {
                    "Content-Type" : "application/json"
                },
                body : JSON.stringify({email})
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/checkadmin`,options);
            const data = await response.json();
            console.log(data);
            
            if(data.exist===true){
                loginUser(data.user);
                    const encryptedData = CryptoJS.AES.encrypt(JSON.stringify([
                        "Dashboard",
                        "Business",
                        "System",
                        "Tickets",
                        "Feedback",
                        "Collections",
                        "Admin",
                        "Conversations"
                    ]), process.env.REACT_APP_SECRET_KEY).toString();
                      localStorage.setItem(process.env.REACT_APP_ENCRYPTED_KEY, encryptedData);
                    return true;
            }
            else
            return false;
        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`);
        }
    }
    
    const checkSubAdmin = async (email) => {
        try{
            const options = {
                method : "POST",
                headers : {
                    "Content-Type" : "application/json"
                },
                body : JSON.stringify({email})
            }
            const response = await fetch(`${process.env.REACT_APP_API_URL}/checksubadmin`,options);
            const data = await response.json();
            console.log(data);
            if(data.exist===true){
                loginUser(data.user);
                const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data.user.accessItems), process.env.REACT_APP_SECRET_KEY).toString();
                  localStorage.setItem(process.env.REACT_APP_ENCRYPTED_KEY, encryptedData);
                return true;
            }
            else
            return false;
        }
        catch(Err){
            console.log(`Error Occurred : ${Err}`);
        }
    }

  return (
    <div style={{marginTop:"20px",backgroundColor:'black'}} className='landing-footer'>
                <div className='footer-items'>
                <div className="footer-col">
                <h3>Aitota</h3>
                <div className="footer-col-item">
                    <p><a href="/about">About</a></p>
                </div>
            </div>
            <div className="footer-col">
                <h3>Office</h3>
                <div className="footer-col-item">
                    <p><a href="">Head Office</a></p>
                    <p className="mt-2 mb-2">804, 5th Cross, 4th Block,</p>
                    <p className="mt-2 mb-2">Koramangala, Bengaluru-560095</p>
                    <p className='mt-2 mb-2'>contact@aitota.com</p>
                    
                </div>
            </div>
            <div className="footer-col">
                <h3>Quick Links</h3>
                <div className="footer-col-item">
                    <p><a href="https://aitota.blogspot.com/2024/08/aitota.html">Blog</a></p>
                    {/* <p><a href="/admin">Admin</a></p> */}
                    {/*<button className="bg-blue-500 text-white p-3 rounded-md hover:bg-blue-600 transition-colors duration-300" type="button">Admin</button>  */}
      <Popup
        trigger={<p>Admin</p>}
        modal
        nested
      >
        {close => (
          <div style={{width:'300px',height:'150px'}} className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg">
            <div className="flex flex-col items-center mt-4">

              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <GoogleLogin
                  onSuccess={async (credentialResponse) => {
                    Cookies.set("userId",credentialResponse.credential);
                    const {email} = jwtDecode(credentialResponse.credential);
                    const res1 = await checkAdmin(email);
                    const res2 = await checkSubAdmin(email);
                    if(res1===true || res2===true)
                    navigate("/dashboard",{replace:true});
                    else
                    alert("You are not authorized to access the content");
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                />
              </GoogleOAuthProvider>
              
            </div>
          </div>
        )}
      </Popup>
                    
                    <p><a href="">Careers</a></p>
                </div>
            </div>
            <div class="footer-col">
                <h3>Legal Stuff</h3>
                <div className="footer-col-item">
                    <p><a href="/privacy-policy">Privacy Policy</a></p>
                    <p><a href="/terms-conditions">Terms of Service</a></p>
                    <p><a href="/refunds">Refunds</a></p>
                    <p><a href="/disclaimer">Disclaimer</a></p>
                </div>
            </div>
                </div>
                <div className='footer-bottom'>
                <p className="copyright">
                    Copyright © 2024 aitota. All Rights Reserved. 
                </p>
                </div>
                
            </div>
  );
};

export default FooterSection;