import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode'
import Cookies from 'js-cookie'
import axios from 'axios';
import {v4 as uuidv4} from 'uuid';

const RegistrationForm = () => {
  // State for form inputs
  const [imageData,setImageData]=useState("");
  const navigate = useNavigate();
  const token = Cookies.get("userId");
  const {email,name,picture} = jwtDecode(token);
  const [formData, setFormData] = useState({
    businessName: '',
    ownerName: '',
    businessNumber: '',
    businessEmail: email,
    gstNumber: '',
    businessAddress: '',
    businessPincode: '',
    businessWebsite: '',
    youtubeChannel: '',
    turnOverRange: '',
    businessCategory:'',
    businessLogo: null,
    city:'',
    panNumber:'',
    regstatus : "pending",
    mobileNumber:''
  });
  const [fileUrl, setFileUrl] = useState('');
  const [isUploading,setIsUploading] = useState(false);


  const postData = async (obj) => {
    try{
      const options = {
        method : "POST",
        headers : {
          "Content-Type" : "application/json"
        },
        body : JSON.stringify({...obj,name,picture})
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users`,options);
      const data = await response.json();
      if(data.success)
        return true;
    }
    catch(Err){
      console.log(`Error Occurred : ${Err}`);
    }
  }

  // Handle input changes
  const handleChange = (e) => {
    const { id, value, type, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: type === 'file' ? files[0] : value,
    }));
  };

  const handleAvatarChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return; // Ensure a file is selected

    try {
      // Set uploading state
      console.log(file);
      setIsUploading(true);

      // Check if the file size is less than 10MB (single upload)
      if (file.size < 10000000) {
        // Call the API to get the presigned URL for single file upload
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/generate-single-presigned-url`,
          {
            fileName: `${uuidv4}-${file.name}`,
          }
        );
        const { url } = response.data;

        // Upload file using presigned URL
        const uploadResponse = await axios.put(url, file, {
          headers: {
            "Content-Type": file.type,
          },
        });

        // Check upload response
        if (uploadResponse.status === 200) {
          alert("File uploaded successfully.");
          // Set the file URL (this is the URL where the file was uploaded)
          setFileUrl(url.split("?")[0]);
        } else {
          alert("Upload failed.");
        }
        setIsUploading(false); // Reset uploading state
      } else {
        // Multipart upload for large files (more than 10MB)
        const uniqueFilename = `${uuidv4()}-${file.name}`;
        const startUploadResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/start-multipart-upload`,
          {
            fileName: uniqueFilename,
            contentType: file.type,
          }
        );

        const { uploadId } = startUploadResponse.data;

        // Chunk file into 10MB parts
        const chunkSize = 10000000; // 10MB
        const numChunks = Math.ceil(file.size / chunkSize);
        const totalSize = file.size;

        // Request presigned URLs for each part
        const presignedUrlsResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/generate-presigned-url`,
          {
            fileName: uniqueFilename,
            uploadId,
            partNumbers: numChunks,
          }
        );

        const presignedUrls = presignedUrlsResponse.data.presignedUrls;

        const parts = [];
        const uploadPromises = [];

        // Upload each chunk separately
        for (let i = 0; i < numChunks; i++) {
          const start = i * chunkSize;
          const end = Math.min(start + chunkSize, totalSize);
          const chunk = file.slice(start, end);

          const presignedUrl = presignedUrls[i];

          uploadPromises.push(
            axios.put(presignedUrl, chunk, {
              headers: {
                "Content-Type": file.type,
              },
            })
          );
        }

        // Wait for all chunks to upload
        const uploadResponses = await Promise.all(uploadPromises);

        uploadResponses.forEach((response, i) => {
          parts.push({
            etag: response.headers.etag,
            PartNumber: i + 1,
          });
        });

        // Complete multipart upload
        const completeUploadResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/complete-multipart-upload`,
          {
            fileName: uniqueFilename,
            uploadId,
            parts,
          }
        );

        if (completeUploadResponse.status === 200) {
          alert("File uploaded successfully.");
          // Set the file URL (construct the S3 object URL)
          console.log(presignedUrls[0].split("?")[0])
          setFileUrl(
            presignedUrls[0].split("?")[0]
          );
        } else {
          alert("Upload failed.");
        }


        setIsUploading(false);
      }
    } catch (error) {
      console.error("Upload error:", error);
      alert("Upload failed.");
      setIsUploading(false); // Reset uploading state
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log('Form Data:', formData);
    if(fileUrl){
    formData.businessLogo = fileUrl;
    const res = await postData(formData);
    if(res)
    navigate("/regpending",{replace:true});
    }
    else
    alert("Image is uploading...")

    // Example: You could send this data to a server here
    // fetch('/api/submit', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(formData),
    // })
    // .then(response => response.json())
    // .then(data => console.log('Success:', data))
    // .catch(error => console.error('Error:', error));
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
      <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
        <h2 className="text-2xl font-bold mb-6">Business Registration</h2>
        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="relative">
            <input
              type="text"
              id="businessName"
              value={formData.businessName}
              onChange={handleChange}
              className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
              placeholder="Business Name"
              required
            />
            <label
              htmlFor="businessName"
              className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
            >
              Business Name
            </label>
          </div>
          <div className="relative">
            <input
              type="text"
              id="ownerName"
              value={formData.ownerName}
              onChange={handleChange}
              className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
              placeholder="Business Owner Name"
              required
            />
            <label
              htmlFor="ownerName"
              className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
            >
              Business Owner Name
            </label>
          </div>
          <div className="relative">
            <input
              type="tel"
              id="businessNumber"
              value={formData.businessNumber}
              onChange={handleChange}
              className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
              placeholder="Business Number"
              required
            />
            <label
              htmlFor="businessNumber"
              className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
            >
              Business Number
            </label>
          </div>
          <div className="relative">
            <input
              type="email"
              id="businessEmail"
              value={email}
              className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
              placeholder="Business Email"
              readOnly={true}
            />
            <label
              htmlFor="businessEmail"
              className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
            >
              Business Email
            </label>
          </div>
          <div className="relative">
            <input
              type="text"
              id="gstNumber"
              value={formData.gstNumber}
              onChange={handleChange}
              className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
              placeholder="Business GST Number"
            />
            <label
              htmlFor="gstNumber"
              className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
            >
              Business GST Number
            </label>
          </div>
          <div className="relative">
          <input
            type="text"
            id="panNumber"
            value={formData.panNumber}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
            placeholder="Business PAN Number"
          />
          <label
            htmlFor="panNumber"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business PAN Number
          </label>
        </div>
        <div className="relative">
            <input
              type="text"
              id="mobileNumber"
              value={formData.mobileNumber}
              onChange={handleChange}
              className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
              placeholder="Business Mobile Number"
            />
            <label
              htmlFor="mobileNumber"
              className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
            >
              Business Mobile Number
            </label>
          </div>
          <div className="relative col-span-2">
          <select
            id="businessCategory"
            value={formData.businessCategory}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300"
            required
          >
            <option value="" disabled>Select</option>
            <option value="E-commerce">E-commerce</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Education">Education</option>
            <option value="Finance & Banking">Finance & Banking</option>
            <option value="Real Estate">Real Estate</option>
            <option value="Technology & IT">Technology & IT</option>
            <option value="Retail">Retail</option>
            <option value="Hospitality & Tourism">Hospitality & Tourism</option>
            <option value="Media & Entertainment">Media & Entertainment</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Consulting & Professional Services">Consulting & Professional Services</option>
            <option value="Transportation & Logistics">Transportation & Logistics</option>
            <option value="Agriculture & Food">Agriculture & Food</option>
            <option value="Energy & Utilities">Energy & Utilities</option>
            <option value="Telecommunications">Telecommunications</option>
            <option value="Legal Services">Legal Services</option>
            <option value="Automotive">Automotive</option>
            <option value="Construction & Engineering">Construction & Engineering</option>
            <option value="Pharmaceuticals & Biotechnology">Pharmaceuticals & Biotechnology</option>
            <option value="Fashion & Apparel">Fashion & Apparel</option>
            <option value="Nonprofit & NGOs">Nonprofit & NGOs</option>
            <option value="Food & Beverage">Food & Beverage</option>
            <option value="Insurance">Insurance</option>
            <option value="Aerospace & Defense">Aerospace & Defense</option>
            <option value="Human Resources & Recruitment">Human Resources & Recruitment</option>
            <option value="Others">Others</option>
          </select>
          <label
            htmlFor="businessCategory"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Business Category
          </label>
        </div>
          <div className="relative col-span-2">
            <input
              type="text"
              id="businessAddress"
              value={formData.businessAddress}
              onChange={handleChange}
              className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
              placeholder="Business Address"
              required
            />
            <label
              htmlFor="businessAddress"
              className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
            >
              Business Address
            </label>
          </div>
          <div className="relative">
            <input
              type="text"
              id="city"
              value={formData.city}
              onChange={handleChange}
              className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
              placeholder="City"
              required
            />
            <label
              htmlFor="city"
              className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
            >
              City
            </label>
          </div>
          <div className="relative">
            <input
              type="text"
              id="businessPincode"
              value={formData.businessPincode}
              onChange={handleChange}
              className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
              placeholder="Business Pincode"
              required
            />
            <label
              htmlFor="businessPincode"
              className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
            >
              Pin Code
            </label>
          </div>
          <div className="relative col-span-2">
            <label
              htmlFor="businessLogo"
              className="block text-gray-500 mb-2"
            >
              Business Logo
            </label>
            <input
              type="file"
              id="businessLogo"
              onChange={handleAvatarChange}
              className="w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          
          <div className="relative">
            <input
              type="url"
              id="businessWebsite"
              value={formData.businessWebsite}
              onChange={handleChange}
              className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
              placeholder="Business Website"
              required
            />
            <label
              htmlFor="businessWebsite"
              className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
            >
              Business Website
            </label>
          </div>
          <div className="relative">
            <input
              type="url"
              id="youtubeChannel"
              value={formData.youtubeChannel}
              onChange={handleChange}
              className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
              placeholder="Business Youtube Channel"
            />
            <label
              htmlFor="youtubeChannel"
              className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
            >
              Business Youtube Channel
            </label>
          </div>
          <div className="relative col-span-2">
            <select
              id="turnOverRange"
              value={formData.turnOverRange}
              onChange={handleChange}
              className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300"
              required
            >
              <option value="" disabled>Select</option>
            <option value="0-50k">0-25L</option>
            <option value="25L-1CR">25L-1CR</option>
            <option value="1CR-10CR">1CR-10CR</option>
            <option value="10CR-100CR">10CR-100CR</option>
            <option value="100CR+">100CR+</option>
            </select>
            <label
              htmlFor="turnOverRange"
              className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
            >
              Business Turn Over Range
            </label>
          </div>
          <button
            type="submit"
            className="col-span-2 bg-blue-500 text-white p-3 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
          >
            Register
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegistrationForm;
