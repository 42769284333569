import React, { useState } from 'react';
import './index.css';

const voices = [
  { 
    name: 'Aaditya', 
    gender: 'Male', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: true 
  },
  { 
    name: 'Aditi', 
    gender: 'Female', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Aarav', 
    gender: 'Male', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Ananya', 
    gender: 'Female', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Anaya', 
    gender: 'Female', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Aria', 
    gender: 'Female', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Dhruv', 
    gender: 'Male', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Ishaan', 
    gender: 'Male', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Kartik', 
    gender: 'Male', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Kavya', 
    gender: 'Female', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Lakshmi', 
    gender: 'Female', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Maya', 
    gender: 'Female', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Meera', 
    gender: 'Female', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Nisha', 
    gender: 'Female', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Priya', 
    gender: 'Female', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Radhika', 
    gender: 'Female', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Rishi', 
    gender: 'Male', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Sanya', 
    gender: 'Female', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Siddharth', 
    gender: 'Male', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Tarun', 
    gender: 'Male', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Vijay', 
    gender: 'Male', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Vivaan', 
    gender: 'Male', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  },
  { 
    name: 'Yash', 
    gender: 'Male', 
    type: 'Conversational', 
    flag: 'https://www.worldometers.info//img/flags/small/tn_in-flag.gif', 
    isSelected: false 
  }
];

const Voices = () => {
  const [selectedVoice, setSelectedVoice] = useState(voices.find(voice => voice.isSelected));

  return (
    <div className="pop-up-contianer">
      <h2>Voices</h2>
      <p>This section allows you to configure the voice settings for the assistant.</p>
      <div className="settings-group">
        <div className="setting">
          <label htmlFor="provider">Domain</label>
          <select id="provider" name="provider">
            <option value="">Select Domain</option>
            <option value="deepgram">deepgram</option>
            <option value="deepgram">talkscriber</option>
            <option value="deepgram">gladia</option>
          </select>
        </div>
        <div className="setting">
          <label htmlFor="type">Type</label>
          <select id="type" name="type">
            <option value="">Select Type</option>
            <option value="Personal Assistant">Personal Assistant</option>
            <option value="Customer Service">Customer Service</option>
            <option value="Counselor">Counselor</option>
            <option value="Technical Support">Technical Support</option>
            <option value="Salesperson">Salesperson</option>
            <option value="Marketer">Marketer</option>
            <option value="General">General</option>
          </select>
        </div>
        <div className="setting">
          <label htmlFor="language">Gender</label>
          <select id="language" name="language">
            <option value="">Select Gender</option>
            <option value="en">Male</option>
            <option value="en">Female</option>
          </select>
        </div>
        <div className="setting">
          <label htmlFor="language">Language</label>
          <select id="language" name="language">
            <option value="">Select Language</option>
            <option value="en">English</option>
            <option value="en">Hindi</option>
            <option value="en">Telugu</option>
            <option value="en">Multi Language</option>
            <option value="en">en-AU</option>
            <option value="en">en-AB</option>
            {/* <option value="en">it</option>
            <option value="en">fa</option>
            <option value="en">en-En</option> */}
          </select>
        </div>
      </div>
      <div className="voices-container">
        <h2 className="title">Choose Agent Voice</h2>
        <ul className="voice-list">
          {voices.map((voice, index) => (
            <li key={index} className={`voice-item ${selectedVoice.name === voice.name ? 'selected' : ''}`}>
              <img src={voice.flag} alt={voice.name} className="flag-icon" />
              <div className="voice-info">
                <div className="voice-name">{voice.name}</div>
                <div className="voice-meta">{voice.gender} • {voice.type}</div>
              </div>
              <button className="play-button">▶️</button>
              <button className="use-voice-button" onClick={() => setSelectedVoice(voice)}>Use Voice</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Voices;