import "./index.css"
import IconClientSidebar from "../../../IconClientSidebar";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import { useRef } from "react";
import { IoMicCircle } from "react-icons/io5";
import { FaMicrophoneLines } from "react-icons/fa6";
import {v4 as uuidv4} from 'uuid';
import { HiSpeakerWave } from "react-icons/hi2";
import { IoMdClose } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { AiFillRobot } from "react-icons/ai";
import { MdOutlineAddIcCall } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { RiRobot3Fill } from "react-icons/ri";
import { useEffect } from "react";

const assistantArray = ['Vijay Kumar Singh','John']
const statusOptions = ["Not started",
    "In queue",
    "Ongoing",
    "Completed", 
    "Not Connected" ,
    "Invalid" ,
    "Not reachable", 
    "Incoming Bard"  ,
    'Error']

const CampaignDetail = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        user : '',
        group:'',
        status:'Not Started',
        isSelected : false
    });
    const [micOn, setMicOn] = useState(false);
    const params = useParams();
    const {campaignName} = params;
    const [usersList, setUsersList] = useState([]);
    const [selectedAssistant, setSelectedAssistant] = useState('');
    const [dids, setDids] = useState([]);
    const [did, setDID] = useState('7971908298');
    
    const fileInputRef = useRef(null);
    const [activeAssistant, setActiveAssistant] = useState({
      name : 'Geeta',
      type : 'Customer Care Executive'
    });
    const [user, setUser] = useState({
      name : 'Vijay',
      mobileno : '8147540362'
    });
    const [availablegroups, setAvailableGroups] = useState([]);
    const [availableAssistants, setAvailableAssistants] = useState([]);
    const [message, setMessage] = useState('');
    const [messageStyle, setMessageStyle] = useState('');
    let curruser = localStorage.getItem("user");
    if(curruser){
      curruser = JSON.parse(curruser);
    }
   const [availableusers, setAvailableUsers] = useState([]);

  // Required columns
  const requiredColumns = ['Name', 'Number', 'Details'];

  useEffect(() => {
    const getItems = async () => {
      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: curruser.email, businessname: curruser.businessName, campaignname: campaignName }),
        };

        const options1 = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: curruser.email, businessName: curruser.businessName}),
        };

        // Fetching available groups and users from campaign in parallel
        const [responseGroups, responseUsers, response3, response4] = await Promise.all([
          fetch(`${process.env.REACT_APP_API_URL}/getgroups`, options),
          fetch(`${process.env.REACT_APP_API_URL}/getusersfromcampaign`, options),
          fetch(`${process.env.REACT_APP_API_URL}/getassistants`),  
          fetch(`${process.env.REACT_APP_API_URL}/getdids`,options1)
        ]);

        const dataGroups = await responseGroups.json();
        const dataUsers = await responseUsers.json();
        const dataAssistants = await response3.json();
        const dataDids = await response4.json();

        // Set available assistants
        if (dataAssistants.templateArray) {
          setAvailableAssistants(dataAssistants.templateArray);
        }

        // Set available groups
        if (dataGroups.GroupsArray) {
          setAvailableGroups(dataGroups.GroupsArray);
        }

        // Set users list
        if (dataUsers.UsersArray) {
          setUsersList(dataUsers.UsersArray);
        }

        if (dataDids.telephonyArray){
          console.log(dataDids.telephonyArray);
          setDids(dataDids.telephonyArray)
        }

      } catch (err) {
        console.error(`Error Occurred: ${err}`);
      }
    };

    getItems();
  }, [curruser.email, curruser.businessName, campaignName]); // Added dependencies

  

  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAllChange = (value) => {
    const newList = usersList.map((ele) => ({
      ...ele,
      isSelected: !ele.isSelected,
    }));
    setSelectAll(value);
    setUsersList(newList);
}


  // Handle individual checkbox change
  const handleCheckboxChange = (elem) => {
    const newList = usersList.map((ele) => {
        if(ele.id===elem.id)
            return {...ele,isSelected:!ele.isSelected}
        return ele;
    })
    setUsersList(newList)
  };

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
          ...formData,
          [id]: value
        });
      };    

      const handleGroupChange = (e) => {
        const groupname = e.target.value;
        if (groupname) {
          const selectedGroup = availablegroups.find((group) => group.groupname === groupname);
          if (selectedGroup && selectedGroup.users) {
            setAvailableUsers(selectedGroup.users); // Set the users for the selected group
          }
        }

         
    
        setFormData({
          ...formData,
          group: groupname,
          user: '', // Reset the user selection when the group changes
        });
      };

      const postData = async (obj) => {
        try{
          const options = {
            method : "POST",
            headers : {
              "Content-Type" : "application/json"
            },
            body:JSON.stringify({email:curruser.email,campaignname:campaignName,UserItem:obj})
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/adduserincampaign`,options)
          const data = await response.json();
          if (data.success) {
            setMessage('User Inserted Successfully');
            setMessageStyle('green');
            
            // Automatically hide the message after 3 seconds
            setTimeout(() => {
              setMessage('');
            }, 3000);
            return true;
          } else {
            setMessageStyle('red');
            setMessage(data.message);
            setTimeout(() => {
              setMessage('');
            }, 3000);
          }
        }
        catch(Err){
          setMessageStyle('red');
          setMessage('An error occurred');
        setTimeout(() => {
          setMessage('');
        }, 3000);
          console.log(`Error Occurred : ${Err}`)
        }
      }
  
      const deleteData = async (obj) => {
        try {
          const options = {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              email: curruser.email,
              campaignname : campaignName,
              businessname : curruser.businessName,
              UserItem: {
                id: obj // Replace 'obj' with the actual ID value of the telephony item
              }
            })
          };
      
          const response = await fetch(`${process.env.REACT_APP_API_URL}/deleteusersfromcampaign`, options);
          
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
      
          const data = await response.json();
          console.log("I am from Delete Data");
      
          if (data.success) {
            // On success, set the success message
            setMessage(data.message);
            setMessageStyle('green');
            
            // Automatically hide the message after 3 seconds
            setTimeout(() => {
              setMessage('');
            }, 3000);
      
            // Refresh or update the telephony list
            setUsersList(usersList.filter((ele) => ele.id!==obj)); // Ensure this function is correctly implemented
          } else {
            setMessageStyle('red');
            setMessage(data.message);
            setTimeout(() => {
              setMessage('');
            }, 3000);
          }
        } catch (Err) {
          setMessageStyle('red');
          setMessage('An error occurred');
          setTimeout(() => {
            setMessage('');
          }, 3000);
          console.error(`Error Occurred: ${Err}`);
        }
      };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const finalData = {
            name : formData.user.name,
            number : formData.user.number,
            group : formData.group,
            status : formData.status,
            id : uuidv4(),
            date : (new Date()).toLocaleDateString('en-GB')+"\n"+(new Date()).toLocaleTimeString('en-GB')
        }
        const res = await postData(finalData);
        if(res){
          setUsersList([finalData,...usersList]);
          setFormData({
              group:'',
              user:'',
              status:''
          })
        }
    }

    const onChangeAssistant = (e) => {
        setSelectedAssistant(e.target.value)
    }

    const parseExcelDate = (excelDate) => {
        // If the date is already a string, just return it
        if (typeof excelDate === 'string') {
          return excelDate;
        }
        // Excel dates are usually stored as serial numbers starting from Jan 1, 1900
        const epoch = new Date(0);
        epoch.setUTCDate(epoch.getUTCDate() + excelDate - 25569);
        return epoch.toISOString().split('T')[0]; // Return in YYYY-MM-DD format
      };

    

    


    return (
         <div className="business-container">
          {message && <div style={{backgroundColor:`${messageStyle==='green'?'green':'red'}`}} className="notification">{message}</div>}
            <div className="business-container-header">
                <div style={{display:'flex',alignItems:'center'}}>
            <button style={{display:'flex',alignItems:'center'}} onClick={() => navigate("/campaigns/campaign")}>
                <MdArrowBackIos/>
                </button>
                <h1 style={{margin:0}} className="bc-main-heading">Campaign</h1>
                </div>
                <div>
                <Popup
  trigger={<button className="addnew-button">Add User +</button>}
  modal
  nested
>
  {close => (
    <div style={{ width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
      <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
        <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
          <h3 style={{fontSize:'20px'}} className="font-bold mb-6">Add User</h3>
          <form onSubmit={(e) => {
            handleSubmit(e);
            close();
          }} className="">
            
            {/* Name Field */}
            {/* <div className="relative">
              <input
                type="text"
                id="name"
                value={formData.name}
                onChange={handleChange}
                className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
                placeholder="Name"
                required
              />
              <label
                htmlFor="name"
                className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
              >
                Name
              </label>
            </div>
            <br/> */}

            {/* Number Field */}
            {/* <div className="relative">
              <input
                type="tel"
                id="number"
                value={formData.number}
                onChange={handleChange}
                className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300 placeholder-transparent"
                placeholder="Number"
                required
              />
              <label
                htmlFor="number"
                className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
              >
                Number
              </label>
            </div>
            <br/> */}

            {/* Details Field */}
            <div className="relative col-span-2">
          <select
            id="group"
            value={formData.group}
            onChange={handleGroupChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300"
            required
          >
            <option value="" disabled>Select</option>
            {availablegroups && (
              <>
              {availablegroups.map((ele,index) => <option key={index} value={ele.groupname}>{ele.groupname}</option>)}
              </>
            )}
          </select>
          <label
            htmlFor="group"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Group
          </label>
        </div>
        <br/>
        <div className="relative col-span-2">
        <select
          id="user"
          value={formData.user?.name || ""} // Value is user's name if selected, else empty
          onChange={(e) => {
            const selectedUser = availableusers.find((user) => user.name === e.target.value);
            setFormData({
              ...formData,
              user: selectedUser || '', // Set the selected user
            });
          }}
          className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300"
          required
        >
          <option value="" disabled>Select User</option>
          {availableusers &&
            availableusers.map((user) => (
              <option key={user.id || user.name} value={user.name}>
                {user.name}
              </option>
            ))}
        </select>
        <label
          htmlFor="user"
          className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
        >
          User
        </label>
      </div>

        <br/>

                    {/*  Status             */}
                    <div className="relative col-span-2">
          <select
            id="status"
            value={formData.businessCategory}
            onChange={handleChange}
            className="peer w-full p-3 bg-gray-900 text-white border border-gray-700 rounded-lg focus:outline-none focus:border-blue-500 transition-all duration-300"
            required
          >
            {statusOptions.map((ele,index) => (
                <option key={index} value={ele}>{ele}</option>
            ))}

          </select>
          <label
            htmlFor="status"
            className="absolute left-3 top-3 text-gray-500 transition-all duration-300 transform -translate-y-1 scale-75 origin-top-left peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-focus:-translate-y-6 peer-focus:scale-75 peer-focus:text-blue-500"
          >
            Status
          </label>
        </div>

            {/* Submit Button */}
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <button
            onClick = {() => close()}
              type="button"
              className="col-span-2 bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="col-span-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Add
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )}
</Popup>
<Popup
  trigger={<button className="addnew-button ml-2">Add Assistant +</button>}
  modal
  nested
>
  {close => (
    <div style={{ width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
      <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
        <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
          <h3 style={{fontSize:'20px'}} className="font-bold mb-6">Select Assistant</h3>
          <form onSubmit={(e) => {
            close();
          }} className="">
            
            {/* Name Field */}
            <div className="relative">
            <div>
              <div>
              <select className="choose-assistant" id="assistant" onChange={onChangeAssistant}>
                <option value="">Select Assistant</option>
                {availableAssistants && availableAssistants.map((ele,index) => (
                    <option key={index} value={ele.assistantName}>{ele.assistantName}</option>
                ))}
              </select>
              {selectedAssistant!=="" && (
                <div style={{display:'flex',alignItems:'center',marginLeft:'15px'}}>
                    <h1 className="sel-assistant">{selectedAssistant}</h1>
                    {micOn===false && (<p onClick={(e) => setMicOn(true)} style={{marginLeft:'5px'}}><FaMicrophoneLines size={30}/></p>)}
                    {micOn===true && (<p onClick={(e) => setMicOn(false)} style={{ marginLeft: '5px' }} className="mic-container"><IoMicCircle size={30} /></p>)}
                </div>
              )}
              </div>
              </div>
              
            </div>
            <br/>

            {/* Submit Button */}
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <button
            onClick = {() => close()}
              type="button"
              className="col-span-2 bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="col-span-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Add
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )}
</Popup>
<Popup
  trigger={<button className="ml-2 addnew-button">Add DID +</button>}
  modal
  nested
>
  {close => (
    <div style={{ width: '600px', overflow: 'auto' }} className="hidden-scrollbar">
      <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
        <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
          <h3 style={{fontSize:'20px'}} className="font-bold mb-6">Add DID</h3>
          <form onSubmit={(e) => {
            close();
          }} className="">
            
            {/* Name Field */}
            <div className="relative">
            <select className="choose-assistant" id="assistant" onChange={(e) => setDID(e.target.value)}>
                <option value="">Select DID Number</option>
                {dids.map((ele,index) => (
                    <option key={index} value={ele}>{ele}</option>
                ))}
              </select>
            </div>
            <br/>


            {/* Submit Button */}
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <button
            onClick = {() => close()}
              type="button"
              className="col-span-2 bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="col-span-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Add
            </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )}
</Popup>
</div>
        </div>
            <div>
                <div style={{display:'flex',justifyContent:'space-between',alignItems:'center',marginTop:'25px'}}>
              <div>
                <h1 style={{fontWeight:'bold',color:'white',fontSize:'18px'}}>Campaign : {campaignName}</h1>
              <h3 style={{fontWeight:'bold',color:'white',fontSize:'14px'}}>Total Users : {usersList.length}</h3>
              <h3 style={{fontWeight:'bold',color:'white',fontSize:'18px',marginTop:'18px'}}>DID Number : {did}</h3>
              </div>
              <div>
              {selectedAssistant!=="" && (
                <div style={{display:'flex',justifyContent:'center'}}>
                <div>
                {micOn === false && (
  <p onClick={() => setMicOn(true)} className="mic-container m-auto">
    <FaMicrophoneLines size={50} />
  </p>
)}
{micOn === true && (
  <p onClick={() => setMicOn(false)} className="mic-container m-auto listening">
    <IoMicCircle size={60} />
  </p>
)}

                    <h1 className="mt-4 sel-assistant">{selectedAssistant}</h1>
                <p>ID: 3ac8dedfsi8</p>
                </div>
                </div>
              )}
              </div>
              </div>

              <div className="table-outer-container bg-[#444444] mt-2 p-4">
  <table className="min-w-full bg-[#15161A] text-white rounded-lg shadow-lg">
    <thead>
      <tr style={{color:'white',fontSize:'16px'}}>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">S.No</th>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">Name</th>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">Number</th>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">Group</th>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">
          <div>
          <input 
        onChange={() => handleSelectAllChange(!selectAll)} 
        type="checkbox" 
        id="selectall" 
        checked={selectAll} 
      />
            <label className="ml-2" htmlFor="selectall">Select</label>
          </div>
        </th>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">Status</th>
        <th className="w-1/7 py-3 px-4 text-left border-b border-gray-700">Action</th>
      </tr>
    </thead>
    <tbody>
      {usersList.map((ele, index) => (
        <tr key={index} className="hover:bg-gray-700">
          <td className="py-3 px-4 border-b border-gray-700">{index+1}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.name}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.number}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.group}</td>
          <td className="py-3 px-4 border-b border-gray-700">
            <input checked={ele.isSelected} type="checkbox" onChange={() => handleCheckboxChange(ele)}/>
          </td>
          {ele.status==="Completed" &&  (
            <Popup
            trigger={<td style={{color:'green',cursor:'pointer'}} className="py-3 px-4 border-b border-gray-700">{ele.status}</td>}
            modal
            nested
          >
            {close => (
              <div style={{width:'600px', overflow: 'auto' }} className="hidden-scrollbar">
              <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
                  <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                  <h3 style={{ fontSize: '25px', marginBottom: '15px', color:'yellow' }} className="font-bold">
                    Completed Call
                  </h3>
                  <button type="button" onClick={() => close()}><IoMdClose /></button>
                  </div>
                  <div className="mt-2 mb-3 flex justify-between items-start">
                    <div className="text-center flex-1">
                    <h1 style={{fontSize:'18px'}} className="assist-heading">{activeAssistant.name}</h1>
                      <p className="m-auto"><RiRobot3Fill style={{color:'aqua'}} className="m-auto" size={60}/></p>
                      <h1 style={{fontSize:'20px'}} className="assist-heading mb-3 mt-2">AI Assistant</h1>

                      <h2 className="assist-heading">{activeAssistant.type}</h2>
                    </div>
                    <div className="text-center flex-1 relative">
                    <div className="flex justify-center items-center pulsing-ring">
                      <FiPhoneCall style={{color:'aqua'}} size={60} className="mx-auto text-white relative z-10" />
                    </div>
                    <p className="text-center">03:49 / 04:00</p>
                  </div>
                    <div className="text-center flex-1">
                    <h1 style={{fontSize:'18px'}} className="assist-heading">{user.name}</h1>
                      <p className="m-auto"><FaUserCircle style={{color:'aqua'}} className="m-auto" size={60}/></p>
                      <h1 style={{fontSize:'20px'}} className="assist-heading mb-3 mt-2">User</h1>

                      <h2 className="assist-heading">{user.mobileno}</h2>
                    </div>
                  </div>
                  <div style={{height:'100px',backgroundColor:'black',borderRadius:'5px',textAlign:'center'}}>
                    <p className="p-3">Hello! How can I help you?</p>
                    </div>
                  {/* <textarea className="greeting-agent-input" placeholder=""></textarea> */}
                </div>
              </div>
            </div>
            
            )}
          </Popup>
          )}
          {ele.status==="Ongoing" &&  (
            <Popup
            trigger={<td style={{color:'aqua',cursor:'pointer'}} className="py-3 px-4 border-b border-gray-700">{ele.status}</td>}
            modal
            nested
          >
            {close => (
              <div style={{width:'600px', overflow: 'auto' }} className="hidden-scrollbar">
              <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
                  <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                  <h3 style={{ fontSize: '25px', marginBottom: '15px', color:'yellow' }} className="font-bold">
                    Live Call
                  </h3>
                  <button type="button" onClick={() => close()}><IoMdClose /></button>
                  </div>
                  <div className="mt-2 mb-3 flex justify-between items-start">
                    <div className="text-center flex-1">
                    <h1 style={{fontSize:'18px'}} className="assist-heading">{activeAssistant.name}</h1>
                      <p className="m-auto"><RiRobot3Fill style={{color:'aqua'}} className="m-auto" size={60}/></p>
                      <h1 style={{fontSize:'20px'}} className="assist-heading mb-3 mt-2">AI Assistant</h1>

                      <h2 className="assist-heading">{activeAssistant.type}</h2>
                    </div>
                    <div className="text-center flex-1 relative">
                    <div className="flex justify-center items-center pulsing-ring">
                      <FiPhoneCall style={{color:'aqua'}} size={60} className="mx-auto text-white relative z-10" />
                    </div>
                    <p className="text-center">03:49</p>
                  </div>
                    <div className="text-center flex-1">
                    <h1 style={{fontSize:'18px'}} className="assist-heading">{user.name}</h1>
                      <p className="m-auto"><FaUserCircle style={{color:'aqua'}} className="m-auto" size={60}/></p>
                      <h1 style={{fontSize:'20px'}} className="assist-heading mb-3 mt-2">User</h1>

                      <h2 className="assist-heading">{user.mobileno}</h2>
                    </div>
                  </div>
                  <div style={{height:'100px',backgroundColor:'black',borderRadius:'5px',textAlign:'center'}}>
                    <p className="p-3">Hello! How can I help you?</p>
                    </div>
                  {/* <textarea className="greeting-agent-input" placeholder=""></textarea> */}
                </div>
              </div>
            </div>
            
            )}
          </Popup>
          )}
          {ele.status==="Error" &&  <td style={{color:'red'}} className="py-3 px-4 border-b border-gray-700">{ele.status}</td>}
          {!['Completed','Error','Ongoing'].includes(ele.status) &&  <td className="py-3 px-4 border-b border-gray-700">{ele.status}</td>}
          <td className="py-3 px-4 border-b border-gray-700">
            <button className="bg-transparent mr-3" type="button">
              <FaEdit size={20}/>
            </button>
            <Popup
              trigger={<button className="bg-transparent mr-3" type="button"><MdDelete size={20}/></button>}
              modal
              nested
            >
              {close => (
                <div
                  style={{
                    width: '300px',
                    height: '250px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#2d3748',
                    color: 'white',
                    borderRadius: '10px',
                    textAlign: 'center',
                  }}
                >
                  <h1 style={{fontSize:'20px'}}>Are you sure you want to delete User?</h1>
                  <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                    <button
                      onClick={() => { close(); }}
                      className="addnew-button"
                      style={{ width: '60px', fontSize: '12px', marginRight: '5px' }}
                    >
                      Close
                    </button>
                    <button
                      onClick={() => { 
                        deleteData(ele.id);
                        close(); 
                      }}
                      className="addnew-button"
                      style={{
                        backgroundColor: 'red',
                        width: '60px',
                        fontSize: '12px',
                        color: 'white',
                        marginLeft: '5px',
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
            </div>

       </div>
    )
}

export default CampaignDetail;