import "./index.css"
import Cookies from 'js-cookie'
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({element : Component,...rest}) => {
    const token = Cookies.get("userId");

    return token?<Component {...rest}/> : <Navigate to="/"/>
}

export default ProtectedRoute;