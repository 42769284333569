import "./index.css"
import IconSidebar from "../../../IconSidebar";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import * as XLSX from 'xlsx';
import { useRef } from "react";
import { IoMicCircle } from "react-icons/io5";
import { FaMicrophoneLines } from "react-icons/fa6";
import {v4 as uuidv4} from 'uuid';
import { HiSpeakerWave } from "react-icons/hi2";
import { IoMdClose } from "react-icons/io";
import { FaUserCircle } from "react-icons/fa";
import { AiFillRobot } from "react-icons/ai";
import { MdOutlineAddIcCall } from "react-icons/md";
import { FiPhoneCall } from "react-icons/fi";
import { RiRobot3Fill } from "react-icons/ri";
import { FaInfoCircle } from "react-icons/fa";
import { FaEye } from "react-icons/fa";
import { IoEyeOff } from "react-icons/io5";
import { useEffect } from "react";


const AdminTelephony = () => {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("user"));
    const [message, setMessage] = useState('');
    const [messageStyle, setMessageStyle] = useState('white');
    const [formData, setFormData] = useState({
        name:'',
        number:'',
        websocket:'',
        vendor:'',
        status:'disable',
        availability : 'Yes',
        clientname : ''
    });
    const [telephonyList, setTelephonyList] = useState([]);
    const [clients, setClients] = useState([]);


    useEffect(() => {
      const getItems = async () => {
        try {
          const options = {
            method : "POST",
            headers : {
              "Content-Type" : "application/json"
            },
            body:JSON.stringify({email:user.email})
          }
          const response = await fetch(`${process.env.REACT_APP_API_URL}/gettelephonydata`,options);
          const response2 = await fetch(`${process.env.REACT_APP_API_URL}/getclients`);
          const data = await response.json();
          const data1 = await response2.json();
          if(data.telephonyArray)
          setTelephonyList(data.telephonyArray)
          if(data1)
          setClients(data1);
        } catch (err) {
          console.error(`Error Occurred: ${err}`);
        }
      };
  
      getItems();
    }, []);


    const onChangeStatus = (elem,statuss) => {
        const newList = telephonyList.map((ele) => {
          if(ele.id===elem.id){
            return {...ele,status:statuss}
          }
          return ele;
        })
        setTelephonyList(newList)
      }

      console.log(telephonyList)

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData({
          ...formData,
          [id]: value
        });
      };
      
    const postData = async (obj) => {
      try{
        const options = {
          method : "POST",
          headers : {
            "Content-Type" : "application/json"
          },
          body:JSON.stringify({email:user.email,telephonyItem:obj})
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/addtelephonyitem`,options)
        const data = await response.json();
        if (data.success) {
          // On success, set the success message
          setMessage('Item Inserted Successfully');
          setMessageStyle('green');
          
          // Automatically hide the message after 3 seconds
          setTimeout(() => {
            setMessage('');
          }, 3000);
          return true;
        } else {
          setMessageStyle('red');
          setMessage(data.message);
          setTimeout(() => {
            setMessage('');
          }, 3000);
        }
      }
      catch(Err){
        setMessageStyle('red');
        setMessage('An error occurred');
      setTimeout(() => {
        setMessage('');
      }, 3000);
        console.log(`Error Occurred : ${Err}`)
      }
    }

    const deleteData = async (obj) => {
      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: user.email,
            telephonyItem: {
              id: obj // Replace 'obj' with the actual ID value of the telephony item
            }
          })
        };
    
        const response = await fetch(`${process.env.REACT_APP_API_URL}/deletetelephonyitem`, options);
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
    
        const data = await response.json();
        console.log("I am from Delete Data");
    
        if (data.success) {
          // On success, set the success message
          setMessage(data.message);
          setMessageStyle('green');
          
          // Automatically hide the message after 3 seconds
          setTimeout(() => {
            setMessage('');
          }, 3000);
    
          // Refresh or update the telephony list
          setTelephonyList(telephonyList.filter((ele) => ele.id!==obj)); // Ensure this function is correctly implemented
        } else {
          setMessageStyle('red');
          setMessage(data.message);
          setTimeout(() => {
            setMessage('');
          }, 3000);
        }
      } catch (Err) {
        setMessageStyle('red');
        setMessage('An error occurred');
        setTimeout(() => {
          setMessage('');
        }, 3000);
        console.error(`Error Occurred: ${Err}`);
      }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        const finalData = {
            name : (formData.name).toLowerCase(),
            didnumber : formData.number,
            websocket : formData.websocket,
            vendor : formData.vendor,
            status : "disable",
            id : uuidv4(),
            date : (new Date()).toLocaleDateString('en-GB')+"\n"+(new Date()).toLocaleTimeString('en-GB'),
            availability : formData.availability,
            clientname : formData.clientname
        }
        // console.log(finalData);
        const res = await postData(finalData);
        if(res){
        setTelephonyList([finalData,...telephonyList]);
        setFormData({
            name:'',
            number:'',
            websocket:'',
            vendor:'',
            status:'',
            clientname:''
        })
      }
    }

    


    return (
        <div className="main-body-container">
          {message && <div style={{backgroundColor:`${messageStyle==='green'?'green':'red'}`}} className="notification">{message}</div>}
     <IconSidebar />
         <div className="w-80 business-container">
            <div className="business-container-header mb-3">
                <h1 style={{margin:0}} className="bc-main-heading">Telephony</h1>
                <div>
                <Popup
            trigger={<button className="addnew-button">Add DID</button>}
            modal
            nested
            >
            {close => (
                <div style={{ width: '600px', height:'500px', overflow: 'auto' }} className="hidden-scrollbar">
                <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                    <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
                    <h3 style={{fontSize:'20px'}} className="font-bold mb-6">Subscribe Number</h3>
                    <form onSubmit={(e) => {
                        handleSubmit(e);
                        close();
                    }} className="">
                        
                        {/* Name Field */}
                        <div className="relative">
                        <label
                            htmlFor="name"
                        >
                            Name
                        </label>
                        <input
                            type="text"
                            id="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="input-model"
                            placeholder="Name"
                            required
                        />
                        </div>
                        <br/>

                        {/* Number Field */}
                        <div className="relative">
                        <label htmlFor="number">Number</label>
                        <input
                            type="number"
                            id="number"
                            value={formData.number}
                            onChange={handleChange}
                            className="input-model"
                            placeholder="Number"
                            required
                        />
                        </div>
                        <br/>

                        <div className="relative">
                        <label htmlFor="websocket">Websocket</label>
                        <input
                            type="text"
                            id="websocket"
                            value={formData.websocket}
                            onChange={handleChange}
                            className="input-model"
                            placeholder="Websocket"
                            required
                        />
                        </div>
                        <br/>

                        <div className="relative">
                        <label htmlFor="vendor">Vendor</label>
                        <input
                            type="text"
                            id="vendor"
                            value={formData.vendor}
                            onChange={handleChange}
                            className="input-model"
                            placeholder="Vendor"
                            required
                        />
                        </div>
                        <br/>

                        <div className="relative">
                        <label htmlFor="availability">Availability</label>
                        <select value={formData.availability} onChange={handleChange} id="availability">
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                        </div>
                        <br/>

                        <div className="relative">
                        <label htmlFor="clientname">Client Name</label>
                        {/* <input
                            type="text"
                            id="clientname"
                            value={formData.clientname}
                            onChange={handleChange}
                            className="input-model"
                            placeholder="Client Name"
                            required
                        /> */}
                        <select value={formData.clientname} onChange={handleChange} id="clientname">
                          <option value="">Select Client</option>
                          {clients.map((ele) => <option key={ele} value={ele}>{ele}</option>)}
                        </select>
                        </div>
                        <br/>

                        {/* Submit Button */}
                        <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <button
                        onClick = {() => close()}
                        type="button"
                        className="col-span-2 bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
                        >
                        Cancel
                        </button>
                        <button
                        type="submit"
                        className="col-span-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
                        >
                        Add
                        </button>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            )}
            </Popup>
            </div>
        </div>
            <div>
            <div style={{overflow: 'auto'}} className="table-outer-container bg-[#444444] mt-5 p-4">
  <table className="min-w-full bg-[#15161A] text-white rounded-lg shadow-lg" style={{tableLayout: 'auto'}}>
    <thead>
      <tr style={{color: 'white', fontSize: '16px'}}>
        <th style={{width: '5%'}} className="py-3 px-4 text-left border-b border-gray-700">S.No</th>
        <th style={{width: '20%'}} className="py-3 px-4 text-left border-b border-gray-700">Name</th>
        <th style={{width: '15%'}} className="py-3 px-4 text-left border-b border-gray-700">DID Number</th>
        <th style={{width: '15%'}} className="py-3 px-4 text-left border-b border-gray-700">Websocket</th>
        <th style={{width: '10%'}} className="py-3 px-4 text-left border-b border-gray-700">Vendor</th>
        <th style={{width: '10%'}} className="py-3 px-4 text-left border-b border-gray-700">Availability</th>
        <th style={{width: '15%'}} className="py-3 px-4 text-left border-b border-gray-700">Client Name</th>
        <th style={{width: '5%'}} className="py-3 px-4 text-left border-b border-gray-700">Enable/Disable</th>
        <th style={{width: '5%'}} className="py-3 px-4 text-left border-b border-gray-700">Action</th>
      </tr>
    </thead>
    <tbody>
      {telephonyList.map((ele, index) => (
        <tr className={`hover:bg-gray-700 ${ele.status==="disable" ? 'disable-row' : ''}`} key={index}>
          <td className="py-3 px-4 border-b border-gray-700">{index + 1}</td>
          <td className="py-3 px-4 border-b border-gray-700" style={{wordWrap: 'break-word'}}>{(ele.name).charAt(0).toUpperCase() + (ele.name).slice(1).toLowerCase()}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.didnumber}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.websocket}</td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.vendor}</td>
          <td style={{color: `${ele.availability === "Yes" ? 'green' : 'red'}`}} className="py-3 px-4 border-b border-gray-700">
            {ele.availability}
          </td>
          <td className="py-3 px-4 border-b border-gray-700">{ele.clientname}</td>
          <td className="py-3 px-4 border-b border-gray-700">
            {ele.status === "enable" ? (
              <button onClick={() => onChangeStatus(ele, "disable")} style={{marginLeft: '10px'}} className="text-gray-600 hover:text-gray-900 focus:outline-none">
                <FaEye size={30} />
              </button>
            ) : (
              <button onClick={() => onChangeStatus(ele, "enable")} style={{marginLeft: '10px'}} className="text-gray-600 hover:text-gray-900 focus:outline-none">
                <IoEyeOff size={30} />
              </button>
            )}
          </td>
          <td className="py-3 px-4 border-b border-gray-700">
            <button className="bg-transparent mr-3" type="button">
              <FaEdit size={20}/>
            </button>
            <Popup
              trigger={<button className="bg-transparent mr-3" type="button"><MdDelete size={20}/></button>}
              modal
              nested
            >
              {close => (
                <div
                  style={{
                    width: '300px',
                    height: '250px',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#2d3748',
                    color: 'white',
                    borderRadius: '10px',
                    textAlign: 'center',
                  }}
                >
                  <h1 style={{fontSize: '20px'}}>Are you sure you want to delete Number?</h1>
                  <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: '10px'}}>
                    <button
                      onClick={() => {  
                        close(); }}
                      className="addnew-button"
                      style={{width: '60px', fontSize: '12px', marginRight: '5px'}}
                    >
                      Close
                    </button>
                    <button
                      onClick={() => { deleteData(ele.id)
                        close(); }}
                      className="addnew-button"
                      style={{
                        backgroundColor: 'red',
                        width: '60px',
                        fontSize: '12px',
                        color: 'white',
                        marginLeft: '5px',
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

            </div>
            </div>
       </div>
    )
}

export default AdminTelephony;