import "./index.css"
import IconSidebar from "../../../IconSidebar";
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Popup from "reactjs-popup";
import { useState } from "react";
import { Link } from "react-router-dom";
import React from "react";
import { useEffect } from "react";
import {v4 as uuidv4} from 'uuid';
import axios from "axios";

const MAX_FILE_SIZE_MB = 10; // Maximum file size limit in MB
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // Convert MB to bytes


const Dataset = () => {
    const [activeType, setActiveType] = useState('')
    const [file, setFile] = useState('');
    const [dataList, setDataList] = useState([]);
    const [webpageUrl, setWebPageUrl] = useState('');
    const [youtubeUrl, setYoutubeVideoUrl] = useState('');
    const [text, setText] = useState('')
    const [finalData, setFinalData] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [showedtype, setShowedType] = useState('ALL');
    const [filteredList, setFilteredList] = useState([]);
    const [message, setMessage] = useState('');
    const [messageStyle, setMessageStyle] = useState('white');
    const [isUploading, setIsUploading] = useState(false); // Track uploading state
    const [fileUrl, setFileUrl] = useState(""); // Store the uploaded file URL

    const user = JSON.parse(localStorage.getItem("user"));

    console.log(process.env.REACT_APP_API_URL)


    useEffect(() => {
      const getItems = async () => {
        try {
          const options = {
            method : "POST",
            headers : {
              "Content-Type" : "application/json"
            },
            body:JSON.stringify({email:user.email})
          }
  
          const response = await fetch(`${process.env.REACT_APP_API_URL}/getdatasetdata`,options);
          const data = await response.json();
          setDataList(data.datasetArray)
          setFilteredList(data.datasetArray)
        } catch (err) {
          console.error(`Error Occurred: ${err}`);
        }
      };
  
      getItems();
    }, []);

    const deleteData = async (obj) => {
      try {
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email: user.email,
            datasetItem: {
              id: obj // Replace 'obj' with the actual ID value of the telephony item
            }
          })
        };
    
        const response = await fetch(`${process.env.REACT_APP_API_URL}/deletedatasetitem`, options);

    
        const data = await response.json();
    
        if (data.success) {
          // On success, set the success message
          setMessage(data.message);
          setMessageStyle('green');
          
          // Automatically hide the message after 3 seconds
          setTimeout(() => {
            setMessage('');
          }, 3000);
    
          // Refresh or update the telephony list
          setDataList(dataList.filter((ele) => ele.id!==obj)); // Ensure this function is correctly implemented
          setFilteredList(dataList.filter((ele) => ele.id!==obj)); // Ensure this function is correctly implemented
        } else {
          setMessageStyle('red');
          setMessage(data.message);
          setTimeout(() => {
            setMessage('');
          }, 3000);
        }
      } catch (Err) {
        setMessageStyle('red');
        setMessage('An error occurred');
        setTimeout(() => {
          setMessage('');
        }, 3000);
        console.error(`Error Occurred: ${Err}`);
      }
    };

    
    const handleUpload = async () => {
      if (!file) return; // Ensure a file is selected
  
      try {
        // Set uploading state
        setIsUploading(true);
  
        // Check if the file size is less than 10MB (single upload)
        if (file.size < 10000000) {
          // Call the API to get the presigned URL for single file upload
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/generate-single-presigned-url`,
            {
              fileName: `${uuidv4}-${file.name}`,
            }
          );
          const { url } = response.data;
  
          // Upload file using presigned URL
          const uploadResponse = await axios.put(url, file, {
            headers: {
              "Content-Type": file.type,
            },
          });
  
          // Check upload response
          if (uploadResponse.status === 200) {
            alert("File uploaded successfully.");
            // Set the file URL (this is the URL where the file was uploaded)
            setFileUrl(url.split("?")[0]);
          } else {
            alert("Upload failed.");
          }
          setIsUploading(false); // Reset uploading state
        } else {
          // Multipart upload for large files (more than 10MB)
          const uniqueFilename = `${uuidv4()}-${file.name}`;
          const startUploadResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/start-multipart-upload`,
            {
              fileName: uniqueFilename,
              contentType: file.type,
            }
          );
  
          const { uploadId } = startUploadResponse.data;
  
          // Chunk file into 10MB parts
          const chunkSize = 10000000; // 10MB
          const numChunks = Math.ceil(file.size / chunkSize);
          const totalSize = file.size;
  
          // Request presigned URLs for each part
          const presignedUrlsResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/generate-presigned-url`,
            {
              fileName: uniqueFilename,
              uploadId,
              partNumbers: numChunks,
            }
          );
  
          const presignedUrls = presignedUrlsResponse.data.presignedUrls;
  
          const parts = [];
          const uploadPromises = [];
  
          // Upload each chunk separately
          for (let i = 0; i < numChunks; i++) {
            const start = i * chunkSize;
            const end = Math.min(start + chunkSize, totalSize);
            const chunk = file.slice(start, end);
  
            const presignedUrl = presignedUrls[i];
  
            uploadPromises.push(
              axios.put(presignedUrl, chunk, {
                headers: {
                  "Content-Type": file.type,
                },
              })
            );
          }
  
          // Wait for all chunks to upload
          const uploadResponses = await Promise.all(uploadPromises);
  
          uploadResponses.forEach((response, i) => {
            parts.push({
              etag: response.headers.etag,
              PartNumber: i + 1,
            });
          });
  
          // Complete multipart upload
          const completeUploadResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/complete-multipart-upload`,
            {
              fileName: uniqueFilename,
              uploadId,
              parts,
            }
          );
  
          if (completeUploadResponse.status === 200) {
            alert("File uploaded successfully.");
            // Set the file URL (construct the S3 object URL)
            console.log(presignedUrls[0].split("?")[0])
            setFileUrl(
              presignedUrls[0].split("?")[0]
            );
          } else {
            alert("Upload failed.");
          }

  
          setIsUploading(false);
        }
      } catch (error) {
        console.error("Upload error:", error);
        alert("Upload failed.");
        setIsUploading(false); // Reset uploading state
      }
    };
    
        

    const postData = async (obj) => {
      try{
        const options = {
          method : "POST",
          headers : {
            "Content-Type" : "application/json"
          },
          body:JSON.stringify({email:user.email,datasetItem:obj})
        }
        const response = await fetch(`${process.env.REACT_APP_API_URL}/adddatasetitem`,options)
        const data = await response.json();
        if (data.success) {
          // On success, set the success message
          setMessage('Data Inserted Successfully');
          setMessageStyle('green');
          
          // Automatically hide the message after 3 seconds
          setTimeout(() => {
            setMessage('');
          }, 3000);
          return true;
        } else {
          setMessageStyle('red');
          setMessage(data.message);
          setTimeout(() => {
            setMessage('');
          }, 3000);
        }
      }
      catch(Err){
        setMessageStyle('red');
        setMessage('An error occurred');
      setTimeout(() => {
        setMessage('');
      }, 3000);
        console.log(`Error Occurred : ${Err}`)
      }
    }

// Handle form submission
const handleSubmit = async (e) => {
  e.preventDefault();
  let newFinalData = null;

  const currentDateTime = `${(new Date()).toLocaleDateString('en-GB')}\n${(new Date()).toLocaleTimeString('en-GB')}`;

  // Check activeType and set newFinalData accordingly
  if (activeType === "File" && file) {
    if(fileUrl){
    newFinalData = {
      date: currentDateTime,
      source: fileUrl,
      type: "File",
      fileName : file.name
    };
    setMessageStyle('green');
    setMessage("File is Uploaded Successfully");
    setTimeout(() => {
      setMessage('');
    }, 3000);
  }
  } else if (activeType === "Webpage" && webpageUrl) {
    newFinalData = {
      date: currentDateTime,
      source: webpageUrl,
      type: "Webpage"
    };
  } else if (activeType === "Youtube Video Link" && youtubeUrl) {
    newFinalData = {
      date: currentDateTime,
      source: youtubeUrl,
      type: "Youtube Video Link"
    };
  } else if (activeType === "Text" && text) {
    newFinalData = {
      date: currentDateTime,
      source: text,
      type: "Text"
    };
  } else {
    // If no valid data, show error message
    setMessageStyle('red');
    setMessage("Please provide valid input.");
    setTimeout(() => {
      setMessage('');
    }, 3000);
    return; // Exit early if no valid data
  }

  // Check if newFinalData is valid before setting it
  if (newFinalData) {
    newFinalData = {id:uuidv4(),...newFinalData};
    const res = await postData(newFinalData);
    if(res){
    const newList = [newFinalData, ...dataList];
    setDataList(newList);
    setFilteredList(newList);

    // Reset states
    setActiveType('');
    setText('');
    setFileUrl('');
    setWebPageUrl('');
    setYoutubeVideoUrl('');
  }
}
};


  const onChangeSearchInput = (e) => {
    const searchinput = e.target.value
    const newList = dataList.filter((ele) => ele.source.toLowerCase().includes(searchinput.toLowerCase()));
    setFilteredList(newList)
  }

  const onChangeShowedType = (e) => {
    const showedtype = e.target.value
    console.log(showedtype)
    if(showedtype==="ALL")
      setFilteredList(dataList);
    else
    setFilteredList(dataList.filter((ele) => ele.type===showedtype))
  setShowedType(e.target.value)
  }
  
    

    


    return (
        <div className="main-body-container">
          {message && <div style={{backgroundColor:`${messageStyle==='green'?'green':'red'}`}} className="notification">{message}</div>}
     <IconSidebar />
         <div className="w-80 business-container">
            <div className="business-container-header">
                <h1 className="bc-main-heading">Dataset (Common Knowledge)</h1>
                <select value={activeType} className="addnew-button custom-dropdown" onChange={(e) => setActiveType(e.target.value)}>
                    <option value="">New Record</option>
                    <option value="File">File</option>
                    <option value="Webpage">Web Page</option>
                    <option value="Youtube Video Link">YT Video Link</option>
                    <option value="Text">Text</option> 
                </select>
                {activeType !== "" && (
  <div style={{
    position: 'fixed', // Changed to fixed to keep the popup on the screen
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)', // Center the popup
    zIndex: '9999',
    backgroundColor: 'black',
    padding: '20px', // Added padding for better spacing
    borderRadius: '8px', // Added border-radius for rounded corners
    width: '700px', // Set a fixed width for the popup
    height:`${activeType==="Text"?'500px':'300px'}`,
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)' // Added shadow for better visibility
  }}>
    <h2 className="text-2xl font-bold mb-6 text-white">Add {activeType}</h2>
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="mt-3 mb-3">
        {activeType === "File" && (
          <>
            <label htmlFor="file" className="text-white">Insert File</label>
            <br />
            <input onChange={(e) => setFile(e.target.files[0])} className="mt-3 input-model" type="file" id="file" />
            <button style={{marginTop:'10px',backgroundColor:'green',color:'white'}} onClick={handleUpload} className="addnew-button" type="button">{isUploading?'Uploading...':'Upload'}</button>
          </>
        )}
        {activeType === "Webpage" && (
          <>
            <label htmlFor="webpage" className="text-white">Add Webpage URL</label>
            <input onChange={(e) => setWebPageUrl(e.target.value)} className="mt-3 input-model" placeholder="Enter the Web page URL" type="text" id="webpage" />
            <br />
          </>
        )}
        {activeType === "Youtube Video Link" && (
          <>
            <label htmlFor="ytvideolink" className="text-white">Video Link</label>
            <br />
            <input onChange={(e) => setYoutubeVideoUrl(e.target.value)} className="mt-3 input-model" placeholder="Enter the Youtube video link" type="text" id="ytvideolink" />
          </>
        )}
        {activeType === "Text" && (
          <>
            <label htmlFor="text" className="text-white">Text</label>
            <br />
            <textarea style={{height:'250px'}} onChange={(e) => setText(e.target.value)} className="mt-3 input-model" type="text" id="text" placeholder="Enter the Text"> </textarea>
          </>
        )}
      </div>

      {/* Submit Button */}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <button
        onClick={() => setActiveType('')}
          type="button"
          className=" bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
        >
          Cancel
        </button>
        <button
          type="submit"
          className=" bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
        >
          Add
        </button>
      </div>
    </form>
  </div>
)}

    </div>
            <div>
              <div className="flex justify-between items-center mt-3 mb-3">
              <div className="flex items-center ">
                <input style={{width:'250px',marginRight:'15px'}} className="input-model" type="text" placeholder="Search by Source" onChange={onChangeSearchInput}/>
                <select style={{width:'250px'}} value={showedtype} className="addnew-button custom-dropdown" onChange={onChangeShowedType}>
                    <option value="ALL">Select by Type</option>
                    <option value="File">Files</option>
                    <option value="Webpage">Web Page</option>
                    <option value="Youtube Video Link">YT Video Link</option>
                    <option value="Text">Text</option> 
                </select>
              </div>
              <button
          type="button"
          className=" bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300"
        >
          Build
        </button>
              </div>
              
            <div className="table-outer-container bg-[#444444] mt-5 p-4">
  <table className="min-w-full bg-[#15161A] text-white rounded-lg shadow-lg">
    <thead>
      <tr style={{color:'white',fontSize:'16px'}}>
        <th className="w-1/12 py-3 px-4 text-left border-b border-gray-700">S.No</th>
        <th className="w-1/12 py-3 px-4 text-left border-b border-gray-700">Date</th>
        <th className="w-4/12 py-3 px-4 text-left border-b border-gray-700">Details</th>
        <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Type</th>
        <th className="w-3/12 py-3 px-4 text-left border-b border-gray-700">Action</th>
      </tr>
    </thead>
    <tbody>
        {filteredList.map((ele,index) => (
                  <tr key={index+1} className="hover:bg-gray-700">
                  <td className="py-3 px-4 border-b border-gray-700">{index+1}</td>
                  <td className="py-3 px-4 border-b border-gray-700">
                    {ele.date}            
                  </td>
                  {ele.type==="Text" && (
                    <Popup
                    trigger={<td className="py-3 px-4 border-b border-gray-700">{(ele.source).slice(0,30)}</td>}
                    modal
                    nested
                    >
                    {close => (
                        <div style={{ width: '600px' }} className="hidden-scrollbar">
                        <div className="flex justify-center items-center min-h-screen bg-black p-4 m-auto">
                            <div className="bg-gray-800 text-white p-8 rounded-lg shadow-lg w-full max-w-4xl">
                            <h3 style={{fontSize:'20px'}} className="font-bold mb-6">View Text</h3>
                              <div className="input-model">
                                {ele.source}
                              </div>
        
                                {/* Submit Button */}
                                <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                                <button
                                onClick = {() => close()}
                                type="button"
                                className="mt-3 col-span-2 bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
                                >
                                Close
                                </button>
                                </div>
                            </div>
                        </div>
                        </div>
                    )}
                    </Popup>
                  )}
                   {ele.type==="File" && (<td className="py-3 px-4 border-b border-gray-700"><a href={ele.source} target="_blank" rel="noreferrer">{ele.fileName?(ele.fileName).slice(0,30):"Demo"}</a></td>)}
                   {(ele.type!=="Text" && ele.type!=="File") && (<td className="py-3 px-4 border-b border-gray-700"><a href={ele.source} target="_blank" rel="noreferrer">{(ele.source).slice(0,30)}</a></td>)}
                  <td className="py-3 px-4 border-b border-gray-700">{ele.type}</td>
                  <td className="py-3 px-4 border-b border-gray-700">
                      <button className="bg-transparent mr-3" type="button"><FaEdit size={20}/></button>
                      <Popup
          trigger={<button className="bg-transparent mr-3" type="button"><MdDelete size={20}/></button>}
          modal
          nested
        >
          {close => (
            <div
              style={{
                width: '300px',
                height: '250px',
                padding: '20px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#2d3748',
                color: 'white',
                borderRadius: '10px',
                textAlign: 'center',
              }}
            >
              <h1 style={{fontSize:'20px'}}>Are you sure you want to delete data?</h1>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '10px' }}>
                <button
                  onClick={() => {
                    close()
                }}
                  className="addnew-button"
                  style={{ width: '60px', fontSize: '12px', marginRight: '5px' }}
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    deleteData(ele.id)
                    close();
                  }}
                  className="addnew-button"
                  style={{
                    backgroundColor: 'red',
                    width: '60px',
                    fontSize: '12px',
                    color: 'white',
                    marginLeft: '5px',
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </Popup>

                  </td>
                </tr>
        ))}

    </tbody>
  </table>
</div>


            </div>
            </div>
       </div>
    )
}

export default Dataset;