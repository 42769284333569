import { BrowserRouter, Routes, Route, useLocation, useParams } from 'react-router-dom';
import '@coreui/coreui-pro/dist/css/coreui.min.css';

import React from 'react';
import Sidebar from './components/Sidebar';
import Content from './components/Content';
import Header from './components/Header';
import Assistants from './components/Assistants';
import PhoneNumber from './components/PhoneNumber';
import Files from './components/Files';
import Tools from './components/ClientTabs/Platform/Tools';
import Squads from './components/Squads';
import Meetings from './components/ClientTabs/Meetings';
import Conversations from './components/ClientTabs/Conversations';
import Profile from './components/Profile';
import LandingPage from './components/LandingPage';
import { SparklesPreview } from './components/ui/sparklespreview';
import './App.css';
import AdminDashboard from './components/AdminDashboard';
import RegistrationForm from './components/RegistrationForm';
import RegPending from './components/RegPending';
import BusinessContent from './components/BusinessContent';
import RegRejected from './components/RegRejected';
import Dashboard from './components/AdminTabs/Dashboard';
import Tickets from './components/AdminTabs/Tickets';
import Feedback from './components/AdminTabs/Feedback';
import AdminSettings from './components/AdminTabs/AdminSettings';
import Credits from './components/ClientTabs/Credits';
import ProtectedRoute from './components/ProtectedRoute';
import AdminAssistants from './components/AdminTabs/PlatformTabs/AdminAssistants';
import AdminSection from './components/AdminTabs/AdminSection';
import { TemplateProvider } from './components/Context/templatecontext';
import TemplateHome from './components/AdminTabs/PlatformTabs/TemplateHome';
import StatusDashboard from './components/StatusDashboard';
import IconSidebar from './components/IconSidebar';
import { IconContext } from 'react-icons/lib';
import BlogPage from './components/BlogPage';
import MyNavbar from './components/LandingComponents/Navbar';
import LandingPage2 from './components/LandingComponents/LandingPage';
import About from './components/About';
import Collections from './components/AdminTabs/Collections';
import Help from './components/AdminTabs/Help';
import IconClientSidebar from './components/IconClientSidebar';
import Settings from './components/Settings';
import Overview from './components/Overview';
import Groups from './components/ClientTabs/Campaigns/Groups';
import ClientAssistants from './components/ClientTabs/Platform/ClientAssistants';
import Users from './components/ClientTabs/Campaigns/Users';
import Campaign from './components/ClientTabs/Campaigns/Campaign';
import CampaignDetail from './components/ClientTabs/Campaigns/CampaignDetail';
import Report from './components/ClientTabs/Campaigns/Report';
import ReportDetail from './components/ClientTabs/Campaigns/ReportDetail';
import Knowledge from './components/ClientTabs/Platform/Knowledge';
import Telephony from './components/ClientTabs/Platform/Telephony';
import Usage from './components/ClientTabs/Usage';
import Performance from './components/AdminTabs/Performance';
import AdminConversations from './components/AdminTabs/AdminConversations';
import AdminTelephony from './components/AdminTabs/PlatformTabs/AdminTelephony';
import AdminTools from './components/AdminTabs/PlatformTabs/AdminTools';
import Dataset from './components/AdminTabs/PlatformTabs/Dataset';
import { UserProvider } from './components/Context/userContext';
import FileUpload from './components/FileUpload';

const routesWithoutSidebarHeader = [
  '/landing-page',
  '/dashboard',
  '/feedback',
  '/tickets',
  '/business',
  '/payments',
  '/settings',
  '/subscriptions',
  '/faqs',
  '/trainings',
  '/contact',
  '/registrationform',
  '/regpending',
  '/regrejected',
  '/businesses/:businessname', // This will be handled dynamically,
  '/overview',
  '/admin',
  '/',
  '/templatehome',
  '/adminassistants',
  '/adminsection',
  '/statusdashboard',
  '/iconicsidebar',
  '/blogs',
  '/nav',
  '/lp2',
  '/about',
  '/payments',
  '/help',
  '/clientsidebar',
  '/adminsettings',
  '/platform/assistants',
  '/campaign',
  '/performance',
  '/adminconversations',
  '/system/assistants',
  '/system/tools',
  '/system/dataset',
  '/system/function',
  '/system/telephony',
];

function App() {
  return (
    <BrowserRouter>
      <ConditionalLayout>
        <Routes>
          <Route path="/" element={<LandingPage2 />} />
          <Route path="/performance" element={<ProtectedRoute element={Overview} />}/>
          <Route path="/assistants" element={<ProtectedRoute element={Assistants} />}/>
          <Route path="/phone-number" element={<ProtectedRoute element={PhoneNumber} />}/>
          <Route path="/files" element={<ProtectedRoute element={Files} />}/>
          <Route path="/tools" element={<ProtectedRoute element={Tools} />}/>
          <Route path="/squads" element={<ProtectedRoute element={Squads } />}/>
          <Route path="/meetings" element={<ProtectedRoute element={Meetings } />}/>
          <Route path="/conversations" element={<ProtectedRoute element={Conversations}/>}/>
          <Route path="/account" element={<ProtectedRoute element={Profile}/>}/>
          <Route path="/sparkles-preview" element = {<ProtectedRoute element={SparklesPreview} />}/>
          <Route path="/admin" element={<ProtectedRoute element={Dashboard} />} />
          <Route path="/adminsection" element={<ProtectedRoute element={AdminSection}/>}/>
          <Route path="/dashboard" element={<ProtectedRoute element={Performance} />} />
          <Route path="/businesses/:businessname" element={<ProtectedRoute element={Overview} />} />
          <Route path="/business" element={<ProtectedRoute element={BusinessContent} />} />
          <Route path="/tickets" element={<ProtectedRoute element={Tickets } />} />
          <Route path="/feedback" element={<ProtectedRoute element={Feedback } />} />
          <Route path="/adminsettings" element={<ProtectedRoute element={AdminSettings } />} />
          <Route path="/registrationform" element={<ProtectedRoute element={RegistrationForm} />} />
          <Route path="/regpending" element={<ProtectedRoute element={RegPending} />} />
          <Route path="/regrejected" element={<ProtectedRoute element={RegRejected} />} />

          <Route path="/system/assistants" element={<ProtectedRoute element={TemplateHome}/>}/>
          <Route path="/system/tools" element={<ProtectedRoute element={AdminTools}/>}/>
          <Route path="/system/dataset" element={<ProtectedRoute element={Dataset}/>}/>
          <Route path="/system/telephony" element={<ProtectedRoute element={AdminTelephony}/>}/>

          <Route path="/templatehome" element={<ProtectedRoute element={TemplateHome}/>}/>
          <Route path="/statusdashboard" element={<ProtectedRoute element={StatusDashboard}/>}/>
          {/* <Route path="/iconicsidebar" element={<IconSidebar/>}/> */}
          <Route path="/payments" element={<ProtectedRoute element={Collections}/>}/>
          <Route path="/help" element={<ProtectedRoute element={Help}/>}/>
          <Route path="/blogs" element={<BlogPage/>}/>
          <Route path="/about" element={<About/>} />
          <Route path="/clientsidebar" element={<ProtectedRoute element={IconClientSidebar}/>}/>
          <Route path="/campaigns/users" element={<ProtectedRoute element={Groups}/>}/>
          <Route path="/campaigns/groups/:groupName" element={<ProtectedRoute element={Users}/>}/>
          <Route path="/campaigns/campaign/" element={<ProtectedRoute element={Campaign}/>}/>
          <Route path="/campaigns/campaign/:campaignName" element={<ProtectedRoute element={CampaignDetail}/>}/>
          <Route path="/campaigns/report" element={<ProtectedRoute element={Report}/>} />
          <Route path="/campaigns/report/:campaignName" element={<ProtectedRoute element={ReportDetail}/>} />

          {/* <Route path="/campaigns/groups/:groupName" element={<ProtectedRoute element={Users}/>}/> */}

          
          <Route path="/settings" element={<ProtectedRoute element={Settings}/>}/>
          <Route path='/platform/assistants' element={<ProtectedRoute element={ClientAssistants}/>}/>
          <Route path='/platform/tools' element={<ProtectedRoute element={Tools}/>}/>
          <Route path='/platform/knowledge' element={<ProtectedRoute element={Knowledge}/>}/>
          <Route path='/platform/telephony' element={<ProtectedRoute element={Telephony}/>}/>
          <Route path="/credits/credits" element={<ProtectedRoute element={Credits}/>}/>
          <Route path="/credits/usage" element={<ProtectedRoute element={Usage}/>}/>
          <Route path="/adminconversations" element={<ProtectedRoute element={AdminConversations}/>}/>

          <Route path="/uploadfile" element={<ProtectedRoute element={FileUpload}/>}/>


          {/* <Route path="/nav" element={<MyNavbar/>}/>
          <Route path="/lp2" element={<LandingPage2/>}/> */}
        </Routes>
      </ConditionalLayout>
    </BrowserRouter>
  );
}

function ConditionalLayout({ children }) {
  const location = useLocation();

  // Check if the current path matches any of the routes without sidebar/header
  const shouldHideLayout = routesWithoutSidebarHeader.some(route => {
    const match = new RegExp(`^${route.replace(/:\w+/g, '[^/]+')}$`).test(location.pathname);
    return match;
  });

  return (
    <UserProvider>
    <div className="app">
      {/* {!shouldHideLayout && <Header />} */}
      <div className="main-body-container">
        {!shouldHideLayout && <IconClientSidebar />}
        {children}
      </div>
    </div>
    </UserProvider>
  );
}

export default App;
