import React, { useEffect } from "react";
import { FaChartPie} from 'react-icons/fa';
import "./index.css";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import IconClientSidebar from "../IconClientSidebar";
import { useState } from "react";
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { MdCopyAll } from "react-icons/md";
import { GoDotFill } from "react-icons/go";
import Popup from "reactjs-popup";
import { IoClose } from "react-icons/io5";
import {CDateRangePicker} from '@coreui/react-pro';


function Overview() {
  const params = useParams();
  const {businessname} = params;
  const [businessName, setBusinessName] = useState(businessname);
  const token = Cookies.get("userId");
  const {email} = jwtDecode(token);
  const [bId, setBusinessId] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedKPIs, setSelectedKPIs] = useState([]);
  const [kpiOptions,setKPIOptions] = useState(['Total Emails', 'Total Aadhaar KYC', 'Total PAN KYC' ,'Total CIBIL KYC',"Total Businesses", 
    "Total AI Assistant", 
    "Total Credits Sold", 
    "Total Calls Made", 
    "Total Call Minutes",
    "Total Campaign"]);

    const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const isDateRangeValid = () => {
    if (startDate && endDate) {
      return new Date(startDate) <= new Date(endDate);
    }
    return true;
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedKPIs([...selectedKPIs, value]);
    } else {
      setSelectedKPIs(selectedKPIs.filter((kpi) => kpi !== value));
    }
  };

  const onClickAdd = (e) => {
    e.preventDefault();
    setKPIOptions(kpiOptions.filter((ele) => !selectedKPIs.includes(ele)))
  }

  const onClickRemove = (option) => {
    setSelectedKPIs(selectedKPIs.filter((ele) => ele!==option))
    if(kpiOptions.includes(option)===false)
      setKPIOptions([option,...kpiOptions])
  }


  useEffect(() => {
    const getItems = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/checkuser`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email })
        });
        const data = await response.json();
        console.log(data);
        setBusinessName(data.user.businessName);
        setBusinessId(data.user._id);
      } catch (err) {
        console.error(`Error Occurred: ${err}`);
      }
    };
  
    getItems();
  }, [email]);  // Only dependent on 'email'



  return (
    <div style={{width:'100%'}} className="app">
    {/* <AdminHeader /> */}
   <div className="main-body-container">
     <IconClientSidebar />
     <div className="business-container">
     <div className="no-data">
      <div style={{display:'flex',justifyContent:'space-between'}}>
     <h1 style={{textAlign:'left',fontWeight:'bold',fontSize:'20px'}}>Performance (KPIs)</h1>
     <div>
     <h1 style={{textAlign:'left',fontWeight:'bold',fontSize:'25px'}}>{businessName}</h1>
     <div style={{display:'flex',alignItems:'center'}}>
        <p style={{fontSize:'16px'}}><span className="template-details-span-element">BID : </span>{bId}</p>
        <p style={{cursor:'pointer'}} className="ml-3" onClick={() => navigator.clipboard.writeText(bId)}><MdCopyAll size={20}/></p>
      </div>    
     </div>
     </div>
     <div className="mb-3 mt-3 flex justify-end">
     <div className="custom-date-range-picker">
      <input
        type="date"
        id="start-date"
        value={startDate}
        onChange={handleStartDateChange}
        max={endDate} // End date must be after start date
      />
      <input
        type="date"
        id="end-date"
        value={endDate}
        onChange={handleEndDateChange}
        min={startDate} // Start date must be before end date
      />

      {/* Show error message if dates are invalid */}
      {!isDateRangeValid() && (
        <p style={{ color: 'red' }}>End date must be later than start date!</p>
      )}
    </div>

      <Popup
                        trigger={<button className="ml-auto addnew-button">Add KPI+</button>}
                        modal
                        nested
                      >
                        {close => (
                          <div style={{width:'300px',padding:'20px'}} className="flex flex-col justify-center p-6 text-center bg-gray-800 text-white w-[90%] max-w-md h-auto rounded-lg shadow-lg">
                            <h1 style={{fontSize:'20px',fontWeight:'bold'}}>Add KPIs</h1>
                            {kpiOptions.length===0 ? (
                              <p>No Options to be added.</p>
                            ):(
                            <div className="kpi-checkboxes">
          {kpiOptions.map((kpi, index) => (
            <div style={{textAlign:'left'}} key={index}>
              <input
                type="checkbox"
                value={kpi}
                id={`kpi-${index}`}
                onChange={handleCheckboxChange}
              />
              <label className="ml-3" htmlFor={`kpi-${index}`}>{kpi}</label>
            </div>
          ))}
          <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <button
            onClick = {() => close()}
              type="button"
              className="col-span-2 bg-red-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Cancel
            </button>
            <button
            onClick={(e) => {
              onClickAdd(e)
              close()
            }}
              type="submit"
              className="col-span-2 bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 mt-6"
            >
              Add
            </button>
            </div>
        </div>)}
                          </div>
                        )}
                      </Popup>
      </div>
       <div style={{overflowY:'auto',maxHeight:'500px'}} className="hidden-scrollbar flex justify-between items-center flex-wrap">
        <div className="performance-card">
          <h1 className="pc-heading"><GoDotFill style={{color:'green',display:'inline'}} size={20}/>Total Campaign</h1>
          <p className="pc-desc">Last 7 days</p>
          <h1 className="pc-stats">0</h1>
        </div>
        <div className="performance-card">
          <h1 className="pc-heading"><GoDotFill style={{color:'green',display:'inline'}} size={20}/>Total Calls</h1>
          <p className="pc-desc">Last 7 days</p>
          <h1 className="pc-stats">0</h1>
        </div>
        <div className="performance-card">
          <h1 className="pc-heading"><GoDotFill style={{color:'#ecd910',display:'inline'}} size={20}/>Total Connected</h1>
          <p className="pc-desc">Last 7 days</p>
          <h1 className="pc-stats">0</h1>
        </div>
        <div className="performance-card">
          <h1 className="pc-heading"><GoDotFill style={{color:'gray',display:'inline'}} size={20}/>Total Invalid</h1>
          <p className="pc-desc">Last 7 days</p>
          <h1 className="pc-stats">0</h1>
        </div>
        <div className="performance-card">
          <h1 className="pc-heading"><GoDotFill style={{color:'orange',display:'inline'}} size={20}/>Total Meetings</h1>
          <p className="pc-desc">Last 7 days</p>
          <h1 className="pc-stats">0</h1>
        </div>
        <div className="performance-card">
          <h1 className="pc-heading"><GoDotFill style={{color:'green',display:'inline'}} size={20}/>Total Call Duration</h1>
          <p className="pc-desc">Last 7 days</p>
          <h1 className="pc-stats">00s</h1>
        </div>
        {selectedKPIs.map((kpi, index) => (
          <div className="performance-card" key={index}>
            <div className="flex justify-between items-center">
            <h1 className="pc-heading">
              <GoDotFill style={{ color: 'orange', display: 'inline' }} size={20} />
              {kpi}
            </h1>
            <IoClose onClick={() => onClickRemove(kpi)} size={20}/>
            </div>
            <p className="pc-desc">Last 7 days</p>
            <h1 className="pc-stats">0</h1>
       </div>
      ))}
     </div>
   </div>
   </div>
   </div>
   </div>
  );
}

export default Overview;