import "./index.css"
import { FaChartPie, FaBusinessTime, FaTicketAlt, FaComments, FaDollarSign, FaCog, FaRupeeSign, FaUser, FaPhone, FaUsers, FaTools, FaFile, FaChevronUp, FaChevronDown, FaQuestionCircle, FaBook, FaEnvelope } from 'react-icons/fa';
import { IoSettings } from 'react-icons/io5';
import { MdHelpOutline } from 'react-icons/md';
import { Link } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { IoLogOut } from "react-icons/io5";
import {Tooltip} from "@nextui-org/react";
import { BiSolidConversation } from "react-icons/bi";
import CryptoJS from 'crypto-js';

const IconSidebar = () => {
    const [activeTabID, setActiveTabID] = useState("Dashboard");
  const [activeTabItem, setActiveItem] = useState("");

  const [accessItems, setAccessItems] = useState({
    Dashboard: false,
    Business: false,
    System: false,
    Tickets: false,
    Feedback: false,
    Collections: false,
    Admin: false,
    Conversations:false
  });
  const [accessOptions, setAccessOptions] = useState([]);

  const email = jwtDecode(Cookies.get("userId")).email;

  useEffect(() => {
    const fetchAccessItems = () => {
      try {
        // Retrieve the encrypted data from localStorage
        const encryptedData = localStorage.getItem(process.env.REACT_APP_ENCRYPTED_KEY);
        
        if (encryptedData) {
          // Decrypt the data
          const decryptedData = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
          const accessItemsArray = JSON.parse(decryptedData);
          
          // Process the decrypted data into the format you need
          const updatedAccessItems = {};
          accessItemsArray.forEach(item => {
            updatedAccessItems[item] = true;
          });
          
          // Update state with the processed data
          setAccessItems(updatedAccessItems);
          setAccessOptions(accessItemsArray);
        } else {
          alert("No access items found in localStorage.");
        }
      } catch (error) {
        console.error("Error decrypting or processing access items:", error);
        alert("Something went wrong while processing access items.");
      }
    };

    fetchAccessItems();
  }, []);


  const handleTabItemClick = (item) => {
    setActiveItem(item);
  };

  const handleTabClick = (tabID) => {
    setActiveTabID(tabID);
  };

  const location = useLocation();

  useEffect(() => {
    const path = location.pathname;
    if (path.startsWith("/dashboard")) {
      setActiveTabID("Dashboard");
      setActiveItem("Dashboard");
    } else if (path.startsWith("/business")) {
      setActiveTabID("Business");
      setActiveItem("Business");
    } else if (path.startsWith("/system")) {
      setActiveTabID("System");
      setActiveItem(path.split("/")[2]);
    } else if (path.startsWith("/tickets")) {
      setActiveTabID("Tickets");
      setActiveItem("Tickets");
    } else if (path.startsWith("/feedback")) {
      setActiveTabID("Feedback");
      setActiveItem("Feedback");
    } else if (path.startsWith("/payments")) {
      setActiveTabID("Collections");
    } else if (path.startsWith("/help")) {
      setActiveTabID("Help");
      setActiveItem("FAQs");
    } else if (path.startsWith("/adminsection")) {
      setActiveTabID("Admin");
      setActiveItem("Admin");
    }else if (path.startsWith("/adminconversations")) {
        setActiveTabID("Conversations");
        setActiveItem("Conversations");
      }
     else if (path.startsWith("/adminsettings")) {
      setActiveTabID("Settings");
      setActiveItem("Settings");
    }
  }, [location]);
    
//   useEffect(() => {
//     const getItems = async () => {
//       try {

//         }
//         if(!items){
//         const response = await fetch('${process.env.REACT_APP_API_URL}/checksubadmin', {
//           method: 'POST',
//           headers: { 'Content-Type': 'application/json' },
//           body: JSON.stringify({ email })
//         });
//         const data = await response.json();
//         if (data.exist && data.user.accessItems) {
//           const updatedAccessItems = {};
//           data.user.accessItems.forEach(item => {
//             updatedAccessItems[item] = true;
//           });
//           setAccessItems(prevItems => ({ ...prevItems, ...updatedAccessItems }));
//           setAccessOptions(data.user.accessItems);
//         } else if (data.exist === false) {
//           setAccessItems({
//             Dashboard: true,
//             Business: true,
//             System: true,
//             Tickets: true,
//             Feedback: true,
//             Collections: true,
//             Admin: true,
//             Conversations:true
//           });
//         }
//     }
//       } catch (err) {
//         console.error(`Error Occurred: ${err}`);
//       }
//     };

//     getItems();
//   }, [email]);

    return (
        <div className="sidebar-main-container">
            <div className="icon-bar">
              <Link className="nav-link" to="/dashboard">
                <div className="icon-bar-img-container">
              <img className="icon-sidebar-img-logo" src="https://res.cloudinary.com/haibegwvw/image/upload/v1721307308/file_krjkgp.png" alt="logo"/>
              </div>
              </Link>
              <div className="iconic-container">
    <ul className="iconic-list-container">
        <Link className="nav-link" to="/dashboard">
        <Tooltip className="custom-tool" content="Performance" placement="right">
            <li 
                onClick={() => handleTabClick("Dashboard")} 
                className={`iconic-sidebar-item ${accessItems.Dashboard ? '' : 'mask-icon'} ${activeTabID === "Dashboard" ? "active" : ""}`}
            >
                <FaChartPie className="iconic-sidebar-icon" />
            </li>
            </Tooltip>
        </Link>
        <Link className="nav-link" to="/business">
        <Tooltip className="custom-tool" content="Business" placement="right">
            <li 
                onClick={() => handleTabClick("Business")} 
                className={`iconic-sidebar-item ${accessItems.Business ? '' : 'mask-icon'} ${activeTabID === "Business" ? "active" : ""}`}
            >
                <FaBusinessTime className="iconic-sidebar-icon" />
            </li>
            </Tooltip>
        </Link>
        <Link className="nav-link" to="/system/assistants">
        <Tooltip className="custom-tool" content="System" placement="right">
            <li 
                onClick={() => handleTabClick("System")} 
                className={`iconic-sidebar-item ${accessItems.System ? '' : 'mask-icon'} ${activeTabID === "System" ? "active" : ""}`}
            >
                <FaTools className="iconic-sidebar-icon" />
            </li>
            </Tooltip>
        </Link>
        <Link className="nav-link" to="/adminconversations">
        <Tooltip className="custom-tool" content="Conversations" placement="right">
            <li 
                onClick={() => handleTabClick("Conversations")} 
                className={`iconic-sidebar-item ${accessItems.Conversations ? '' : 'mask-icon'} ${activeTabID === "Conversations" ? "active" : ""}`}
            >
                <BiSolidConversation className="iconic-sidebar-icon" />
            </li>
            </Tooltip>
        </Link>
        <Link className="nav-link" to="/tickets">
        <Tooltip className="custom-tool" content="Tickets" placement="right">
            <li 
                onClick={() => handleTabClick("Tickets")} 
                className={`iconic-sidebar-item ${accessItems.Tickets ? '' : 'mask-icon'} ${activeTabID === "Tickets" ? "active" : ""}`}
            >
                <FaTicketAlt className="iconic-sidebar-icon" />
            </li>
            </Tooltip>
        </Link>
        <Link className="nav-link" to="/feedback">
        <Tooltip className="custom-tool" content="Feedback" placement="right">
            <li 
                onClick={() => handleTabClick("Feedback")} 
                className={`iconic-sidebar-item ${accessItems.Feedback ? '' : 'mask-icon'} ${activeTabID === "Feedback" ? "active" : ""}`}
            >
                <FaComments className="iconic-sidebar-icon" />
            </li>
            </Tooltip>
        </Link>
        <Link className="nav-link" to="/payments">
        <Tooltip className="custom-tool" content="Payments" placement="right">
            <li 
                onClick={() => handleTabClick("Collections")} 
                className={`iconic-sidebar-item ${accessItems.Collections ? '' : 'mask-icon'} ${activeTabID === "Collections" ? "active" : ""}`}
            >
                <FaRupeeSign className="iconic-sidebar-icon" />
            </li>
            </Tooltip>
        </Link>
    </ul>
    <ul className="iconic-list-container">
        <Link className="nav-link" to="/help">
        <Tooltip className="custom-tool" content="Help" placement="right">
            <li 
                onClick={() => handleTabClick("Help")} 
                className={`iconic-sidebar-item ${activeTabID === "Help" ? "active" : ""}`}
            >
                <MdHelpOutline className="iconic-sidebar-icon" />
            </li>
            </Tooltip>
        </Link>
        <Link className="nav-link" to="/adminsection">
        <Tooltip className="custom-tool" content="Admin" placement="right">
            <li 
                onClick={() => handleTabClick("Admin")} 
                className={`iconic-sidebar-item ${accessItems.Admin ? '' : 'mask-icon'} ${activeTabID === "Admin" ? "active" : ""}`}
            >
                <FaCog className="iconic-sidebar-icon" />
            </li>
            </Tooltip>
        </Link>
        <Link className="nav-link" to="/adminsettings">
        <Tooltip className="custom-tool" content="Settings" placement="right">
            <li 
                onClick={() => handleTabClick("Settings")} 
                className={`iconic-sidebar-item ${activeTabID === "Settings" ? "active" : ""}`}
            >
                <IoLogOut className="iconic-sidebar-icon" />
            </li>
            </Tooltip>
        </Link>
    </ul>
</div>

            </div>
            <div className="description-bar">
                <div className="description-main-container">
                <h1 className="description-heading">Aitota</h1>
                <h2 style={{fontSize:"15px"}} className="description-heading">(Admin)</h2> 
                </div>
                {activeTabID==="Dashboard" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <Link className="iconic-link" to="/dashboard">
                        <li onClick={() => handleTabItemClick("Dashboard")} className={`iconic-tab-item ${activeTabItem === "Dashboard" ? "active" : ""}`}>Performance (KPIs)</li>
                        </Link>
                    </ul>
                    </div>
                )}
                {activeTabID==="Business" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("Business")} className={`iconic-tab-item ${activeTabItem === "Business" ? "active" : ""}`}>Business</li>
                    </ul>
                    </div>
                )}
                {activeTabID==="System" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <Link className="nav-link" to="/system/assistants">
                        <li onClick={() => handleTabItemClick("assistants")} className={`iconic-tab-item ${activeTabItem === "assistants" ? "active" : ""}`}>Assistant Template</li>
                        </Link>
                        <Link className="nav-link" to="/system/telephony">
                        <li onClick={() => handleTabItemClick("telephony")} className={`iconic-tab-item ${activeTabItem === "telephony" ? "active" : ""}`}>Telephony</li>
                        </Link>
                        <Link className="nav-link" to="/system/dataset">
                        <li onClick={() => handleTabItemClick("dataset")} className={`iconic-tab-item ${activeTabItem === "dataset" ? "active" : ""}`}>Dataset</li>
                        </Link>
                        <Link className="nav-link" to="/system/tools">
                        <li onClick={() => handleTabItemClick("tools")} className={`iconic-tab-item ${activeTabItem === "tools" ? "active" : ""}`}>Tools</li>
                        </Link>

                        {/* <li onClick={() => handleTabItemClick("team")} className={`iconic-tab-item ${activeTabItem === "Team" ? "active" : ""}`}>Team</li> */}
                    </ul>
                    </div>
                )}
                {activeTabID==="Conversations" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("Conversations")} className={`iconic-tab-item ${activeTabItem === "Conversations" ? "active" : ""}`}>Conversations</li>
                    </ul>
                    </div>
                )}
                {activeTabID==="Tickets" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("Tickets")} className={`iconic-tab-item ${activeTabItem === "Tickets" ? "active" : ""}`}>Tickets</li>
                    </ul>
                    </div>
                )}
                {activeTabID==="Feedback" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("Feedback")} className={`iconic-tab-item ${activeTabItem === "Feedback" ? "active" : ""}`}>Feedback</li>
                    </ul>
                    </div>
                )}
                {activeTabID==="Collections" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("Payments")} className={`iconic-tab-item ${activeTabItem === "Payments" ? "active" : ""}`}>Payments</li>
                        <li onClick={() => handleTabItemClick("Subscriptions")} className={`iconic-tab-item ${activeTabItem === "Subscriptions" ? "active" : ""}`}>Subscriptions</li>
                    </ul>
                    </div>
                )}
                {activeTabID==="Help" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("FAQs")} className={`iconic-tab-item ${activeTabItem === "FAQs" ? "active" : ""}`}>FAQs</li>
                        <li onClick={() => handleTabItemClick("Training")} className={`iconic-tab-item ${activeTabItem === "Training" ? "active" : ""}`}>Training</li>
                        <li onClick={() => handleTabItemClick("Contact")} className={`iconic-tab-item ${activeTabItem === "Contact" ? "active" : ""}`}>Contact</li>
                    </ul>
                    </div>
                )}
                {activeTabID==="Admin" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("Admin")} className={`iconic-tab-item ${activeTabItem === "Admin" ? "active" : ""}`}>Admin</li>
                    </ul>
                    </div>
                )}
                {activeTabID==="Settings" && (
                    <div className="iconic-tabs-container">
                    <ul className="iconic-list-container">
                        <li onClick={() => handleTabItemClick("Settings")} className={`iconic-tab-item ${activeTabItem === "Settings" ? "active" : ""}`}>Settings</li>
                    </ul>
                    </div>
                )}
            </div>
        </div>
    )
}

export default IconSidebar;